import { useMemo } from 'react';

import { addBignumbers, bn } from 'tools';
import { formatToken } from 'utils/formats';
import { ICexAccountTableRow } from 'types/tables';
import { IAccountBalances, ICexAccount } from 'types/accounts';

interface IAccountWithBalance extends ICexAccount, Partial<IAccountBalances> {}

const useArbitrageCexAccountsRecords = (pairAccounts: IAccountWithBalance[]) => {
  const tableData = useMemo<ICexAccountTableRow[]>(
    () =>
      pairAccounts?.map(account => {
        const tokenBase = formatToken(
          addBignumbers(
            [bn(account.base_balance ?? '0'), 18],
            [bn(account.base_balance_locked ?? '0'), 18],
          ),
          18,
        );

        const tokenQuote = formatToken(
          addBignumbers(
            [bn(account.quote_balance ?? '0'), 18],
            [bn(account.quote_balance_locked ?? '0'), 18],
          ),
          18,
        );

        const notesCol = {
          value: account.notes as string,
        };

        return {
          tokenBaseCol: tokenBase,
          tokenQuoteCol: tokenQuote,
          notesCol,
          id: account.id,
        } as ICexAccountTableRow;
      }) ?? [],
    [pairAccounts],
  );
  return { tableData };
};

export { useArbitrageCexAccountsRecords };
