import React, { useCallback, useMemo } from 'react';
import { motion } from 'framer-motion';

import { Button, Modal, SmallRowListSelector, ButtonLoading, Spinner } from 'ui';
import { InputField, SelectExchangeField } from 'fields';
import { InsertButton } from 'fields/components';
import { PairIcon } from 'assets/icons';
import { useAddPairModal } from './useAddPairModal/useAddPairModal';

import { IFee, UNISWAP_FEES, PANCAKE_FEES, HAQQ_FEES } from 'constant/fees';
import { web3Configs, EExchange, ENetwork } from 'web3';
import { IDex } from 'api/apiDictionary/models';

import './addPairModal.scss';

interface IAddPairModalProps {
  onClose: () => void;
  onOpen: () => void;
  projectId: number;
  tradingType: EExchange;
  dex?: IDex;
}

const AddPairModal: React.FC<IAddPairModalProps> = ({
  onClose,
  onOpen,
  projectId,
  tradingType,
  dex,
}) => {
  const {
    cexs,
    values,
    errors,
    touched,
    setFormField,
    handleSubmit,
    loading,
    pairParsingLoading,
    pairParsingError,
    edited,
  } = useAddPairModal({
    projectId,
    onClose,
    onOpen,
    tradingType,
    dex,
  });

  const feesAreVisible = useMemo(
    () =>
      dex &&
      (dex.dex === 'uniswap_v3' ||
        dex.dex === 'uniswap_v3:arbitrum_one' ||
        dex.dex === 'uniswap_v3:polygon' ||
        dex.dex === 'uniswap_v3:base' ||
        dex.dex === 'pancakeswap_v3:bsc' ||
        dex.dex === 'sushiswap_v3:haqq'),
    [dex],
  );

  const feesList = useMemo(() => {
    if (
      dex &&
      ['uniswap_v3', 'uniswap_v3:arbitrum_one', 'uniswap_v3:polygon', 'uniswap_v3:base'].includes(
        dex.dex,
      )
    )
      return UNISWAP_FEES;

    if (dex && ['pancakeswap_v3:bsc'].includes(dex.dex)) return PANCAKE_FEES;

    if (dex && ['sushiswap_v3:haqq'].includes(dex.dex)) return HAQQ_FEES;

    return [];
  }, [dex]);

  const handleSwapTokens = useCallback(() => {
    const baseToken = values.baseToken;
    const quoteToken = values.quoteToken;

    setFormField('baseToken', quoteToken);
    setFormField('quoteToken', baseToken);
  }, [values, setFormField]);

  const cexsFormatted = useMemo(
    () => cexs?.map(el => ({ label: el.description, exchange: el.cex })) ?? [],
    [cexs],
  );

  return (
    <Modal
      title={`New trading pair ${
        dex ? '- ' + dex.description + ` (${web3Configs[dex.network as ENetwork].label})` : ''
      }`}
      edited={edited}
      onClose={onClose}
      customButtons={
        loading || pairParsingLoading ? (
          <ButtonLoading />
        ) : (
          <Button type="button" onClick={handleSubmit}>
            Create
          </Button>
        )
      }
    >
      <div className="mm-add-pair-modal">
        {tradingType === EExchange.dex && (
          <InputField
            label="Pair address(optional)"
            type="text"
            value={values.pairAddress}
            setValue={newPairAddress => setFormField('pairAddress', newPairAddress)}
            nodeRight={
              <InsertButton
                onInsert={newPairAddress => setFormField('pairAddress', newPairAddress)}
              />
            }
            errorMessage={
              pairParsingError
                ? 'Invalid pair address'
                : errors.pairAddress && touched.pairAddress
                ? errors.pairAddress
                : undefined
            }
          />
        )}
        <motion.div className="mm-add-pair-modal__token-selector">
          <div className="mm-add-pair-modal__token-selector__pair">
            {tradingType === EExchange.cex && cexs && (
              <SelectExchangeField
                label="Exchange"
                items={cexsFormatted}
                disabled={loading}
                selectedItem={
                  cexsFormatted.find(el => el.exchange === values.cex?.cex) ?? undefined
                }
                onSelectItem={cex =>
                  setFormField('cex', cexs.find(el => el.cex === cex?.exchange) ?? null)
                }
              />
            )}
            <InputField
              label="Token base"
              disabled={pairParsingLoading}
              type="text"
              value={values.baseToken}
              setValue={newBaseToken =>
                setFormField(
                  'baseToken',
                  tradingType === EExchange.cex ? newBaseToken.toUpperCase() : newBaseToken,
                )
              }
              nodeRight={
                <InsertButton
                  onInsert={newBaseToken =>
                    setFormField(
                      'baseToken',
                      tradingType === EExchange.cex ? newBaseToken.toUpperCase() : newBaseToken,
                    )
                  }
                />
              }
              errorMessage={errors.baseToken && touched.baseToken ? errors.baseToken : undefined}
            />
            <motion.button
              whileHover={{ scale: 1.2 }}
              className="swap-tokens-button"
              onClick={handleSwapTokens}
            >
              {pairParsingLoading && <Spinner size="mini" />}
              {!pairParsingLoading && <PairIcon />}
            </motion.button>
            <InputField
              label="Token quote"
              disabled={pairParsingLoading}
              type="text"
              value={values.quoteToken}
              setValue={newQuoteToken =>
                setFormField(
                  'quoteToken',
                  tradingType === EExchange.cex ? newQuoteToken.toUpperCase() : newQuoteToken,
                )
              }
              nodeRight={
                <InsertButton
                  onInsert={newQuoteToken =>
                    setFormField(
                      'quoteToken',
                      tradingType === EExchange.cex ? newQuoteToken.toUpperCase() : newQuoteToken,
                    )
                  }
                />
              }
              errorMessage={errors.quoteToken && touched.quoteToken ? errors.quoteToken : undefined}
            />
          </div>
        </motion.div>

        {tradingType === EExchange.dex && (
          <motion.div
            initial={{ height: 0 }}
            animate={{
              height: feesAreVisible ? 'auto' : 0,
              overflow: feesAreVisible ? 'visible' : 'hidden',
            }}
            className="mm-add-pair-modal__fee-selector"
          >
            <span className="mm-add-pair-modal__fee-selector__text">Fee:</span>
            <SmallRowListSelector<IFee>
              list={feesList}
              selected={feesList.find(fee => fee.value === values.fee)}
              field={'text'}
              onSelect={newSelectedFee => setFormField('fee', newSelectedFee.value)}
            />
          </motion.div>
        )}

        <div className="mm-add-pair-modal__notes">
          <InputField
            type="text"
            label="Notes"
            value={values.notes}
            setValue={newNotes => setFormField('notes', newNotes)}
            errorMessage={errors.notes && touched.notes ? errors.notes : undefined}
          />
        </div>
      </div>
    </Modal>
  );
};

export { AddPairModal };
