import React, { useRef, useEffect, memo } from 'react';
import cn from 'classnames';
import * as echarts from 'echarts';

import './barRaceDiagram.scss';

type IBarRaceDiagramProps = {
  data: number[];
  barWidth: number;
  className?: string;
};

const BarRaceDiagram: React.FC<IBarRaceDiagramProps> = ({ data, barWidth, className, ...rest }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) return;
    const barChart = echarts.init(chartRef.current);

    const option: echarts.EChartsOption = {
      animation: false,
      tooltip: {
        show: false,
      },
      grid: {
        top: 0,
        bottom: 0,
        left: 20,
        right: 30,
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        position: 'bottom',
        splitLine: {
          show: true,
          lineStyle: {
            color: '#27272A',
            width: 1,
            type: 'solid',
          },
        },
        axisLabel: {
          show: true,
          color: '#A1A1AA',
          fontSize: 9,
          fontWeight: 400,
        },
        axisTick: { show: false },
      },
      yAxis: {
        type: 'category',
        axisLine: { show: false },
        axisLabel: { show: false },
        axisTick: { show: false },
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
            color: '#3F3F46',
            width: 1,
          },
        },
        splitArea: {
          show: true,
          areaStyle: {
            color: ['transparent'],
          },
        },
      },
      series: [
        {
          name: 'Cost',
          type: 'bar',
          stack: 'Total',
          barWidth,
          label: {
            show: true,
            position: 'right',
            formatter: params => {
              const value = params.value as number;
              return `${value}`;
            },
            align: 'left',
            color: '#A1A1AA',
            fontSize: 9,
            fontWeight: 400,
          },
          data: data.map(value => ({
            value,
            label: {
              position: value < 0 ? 'left' : 'right',
              distance: value < 0 ? 20 : 5,
              color: '#A1A1AA',
              fontSize: 9,
              fontWeight: 400,
            },
            itemStyle: {
              color: value < 0 ? '#E9445A' : '#67FFD1',
              borderRadius: value < 0 ? [4, 0, 0, 4] : [0, 4, 4, 0],
            },
            emphasis: {
              itemStyle: {
                scale: 1.1,
              },
              label: {
                color: '#FFFFFF',
                fontWeight: 500,
              },
            },
          })),
          emphasis: {
            focus: 'none',
          },
        },
        {
          name: 'Center Line',
          type: 'line',
          markLine: {
            silent: true,
            symbol: 'none',
            label: {
              show: false,
            },
            lineStyle: {
              color: '#E9445A',
              width: 1,
              type: 'solid',
            },
            data: [{ xAxis: 0 }],
          },
        },
      ],
    };

    barChart.setOption(option);

    return () => {
      barChart.dispose();
    };
  }, [data]);

  return (
    <div className={cn({ [className ?? '']: !!className }, 'mm-bar-race-diagram-container')}>
      <div ref={chartRef} className="mm-bar-race-diagram" {...rest} />
    </div>
  );
};

const memoBarRaceDiagram = memo(BarRaceDiagram);

export { memoBarRaceDiagram as BarRaceDiagram };
