import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import { useTypedSelector } from 'store';

import { EChartFormat, IChartRange, IChartTimeframe, IPriceFormat } from 'types/charts';
import { IAccountBalances, ICexAccount } from 'types/accounts';
import { CexPairContext } from 'context/CexPairContext';

interface IAccountWithBalance extends ICexAccount, Partial<IAccountBalances> {}

interface IChartsControlsContext {
  period: { get: IChartRange | undefined; set: Dispatch<SetStateAction<IChartRange | undefined>> };
  startDate: { get: number | undefined; set: Dispatch<SetStateAction<number | undefined>> };
  endDate: { get: number | undefined; set: Dispatch<SetStateAction<number | undefined>> };
  selectedAccounts: {
    get: IAccountWithBalance[];
    set: Dispatch<SetStateAction<ICexAccount[]>>;
  };

  timeframe: {
    get: IChartTimeframe;
    set: Dispatch<SetStateAction<IChartTimeframe>>;
  };
  chartFormat: EChartFormat;
  setChartFormat: Dispatch<SetStateAction<EChartFormat>>;
  priceFormat: IPriceFormat;
  setPriceFormat: Dispatch<SetStateAction<IPriceFormat>>;
  balancesShow: Record<string, boolean>;
  setBalancesShow: Dispatch<SetStateAction<Record<string, boolean>>>;
  showActiveOrders: boolean;
  setShowActiveOrders: (v: boolean) => void;
  resetBalancesShow: () => void;
  accountsToAPI: (selectedAccounts: ICexAccount[]) => {
    array: number[];
    optional?: number[];
  };
  selectedChart: {
    get: 'Original' | 'Trading';
    set: (v: 'Original' | 'Trading') => void;
  };
}

export const ChartsControlsContext = createContext<IChartsControlsContext>({
  period: { get: 'ALL', set: () => {} },
  startDate: { get: 0, set: () => {} },
  endDate: { get: 0, set: () => {} },
  selectedAccounts: { get: [], set: () => {} },
  balancesShow: {},
  setBalancesShow: () => {},
  showActiveOrders: false,
  setShowActiveOrders: () => {},
  resetBalancesShow: () => {},
  timeframe: { get: '1m', set: () => {} },
  chartFormat: EChartFormat.Normal,
  setChartFormat: () => {},
  priceFormat: 'usd',
  setPriceFormat: () => {},
  accountsToAPI: () => ({ array: [] }),
  selectedChart: { get: 'Original', set: () => {} },
});

export const ChartsControlsContextProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const { accounts } = useContext(CexPairContext);

  const [selectedChart, setSelectedChart] = useState<'Original' | 'Trading'>('Trading');

  const [period, setPeriod] = useState<IChartRange | undefined>('1D');
  const [startDate, setStartDate] = useState<number | undefined>(undefined);
  const [endDate, setEndDate] = useState<number | undefined>(undefined);

  const [selectedAccounts, setSelectedAccounts] = useState<ICexAccount[]>([]);

  const [timeframe, setTimeframe] = useState<IChartTimeframe>('5m');
  const [chartFormat, setChartFormat] = useState<EChartFormat>(EChartFormat.Percentage);
  const [priceFormat, setPriceFormat] = useState<IPriceFormat>('usd');

  const INITIAL_BALANCES_SHOW = useMemo(
    () => ({
      [cexPair.token_base.symbol]: true,
      [cexPair.token_quote.symbol]: true,
      [cexPair.token_native.symbol]: true,
      Total: true,
    }),
    [cexPair],
  );

  const [balancesShow, setBalancesShow] = useState<Record<string, boolean>>(INITIAL_BALANCES_SHOW);
  const [showActiveOrders, setShowActiveOrders] = useState<boolean>(false);

  const resetBalancesShow = useCallback(() => {
    setBalancesShow(INITIAL_BALANCES_SHOW);
  }, [setBalancesShow, INITIAL_BALANCES_SHOW]);

  const accountsToAPI = useCallback(
    (selectedAccounts: ICexAccount[]) => {
      const optional =
        selectedAccounts.length === accounts.length ? undefined : selectedAccounts.map(el => el.id);
      const array =
        optional === undefined || optional.length === 0 ? accounts.map(el => el.id) : optional;
      return { array, optional };
    },
    [accounts],
  );

  return (
    <ChartsControlsContext.Provider
      value={{
        period: { get: period, set: setPeriod },
        startDate: { get: startDate, set: setStartDate },
        endDate: { get: endDate, set: setEndDate },
        selectedAccounts: { get: selectedAccounts, set: setSelectedAccounts },
        balancesShow,
        setBalancesShow,
        resetBalancesShow,
        showActiveOrders,
        setShowActiveOrders,
        timeframe: { get: timeframe, set: setTimeframe },
        chartFormat,
        setChartFormat,
        priceFormat,
        setPriceFormat,
        accountsToAPI,
        selectedChart: { get: selectedChart, set: setSelectedChart },
      }}
    >
      {children}
    </ChartsControlsContext.Provider>
  );
};
