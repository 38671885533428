import { useTypedSelector } from 'store';
import { RadioBox } from 'ui';

import { ICexPair } from 'types/pairs';

import PlatformIcon from 'common/project/ProjectListWithPairs/PlatformIcon';

import './style.scss';

interface PairsListProps {
  toggleAllPairs: () => void;
  selectedCexPairs: ICexPair[];
  cexPairs: ICexPair[];
  handlePairSelect: (pair: ICexPair) => void;
}

const PairsList: React.FC<PairsListProps> = ({
  toggleAllPairs,
  selectedCexPairs,
  cexPairs,
  handlePairSelect,
}) => {
  const isAdmin = useTypedSelector(store => store.auth.isAdmin);

  const isAllSelected = selectedCexPairs.length === cexPairs.length;

  return (
    <div className="mm-add-arbitrage-modal__pairs-list-wrapper">
      <div className="pairs-list-title">
        {isAdmin ? (
          <button className="all-pairs-button" onClick={toggleAllPairs}>
            {isAllSelected ? 'Unselect All Pairs' : 'Select All Pairs'}
          </button>
        ) : (
          <span>All pairs:</span>
        )}
        <span className="selected-number">Selected: {selectedCexPairs.length}</span>
      </div>
      <div className="pairs-list">
        {cexPairs.map(pair => (
          <div
            key={pair.created_at}
            className="trading-pair"
            onClick={() => handlePairSelect(pair)}
          >
            <RadioBox
              key={pair.id}
              checked={selectedCexPairs.some(selected => selected.id === pair.id)}
              text={pair.symbol}
              pairIcon={
                <div className="project-pair-container">
                  <PlatformIcon
                    platform={pair.cex}
                    isIcon
                    className="mm-sidebar__projects__project__trading-pair-icon"
                  />
                </div>
              }
              className="pair-radio-box"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export { PairsList };
