import { useMemo, useEffect, useState, useContext, useCallback } from 'react';
import { useFormik } from 'formik';
import { number, object, string } from 'yup';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useTypedDispatch, useTypedSelector } from 'store';

import { ApiDexBuySellBot } from 'api';
import { IAddPairArgs } from 'api/apiPairs/models';
import { DexBuySellBotContext } from 'context/DexBuySellBotContext';

import { durationToMs } from 'utils/duration';
import { mempoolConfigMap, MempoolConfigMap } from 'constant/common';
import { IDexBuySellBotDirection } from 'types/bots';
import { IDexPair } from 'types/pairs';
import { IBuySellBotTaskItemInner } from 'types/buy-sell-bot';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { bn, bnFrom, humanizeBn } from 'tools/math';
import { convertTokenDEX } from 'utils';

interface IForm {
  startDate: number | null;
  totalTokenAmount: string;
  minAmount: string;
  maxAmount: string;
  minDelay: number | null;
  maxDelay: number | null;
  advanceCoefficient: string;
  priceThresholdMin: string;
  priceThresholdMax: string;
  amountDisperseCoeffMin: string;
  amountDisperseCoeffMax: string;
  skipTransactionsMin: string;
  skipTransactionsMax: string;
}

interface IUseCreateNewTaskModalProps {
  pair: IAddPairArgs & IDexPair;
  direction: Capitalize<IDexBuySellBotDirection>;
  onClose: () => void;
  task?: IBuySellBotTaskItemInner;
  mode: 'create' | 'edit';
}

export const useTaskModal = ({
  pair,
  direction,
  onClose,
  task,
  mode,
}: IUseCreateNewTaskModalProps) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const dispatch = useTypedDispatch();
  const { handleLoadRecords, handleLoadBotSettings, botSettings } =
    useContext(DexBuySellBotContext);
  const [loading, setLoading] = useState(false);
  const [edited, setEdited] = useState(false);
  const [memPoolConfig, setMemPoolConfig] = useState<MempoolConfigMap>('Do not use');
  const [totalCurrency, setTotalCurrency] = useState<string>(dexPair.token_base.symbol);
  const [tradesCurrency, setTradesCurrency] = useState<string>(dexPair.token_base.symbol);
  const [thresholdCurrency, setThresholdCurrency] = useState<string>(dexPair.token_quote.symbol);

  const initialValues = useMemo<IForm>(() => {
    if (task) {
      const options = task.task.options;

      const minDelayMs = durationToMs(options.min_pause);
      const maxDelayMs = durationToMs(options.max_pause);

      return {
        startDate: dayjs(options.start_time).valueOf(),
        totalTokenAmount: humanizeBn(
          bnFrom(options.total_token_amount),
          dexPair.token_base.decimals,
        ),
        minAmount: humanizeBn(bnFrom(options.min_amount), dexPair.token_base.decimals),
        maxAmount: humanizeBn(bnFrom(options.max_amount), dexPair.token_base.decimals),
        minDelay: minDelayMs,
        maxDelay: maxDelayMs,
        advanceCoefficient: (Number(options.advance_coefficient) * 100).toString(),
        priceThresholdMin: humanizeBn(
          bn(options.price_threshold_min, dexPair.token_quote.decimals),
          dexPair.token_quote.decimals,
        ),
        priceThresholdMax: humanizeBn(
          bn(options.price_threshold_max, dexPair.token_quote.decimals),
          dexPair.token_quote.decimals,
        ),
        amountDisperseCoeffMin: (options.amount_disperse_coeff_min * 100).toString(),
        amountDisperseCoeffMax: (options.amount_disperse_coeff_max * 100).toString(),
        skipTransactionsMin: options.skip_transactions_min.toString(),
        skipTransactionsMax: options.skip_transactions_max.toString(),
      };
    }

    return {
      startDate: dayjs().second(0).millisecond(0).valueOf(),
      totalTokenAmount: '',
      minAmount: '',
      maxAmount: '',
      minDelay: 3000,
      maxDelay: 1000 * 60 * 15,
      advanceCoefficient: '30',
      priceThresholdMin: '',
      priceThresholdMax: '',
      amountDisperseCoeffMin: '50',
      amountDisperseCoeffMax: '80',
      skipTransactionsMin: '2',
      skipTransactionsMax: '5',
    };
  }, [task, dexPair]);

  const [formError, setFormError] = useState<string | undefined>(undefined);

  const validationSchema = useMemo(
    () =>
      object({
        startDate: number().test(
          'startDate',
          '"Start time" field is required',
          startDate => !isNil(startDate) && startDate > 0,
        ),
        totalTokenAmount:
          mode === 'create'
            ? number()
                .required('"Total amount" field is required')
                .positive('"Total amount" field is required')
            : number().required('"Total amount" field is required'),

        minAmount: number()
          .test(
            'minAmount',
            '"Min amount" field is required',
            minAmount => !isNaN(Number(minAmount)) && Number(minAmount) > 0,
          )
          .test(
            'minAmount',
            '"Min amount" must be less than "Total amount"',
            (minAmount, testContext) => {
              if (
                !minAmount ||
                isNaN(minAmount) ||
                isNaN(Number(testContext.parent.totalTokenAmount))
              )
                return true;

              return convertTokenDEX({
                from: tradesCurrency,
                to: dexPair.token_base.symbol,
                amount: minAmount.toString(),
                pair: dexPair,
                decimals: dexPair.token_base.decimals,
              }).lte(
                convertTokenDEX({
                  from: totalCurrency,
                  to: dexPair.token_base.symbol,
                  amount: testContext.parent.totalTokenAmount,
                  pair: dexPair,
                  decimals: dexPair.token_base.decimals,
                }),
              );
            },
          ),
        maxAmount: number()
          .test(
            'maxAmount',
            '"Max amount" field is required',
            maxAmount => !isNaN(Number(maxAmount)) && Number(maxAmount) > 0,
          )
          .test('maxAmount', 'Enter valid "Max amount"', (maxAmount, testContext) => {
            if (
              !maxAmount ||
              !testContext.parent.minAmount ||
              isNaN(maxAmount) ||
              isNaN(Number(testContext.parent.totalTokenAmount)) ||
              isNaN(testContext.parent.minAmount)
            )
              return true;
            const converted = convertTokenDEX({
              from: tradesCurrency,
              to: dexPair.token_base.symbol,
              amount: maxAmount.toString(),
              pair: dexPair,
              decimals: dexPair.token_base.decimals,
            });

            return (
              converted.lte(
                convertTokenDEX({
                  from: totalCurrency,
                  to: dexPair.token_base.symbol,
                  amount: testContext.parent.totalTokenAmount,
                  pair: dexPair,
                  decimals: dexPair.token_base.decimals,
                }),
              ) &&
              converted.gte(
                convertTokenDEX({
                  from: tradesCurrency,
                  to: dexPair.token_base.symbol,
                  amount: testContext.parent.minAmount,
                  pair: dexPair,
                  decimals: dexPair.token_base.decimals,
                }),
              )
            );
          }),
        minDelay: number()
          .nullable()
          .test(
            'minDelay',
            '"Min delay" field is required',
            (minDelay: number | undefined | null) =>
              memPoolConfig === 'Only' ? true : !isNil(minDelay),
          ),
        maxDelay: number()
          .nullable()
          .test(
            'maxDelay',
            '"Max delay" field is required',
            (maxDelay: number | undefined | null) =>
              memPoolConfig === 'Only' ? true : !isNil(maxDelay),
          ),
        advanceCoefficient: number()
          .test(
            'advanceCoefficient',
            '"Advanced mempool progress" field is required',
            (advanceCoefficient: number | undefined) =>
              memPoolConfig === 'Do not use' || memPoolConfig === 'Only'
                ? true
                : !isNaN(Number(advanceCoefficient)),
          )
          .test(
            'advanceCoefficient',
            '"Advanced mempool progress" must pe percent value',
            (advanceCoefficient: number | undefined) =>
              memPoolConfig === 'Do not use' || memPoolConfig === 'Only'
                ? true
                : Number(advanceCoefficient) <= 100,
          ),
        priceThresholdMin: string()
          .required('"Min threshold" field is required')
          .test(
            'priceThresholdMin',
            '"Min threshold" field is required',
            (value: string | undefined) => !isNil(Number(value)) && bn(value).gte(bn('0')),
          ),
        priceThresholdMax: string()
          .required('"Max threshold" field is required')
          .test(
            'priceThresholdMax',
            '"Max threshold" field must be greater than "Min threshold"',
            function (priceThresholdMax: string | undefined, testContext) {
              const priceThresholdMin = testContext.parent.priceThresholdMin;

              return bn(priceThresholdMin).lte(bn(priceThresholdMax));
            },
          ),
        amountDisperseCoeffMin: number()
          .test(
            'amountDisperseCoeffMin',
            '"Min contr trade" field is required',
            (amountDisperseCoeffMin: number | undefined) =>
              memPoolConfig === 'Do not use' ? true : !isNaN(Number(amountDisperseCoeffMin)),
          )
          .test(
            'amountDisperseCoeffMin',
            '"Min contr trade" must pe percent value',
            (amountDisperseCoeffMin: number | undefined) =>
              memPoolConfig === 'Do not use' ? true : Number(amountDisperseCoeffMin) <= 100,
          ),
        amountDisperseCoeffMax: number()
          .test(
            'amountDisperseCoeffMax',
            '"Max contr trade" number is required',
            (amountDisperseCoeffMax: number | undefined) =>
              memPoolConfig === 'Do not use' ? true : !isNaN(Number(amountDisperseCoeffMax)),
          )
          .test(
            'amountDisperseCoeffMax',
            '"Max contr trade" must pe percent value',
            (amountDisperseCoeffMax: number | undefined) =>
              memPoolConfig === 'Do not use' ? true : Number(amountDisperseCoeffMax) <= 100,
          ),
        skipTransactionsMin: string().test(
          'skipTransactionsMin',
          '"Min skip transactions" field is required',
          (skipTransactionsMin: string | undefined) =>
            memPoolConfig === 'Do not use' ? true : !isNaN(Number(skipTransactionsMin)),
        ),
        skipTransactionsMax: string().test(
          'skipTransactionsMin',
          '"Max skip transactions" field is required',
          (skipTransactionsMax: string | undefined) =>
            memPoolConfig === 'Do not use' ? true : !isNaN(Number(skipTransactionsMax)),
        ),
      }),
    [memPoolConfig, mode, dexPair, totalCurrency, tradesCurrency],
  );

  const handleAddTask = async ({
    startDate,
    totalTokenAmount,
    minAmount,
    maxAmount,
    minDelay,
    maxDelay,
    advanceCoefficient,
    priceThresholdMin,
    priceThresholdMax,
    amountDisperseCoeffMin,
    amountDisperseCoeffMax,
    skipTransactionsMin,
    skipTransactionsMax,
  }: IForm) => {
    if (!startDate) return;

    setLoading(true);

    const _priceThresholdMin =
      priceThresholdMin !== '' && !isNaN(Number(priceThresholdMin))
        ? humanizeBn(
            convertTokenDEX({
              from: thresholdCurrency,
              to: dexPair.token_quote.symbol,
              amount: priceThresholdMin,
              pair: dexPair,
              decimals: dexPair.token_quote.decimals,
            }),
            dexPair.token_quote.decimals,
          )
        : '0';

    const _priceThresholdMax =
      priceThresholdMax !== '' && !isNaN(Number(priceThresholdMax))
        ? humanizeBn(
            convertTokenDEX({
              from: thresholdCurrency,
              to: dexPair.token_quote.symbol,
              amount: priceThresholdMax,
              pair: dexPair,
              decimals: dexPair.token_quote.decimals,
            }),
            dexPair.token_quote.decimals,
          )
        : '0';

    const updatedTask = {
      enabled: task ? task.task.options.enabled : true,
      direction: direction.toLowerCase() as IDexBuySellBotDirection,
      start_time: dayjs(startDate).toISOString(),
      total_token_amount: convertTokenDEX({
        from: totalCurrency,
        to: pair.token_base.symbol,
        amount: totalTokenAmount,
        pair: dexPair,
        decimals: dexPair.token_base.decimals,
      }).toString(),
      min_amount: convertTokenDEX({
        from: tradesCurrency,
        to: pair.token_base.symbol,
        amount: minAmount,
        pair: dexPair,
        decimals: dexPair.token_base.decimals,
      }).toString(),
      max_amount: convertTokenDEX({
        from: tradesCurrency,
        to: pair.token_base.symbol,
        amount: maxAmount,
        pair: dexPair,
        decimals: dexPair.token_base.decimals,
      }).toString(),
      min_pause: memPoolConfig !== 'Only' ? `${minDelay}ms` : '1s',
      max_pause: memPoolConfig !== 'Only' ? `${maxDelay}ms` : '2s',
      use_mempool: mempoolConfigMap[memPoolConfig],
      advance_coefficient:
        memPoolConfig === 'Use additionaly' ? Number(advanceCoefficient) / 100 : 1,
      price_threshold_min: _priceThresholdMin,
      price_threshold_max: _priceThresholdMax,
      amount_disperse_coeff_min:
        memPoolConfig !== 'Do not use' ? Number(amountDisperseCoeffMin) / 100 : 1,
      amount_disperse_coeff_max:
        memPoolConfig !== 'Do not use' ? Number(amountDisperseCoeffMax) / 100 : 1,
      skip_transactions_min: memPoolConfig !== 'Do not use' ? Number(skipTransactionsMin) : 0,
      skip_transactions_max: memPoolConfig !== 'Do not use' ? Number(skipTransactionsMax) : 0,
    };

    try {
      const taskId = task?.task.id;

      let _isSuccess = false;
      let _errorMessage: string | null = null;

      if (mode === 'edit' && taskId) {
        const { isSuccess, errorMessage } = await ApiDexBuySellBot.updateBuySellTask({
          taskId,
          options: updatedTask,
        });

        _isSuccess = isSuccess;
        _errorMessage = errorMessage;
      }

      if (mode === 'create') {
        const { isSuccess, errorMessage } = await ApiDexBuySellBot.createBuySellTask({
          pairId: dexPair.id,
          options: updatedTask,
        });

        _isSuccess = isSuccess;
        _errorMessage = errorMessage;
      }

      if (!_isSuccess) {
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: _errorMessage ?? 'Something went wrong',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      } else {
        handleLoadRecords();
        handleLoadBotSettings();
        onClose();

        dispatch(
          setAlertState({
            type: 'success',
            text:
              mode === 'create'
                ? 'You successfully create new task!'
                : 'You successfully edit task!',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      }
    } catch (e) {
      console.error(e);
      dispatch(
        setAlertState({
          type: 'failed-img',
          text: 'Something went wrong',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => {
            dispatch(dropAlertState());
          },
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const { handleSubmit, setFieldValue, setFieldTouched, values, errors, touched, validateForm } =
    useFormik<IForm>({
      initialValues,
      onSubmit: handleAddTask,
      validationSchema,
    });

  const handleSetFieldValue = useCallback(
    (field: string, value: any) => {
      setEdited(true);

      setFieldValue(field, value);
    },
    [setFieldValue],
  );

  useEffect(() => {
    const handleSetFormError = () => {
      const keys = Object.keys(errors);

      for (const key of keys) {
        const keyWithType = key as keyof typeof errors;

        if (errors[keyWithType] && touched[keyWithType]) {
          setFormError(errors[keyWithType]);
          return;
        }
      }

      setFormError(undefined);
    };

    handleSetFormError();
  }, [errors, touched]);

  return {
    validationSchema,
    loading,
    initialValues,
    values,
    handleSubmit,
    setFieldValue: handleSetFieldValue,
    setFieldTouched,
    formError,
    memPoolConfig,
    setMemPoolConfig,
    thresholdCurrency,
    setThresholdCurrency,
    totalCurrency,
    setTotalCurrency,
    tradesCurrency,
    setTradesCurrency,
    validateForm,
    edited,
    errors,
    touched,
  };
};
