import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';
import { metricsAxios } from '../apiBase';

import { AApiBase, IBaseResponse } from '../apiBase';
import { ICexActiveOrder, ICexOrder, INewCexOrder } from 'types/orders';

class ApiOrders extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiOrders | undefined;
  private static instance = (() => {
    if (ApiOrders.__instance === undefined) {
      ApiOrders.__instance = new ApiOrders();
    }
    return ApiOrders.__instance;
  })();

  public static getOrders = async ({
    limit,
    lastSeenId,
    pair_ids,
    range,
    sides,
    statuses,
    user_ids,
    account_ids,
    cex_order_ids,
    amounts,
  }: {
    limit: number;
    lastSeenId: number;
    pair_ids?: number[];
    range?: string;
    sides?: string[];
    statuses?: string[];
    user_ids?: number[];
    account_ids?: number[];
    cex_order_ids?: string[];
    amounts?: string[];
  }) => {
    try {
      const urlParams = new URLSearchParams();
      urlParams.append('limit', limit.toString());
      urlParams.append('last_seen_id', lastSeenId.toString());

      if (pair_ids) {
        for (const pairId of pair_ids) {
          urlParams.append('pair_ids', pairId.toString());
        }
      }

      if (range) {
        urlParams.append('range', range);
      }

      if (sides) {
        for (const side of sides) {
          urlParams.append('sides', side);
        }
      }

      if (statuses) {
        for (const status of statuses) {
          urlParams.append('statuses', status);
        }
      }

      if (user_ids) {
        for (const userId of user_ids) {
          urlParams.append('user_ids', userId.toString());
        }
      }

      if (account_ids) {
        for (const userId of account_ids) {
          urlParams.append('account_ids', userId.toString());
        }
      }

      if (cex_order_ids) {
        for (const cexOrderId of cex_order_ids) {
          urlParams.append('cex_order_ids', cexOrderId);
        }
      }

      if (amounts) {
        for (const amount of amounts) {
          urlParams.append('amounts', amount);
        }
      }

      const response = await ApiOrders.instance.get<
        IBaseResponse<{ has_next: boolean; items: ICexOrder[] }>
      >('/orders?' + urlParams.toString());

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<{ has_next: boolean; items: ICexOrder[] }>({ error });
    }
  };

  public static getCexActiveOrders = async ({
    pair_id,
    account_id,
    limit,
    offset,
  }: {
    pair_id: number;
    limit: number;
    offset: number;
    account_id?: number[];
  }) => {
    type ReturnType = { orders: ICexActiveOrder[]; total_count: number };

    try {
      const urlParams = new URLSearchParams();

      urlParams.append('pair_id', pair_id.toString());
      urlParams.append('limit', limit.toString());
      urlParams.append('offset', offset.toString());
      //TODO remove hardcode
      urlParams.append('status', 'active');

      if (account_id) {
        for (const accountId of account_id) {
          urlParams.append('account_id', accountId.toString());
        }
      }

      const response = await metricsAxios.get<ReturnType>(
        '/api/v1/orders' + '?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<ReturnType>({
        error,
      });
    }
  };

  public static placeLimitOrder = async ({
    account_id,
    order_size,
    pair_id,
    price,
    side,
  }: {
    account_id: number;
    order_size: string;
    pair_id: number;
    price: string;
    side: 'buy' | 'sell';
  }) => {
    try {
      const response = await metricsAxios.post<IBaseResponse<any>>('/api/v1/orders', {
        account_id,
        order_size,
        pair_id,
        price,
        side,
      });

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };

  public static cancelOrders = async ({
    account_id,
    pair_id,
    orders,
  }: {
    account_id: number;
    pair_id: number;
    orders: string[];
  }) => {
    try {
      const response = await metricsAxios.delete<IBaseResponse<any>>('/api/v1/orders', {
        data: {
          pair_id,
          account_id,
          orders,
        },
      });

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };

  public static cancelOrdersByAccount = async ({
    pair_id,
    account_id,
  }: {
    pair_id: number;
    account_id: number;
  }) => {
    try {
      const response = await metricsAxios.delete<IBaseResponse<any>>(
        `/api/v1/orders/all?pair_id=${pair_id}&account_id=${account_id}`,
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };

  public static getNewOrders = async ({
    account_ids,
    bots,
    cursor,
    from,
    limit,
    pair_id,
    period,
    side,
    statuses,
    to,
  }: {
    account_ids: number[];
    bots?: string[];
    cursor?: number;
    from?: number;
    limit: number;
    pair_id: number;
    period?: string;
    side?: string;
    statuses?: string[];
    to?: number;
  }) => {
    try {
      const response = await metricsAxios.post<
        IBaseResponse<{ orders: INewCexOrder[]; next_cursor: number }>
      >('/api/v1/orders/filter', {
        account_ids,
        bots,
        cursor,
        from,
        limit,
        pair_id,
        period,
        side,
        statuses,
        to,
      });

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<{ orders: INewCexOrder[]; next_cursor: number }>({ error });
    }
  };

  public static downloadOrders = async ({
    account_ids,
    bots,
    from,
    pair_id,
    period,
    side,
    statuses,
    to,
  }: {
    account_ids: number[];
    bots?: string[];
    from?: number;
    pair_id: number;
    period?: string;
    side?: string;
    statuses?: string[];
    to?: number;
  }) => {
    try {
      const response = await metricsAxios.post<Blob>(
        '/api/v1/orders/filter/download',
        { account_ids, bots, from, pair_id, period, side, statuses, to },
        {
          responseType: 'blob',
        },
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<any>({ error });
    }
  };
}

export { ApiOrders };
