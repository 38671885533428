import React, {
  createContext,
  useState,
  SetStateAction,
  Dispatch,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useTypedSelector } from 'store';

import { ApiStatistic } from 'api';
import { IChartRange } from 'types/charts';
import { ICexAccount } from 'types/accounts';
import { INewCexBot, CEX_BOT_LIST } from 'types/bot';
import { IPnl } from 'types/pnl';
import { getPeriodToAPI } from 'utils/charts';
import { ChartsControlsContext } from './ChartsControlsContext';

type ISelectedBots = {
  bot: INewCexBot;
  enabled: boolean;
}[];

interface IPNLContext {
  period: { get: IChartRange | undefined; set: Dispatch<SetStateAction<IChartRange | undefined>> };
  startDate: { get: number | undefined; set: Dispatch<SetStateAction<number | undefined>> };
  endDate: { get: number | undefined; set: Dispatch<SetStateAction<number | undefined>> };
  loading: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
  selectedAccounts: {
    get: ICexAccount[];
    set: Dispatch<SetStateAction<ICexAccount[]>>;
  };
  selectedToken: {
    get: 'base' | 'quote';
    set: Dispatch<SetStateAction<'base' | 'quote'>>;
  };
  selectedTokenSymbol: string;
  selectedPnlToggleValue: {
    get: 'realised' | 'unrealised' | 'net';
    set: Dispatch<SetStateAction<'realised' | 'unrealised' | 'net'>>;
  };
  selectedBots: {
    get: ISelectedBots;
    set: Dispatch<SetStateAction<ISelectedBots>>;
  };
  pnlValue: { value: string; percentage: string };
}

export const PNLContext = createContext<IPNLContext>({
  period: { get: 'ALL', set: () => {} },
  startDate: { get: 0, set: () => {} },
  endDate: { get: 0, set: () => {} },
  loading: { get: false, set: () => {} },
  selectedAccounts: { get: [], set: () => {} },
  selectedToken: { get: 'base', set: () => {} },
  selectedTokenSymbol: '',
  selectedPnlToggleValue: { get: 'realised', set: () => {} },
  selectedBots: { get: [] as ISelectedBots, set: () => {} },
  pnlValue: { value: '', percentage: '' },
});

export const PNLContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const pairId = cexPair.id;
  const { accountsToAPI } = useContext(ChartsControlsContext);

  const [loading, setLoading] = useState<boolean>(false);

  const [period, setPeriod] = useState<IChartRange | undefined>('1D');
  const [startDate, setStartDate] = useState<number | undefined>(undefined);
  const [endDate, setEndDate] = useState<number | undefined>(undefined);

  const [selectedAccounts, setSelectedAccounts] = useState<ICexAccount[]>([]);
  const [selectedToken, setSelectedToken] = useState<'quote' | 'base'>('quote');

  const [selectedPnlToggleValue, setSelectedPnlToggleValue] = useState<
    'realised' | 'unrealised' | 'net'
  >('realised');
  const [selectedBots, setSelectedBots] = useState<ISelectedBots>(
    CEX_BOT_LIST.map(b => ({ bot: b, enabled: true })),
  );

  const [balancePNL, setBalancePNL] = useState<IPnl>({
    net_pnl: '',
    net_pnl_percentage: '',
    realised_pnl: '',
    realised_pnl_percentage: '',
    unrealised_pnl: '',
    unrealised_pnl_percentage: '',
  });

  const periodToAPI = useMemo(() => getPeriodToAPI(period), [period]);

  const selectedTokenSymbol = useMemo(() => {
    return selectedToken === 'base' ? cexPair.token_base.symbol : cexPair.token_quote.symbol;
  }, [selectedToken, cexPair]);

  const pnlValue = useMemo(() => {
    switch (selectedPnlToggleValue) {
      case 'realised':
        return {
          value: balancePNL.realised_pnl,
          percentage: balancePNL.realised_pnl_percentage,
        };
      case 'unrealised':
        return {
          value: balancePNL.unrealised_pnl,
          percentage: balancePNL.unrealised_pnl_percentage,
        };
      case 'net':
        return {
          value: balancePNL.net_pnl,
          percentage: balancePNL.net_pnl_percentage,
        };
      default:
        return { value: '', percentage: '' };
    }
  }, [selectedPnlToggleValue, balancePNL]);

  const getBalancePnl = useCallback(async () => {
    try {
      const account_id = accountsToAPI(selectedAccounts).array;

      const symbol = `${cexPair.token_base.symbol}-${cexPair.token_quote.symbol}`;
      const cex = cexPair.cex;
      const period = periodToAPI;

      const [{ data: cexBalancesPnl }] = await Promise.all([
        ApiStatistic.getBalancePnl({
          account_id,
          symbol,
          cex,
          pairId,
          period: period,
          endDate,
          startDate,
        }),
      ]);

      setBalancePNL(cexBalancesPnl ?? ({} as IPnl));
    } catch (error) {
      console.log('error: ', error);
    }
  }, [cexPair, pairId, selectedAccounts, periodToAPI, startDate, endDate, accountsToAPI]);

  const getInfo = useCallback(async () => {
    try {
      setLoading(true);

      await Promise.all([getBalancePnl()]);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }, [getBalancePnl]);

  useDeepCompareEffect(() => {
    getInfo();
  }, [cexPair, selectedAccounts, startDate, endDate, periodToAPI]);

  return (
    <PNLContext.Provider
      value={{
        period: { get: period, set: setPeriod },
        startDate: { get: startDate, set: setStartDate },
        endDate: { get: endDate, set: setEndDate },
        loading: { get: loading, set: setLoading },
        selectedAccounts: { get: selectedAccounts, set: setSelectedAccounts },
        selectedToken: { get: selectedToken, set: setSelectedToken },
        selectedTokenSymbol,
        selectedPnlToggleValue: { get: selectedPnlToggleValue, set: setSelectedPnlToggleValue },
        selectedBots: { get: selectedBots, set: setSelectedBots },
        pnlValue,
      }}
    >
      {children}
    </PNLContext.Provider>
  );
};
