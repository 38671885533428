import React, { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import { isNil } from 'lodash';
import { UTCTimestamp } from 'lightweight-charts';

import { CHARTS_COLORS } from 'constant/charts';
import { chartFormatter } from 'utils/charts';
import { formatFiat } from 'utils/formats';
import { ICursorPosistion, ILogicalRange, IPriceFormat } from 'types/charts';
import { bn } from 'tools/math';
import { useCursorPosition, useVisibleLogicalRange } from 'hooks/charts';

import './style.scss';

interface IBalanceTooltipOldProps {
  priceFormat: IPriceFormat;
  balancesOuter: {
    records: {
      time: UTCTimestamp;
      value: number;
    }[];
    label: string;
  }[];
  cursorEvent: string;
  logicalEvent: string;
  totalBalancesUsd: {
    time: UTCTimestamp;
    value: number;
  }[];
}

const BalanceTooltipOld: React.FC<IBalanceTooltipOldProps> = ({
  priceFormat,
  balancesOuter,
  cursorEvent,
  logicalEvent,
  totalBalancesUsd,
}) => {
  const onChangeLogicalRange = useCallback((p: any) => {
    _setVisibleLogicalRange(p);
  }, []);
  const [visibleLogicalRange, _setVisibleLogicalRange] = useState<ILogicalRange>(undefined);
  useVisibleLogicalRange({
    event: logicalEvent,
    setter: onChangeLogicalRange,
  });

  const [cursorPosition, _setCursorPosition] = useState<ICursorPosistion>(undefined);

  const onChangeCursor = useCallback((p: ICursorPosistion) => {
    _setCursorPosition(p);
  }, []);

  useCursorPosition({
    event: cursorEvent,
    setter: onChangeCursor,
  });

  const firstVisibleIndex = useMemo(
    () => (visibleLogicalRange ? Math.ceil(visibleLogicalRange.from) : undefined),
    [visibleLogicalRange],
  );
  const lastVisibleIndex = useMemo(
    () => (visibleLogicalRange ? Math.floor(visibleLogicalRange.to) : undefined),
    [visibleLogicalRange],
  );

  const hoveredIndex = useMemo(() => {
    const result = cursorPosition
      ? cursorPosition === undefined
        ? lastVisibleIndex
        : cursorPosition.logical
      : lastVisibleIndex;

    const recordsLength = balancesOuter[0] ? balancesOuter[0].records.length : 0;

    if (!result) return recordsLength - 1;

    return result > recordsLength - 1 ? recordsLength - 1 : result;
  }, [lastVisibleIndex, cursorPosition, balancesOuter]);

  const balances = useMemo(
    () =>
      balancesOuter.map(el => ({
        value: el.records[hoveredIndex]?.value ?? 0,
        startValue:
          firstVisibleIndex !== undefined ? el.records[firstVisibleIndex]?.value ?? 0 : undefined,
        label: el.label,
      })),
    [balancesOuter, firstVisibleIndex, hoveredIndex],
  );

  const totalBalance = useMemo(
    () => (priceFormat === 'usd' ? totalBalancesUsd[hoveredIndex]?.value ?? 0 : undefined),
    [hoveredIndex, priceFormat, totalBalancesUsd],
  );

  const startTotalBalance = useMemo(
    () =>
      firstVisibleIndex !== undefined && priceFormat === 'usd'
        ? totalBalancesUsd[firstVisibleIndex]?.value ?? 0
        : undefined,
    [firstVisibleIndex, totalBalancesUsd, priceFormat],
  );

  const totalBalanceString = useMemo(() => {
    try {
      if (!totalBalance) return '';

      if (priceFormat === 'usd') {
        const bignumber = bn(totalBalance);

        return formatFiat(bignumber, 18);
      }

      return chartFormatter(totalBalance);
    } catch (e) {
      return '0';
    }
  }, [totalBalance, priceFormat]);

  const totalBalanceDiff = useMemo(
    () =>
      !isNil(totalBalance) && !isNil(startTotalBalance)
        ? totalBalance - startTotalBalance
        : undefined,
    [startTotalBalance, totalBalance],
  );

  const totalBalanceDiffString = useMemo(() => {
    try {
      if (!totalBalanceDiff) return '';

      if (priceFormat === 'usd') {
        const bignumber = bn(totalBalanceDiff);

        return formatFiat(bignumber, 18);
      }

      return chartFormatter(totalBalanceDiff);
    } catch (e) {
      return '';
    }
  }, [totalBalanceDiff, priceFormat]);

  const diffPercentage = useMemo(
    () =>
      !isNil(startTotalBalance) && startTotalBalance !== 0 && !isNil(totalBalanceDiff)
        ? Number(Math.abs((totalBalanceDiff / startTotalBalance) * 100).toFixed(2))
        : undefined,
    [totalBalanceDiff, startTotalBalance],
  );

  const _balances = useMemo(
    () =>
      balances.map(balance => {
        let balanceString = chartFormatter(balance.value);
        if (priceFormat === 'usd') {
          balanceString = formatFiat(bn(balance.value), 18);
        }

        const diff = !isNil(balance.startValue) ? balance.value - balance.startValue : undefined;

        const diffPercentage =
          !isNil(balance.startValue) && balance.startValue !== 0 && !isNil(diff)
            ? Number(Math.abs((diff / balance.startValue) * 100).toFixed(2))
            : undefined;

        let diffString = !isNil(diff) ? chartFormatter(diff) : '';
        if (priceFormat === 'usd' && !isNil(diff)) {
          diffString = formatFiat(bn(diff), 18);
        }

        return {
          label: balance.label,
          balanceString,
          diff,
          diffPercentage,
          diffString,
        };
      }),
    [balances, priceFormat],
  );

  return (
    <div className="mm-pair-balance-chart-tooltip">
      <div className="tooltip-header">
        <span className="tooltip-title">Balances</span>
      </div>
      {!isNil(totalBalance) && (
        <div className="tooltip-balance">
          <div className="total-balance-value">
            <div className="round" style={{ backgroundColor: CHARTS_COLORS[3] }} />
            <span className="caption">Total:</span>
            <span className="amount">{totalBalanceString}</span>
            {totalBalanceDiff !== undefined && diffPercentage !== undefined && (
              <div className="value">
                <span
                  className={cn('amount', {
                    green: totalBalanceDiff >= 0,
                    red: totalBalanceDiff < 0,
                  })}
                >
                  {totalBalanceDiff > 0 ? '+' : ''}
                  {totalBalanceDiffString}
                </span>
                <span
                  className={cn('amount', {
                    green: totalBalanceDiff >= 0,
                    red: totalBalanceDiff < 0,
                  })}
                >
                  ({totalBalanceDiff >= 0 ? '+' : '-'}
                  {diffPercentage}%)
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      {_balances.map((el, idx) => (
        <div className="tooltip-balance" key={idx}>
          <div className="total-balance-value">
            <div className="round" style={{ backgroundColor: CHARTS_COLORS[idx] }} />
            <span className="caption">{el.label}</span>
            <span className="amount">{el.balanceString}</span>
            {el.diff !== undefined && el.diffPercentage !== undefined && (
              <div className="value">
                <span
                  className={cn('amount', {
                    green: el.diff >= 0,
                    red: el.diff < 0,
                  })}
                >
                  {el.diff > 0 ? '+' : ''}
                  {el.diffString}
                </span>
                <span
                  className={cn('amount', {
                    green: el.diff >= 0,
                    red: el.diff < 0,
                  })}
                >
                  ({el.diff >= 0 ? '+' : '-'}
                  {el.diffPercentage}%)
                </span>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export { BalanceTooltipOld };
