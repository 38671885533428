import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { ApiAuth, ApiUsers } from 'api';
import { LocalStorage } from 'tools';
import { ENetwork } from 'web3';
import { IGasPriceResponse } from 'api/apiDictionary/models';
import { IAuthUserMe } from 'api/apiUsers/models';

type IGasPrices = Record<ENetwork, IGasPriceResponse | undefined>;

interface IAuthSliceState {
  jwt: null | string;
  authLoading: boolean;
  authError: null | string;
  user: IAuthUserMe | null;
  isAdmin: boolean;
  isUser: boolean;
  gasPrices: IGasPrices;
}

const defaultGasPrices: IGasPrices = {
  bsc: undefined,
  eth: undefined,
  polygon: undefined,
  arbitrum_one: undefined,
  base: undefined,
  unit0: undefined,
};

const lsJwt = LocalStorage.get<string>('jwt');
const lsUser = LocalStorage.get<IAuthUserMe>('user');

const authSliceInitialState: IAuthSliceState = {
  jwt: lsJwt ? lsJwt : null,
  authLoading: false,
  authError: null,
  ...(lsUser
    ? {
        user: lsUser,
        isAdmin: lsUser.role === 'admin',
        isUser: lsUser.role !== 'admin',
        gasPrices: defaultGasPrices,
      }
    : {
        user: null,
        isAdmin: false,
        isUser: false,
        gasPrices: defaultGasPrices,
      }),
};

const onLogin = createAsyncThunk<
  any,
  { login: string; password: string; onSuccess: () => void },
  any
>('auth/login', async ({ login, password, onSuccess }, thunkApi) => {
  try {
    const { data } = await ApiAuth.login({ login, password });

    const jwt = data?.jwt ?? '';

    const { isSuccess, errorMessage, data: user } = await ApiUsers.getMe(jwt);

    if (isSuccess) {
      setTimeout(() => {
        onSuccess();
      }, 50);

      return thunkApi.fulfillWithValue({ jwt, user });
    } else {
      return thunkApi.rejectWithValue(errorMessage as string);
    }
  } catch (error) {
    console.log(error);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: authSliceInitialState as IAuthSliceState,
  extraReducers: builder => {
    // login
    builder.addCase(onLogin.pending, state => {
      state.authLoading = true;
    });
    builder.addCase(onLogin.rejected, (state, action) => {
      state.authError = action.payload as string;
      state.authLoading = false;
    });
    builder.addCase(
      onLogin.fulfilled,
      (state, { payload }: { payload: { jwt: string; user: IAuthUserMe } }) => {
        const { jwt, user } = payload;

        state.jwt = jwt;
        state.authLoading = false;
        state.authError = null;
        state.user = user;
        state.isAdmin = user.role === 'admin';
        state.isUser = user.role === 'user';

        LocalStorage.set('jwt', jwt);
        LocalStorage.set('user', user);
      },
    );
  },
  reducers: {
    setAuthError: (state, { payload }: { payload: string | null }) => {
      state.authError = payload;
    },
    logout: state => {
      LocalStorage.remove('jwt');
      LocalStorage.remove('user');

      state.jwt = null;
      state.gasPrices = defaultGasPrices;
      state.authError = null;
      state.authLoading = false;
      state.isAdmin = false;
      state.isUser = false;
      state.user = null;
    },
    setGasPrice: (
      state,
      {
        payload: { network, gasPrice },
      }: {
        payload: {
          network: ENetwork;
          gasPrice: IGasPriceResponse | undefined;
        };
      },
    ) => {
      state.gasPrices[network] = gasPrice;
    },
  },
});

export { onLogin };
export const { setAuthError, logout, setGasPrice } = authSlice.actions;

export default authSlice.reducer;
