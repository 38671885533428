import React, { useMemo } from 'react';

import './style.scss';

interface ITooltipProps {
  values: { value: number; color: string; label: string }[];
  priceFormatter: (num: number) => string;
}

const Tooltip: React.FC<ITooltipProps> = ({ values, priceFormatter }) => {
  return (
    <div className="mm-pnl-chart-tooltip">
      {values.map(value => (
        <div key={value.label} className="tooltip-amount">
          <div className="total-amount-value">
            <span className="caption">{value.label}:</span>
            <span className="amount" style={{ color: value.color }}>
              {priceFormatter(value.value)}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export { Tooltip };
