import { ResolutionString, SubscribeBarsCallback } from 'charting_library';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { Logical, Time } from 'lightweight-charts';

import { ECexOrderSide } from './orders';

dayjs.extend(duration);

export type IPriceFormat = 'usd' | 'token';

export enum EChartFormat {
  Percentage = 'percentage',
  Normal = 'normal',
}

type INumber = number;

type IHoursRange = `${INumber}H`;
type IDaysRange = `${INumber}D`;
type IWeeksRange = `${INumber}W`;
type IMonthsRange = `${INumber}M`;
type IYearsRange = `${INumber}Y`;
type IAllRange = 'ALL';

export type IChartRange =
  | IHoursRange
  | IDaysRange
  | IWeeksRange
  | IMonthsRange
  | IYearsRange
  | IAllRange;

export type IChartTimeframe = '1m' | '5m' | '15m' | '1h' | '4h' | '24h' | '168h';

export const mapChartRangeToRangeAPI = (range: IChartRange): IChartRange => {
  if (range === 'ALL') return 'ALL';

  const rangeNumber = Number(
    range
      .split('')
      .filter(el => el.match(/^\d$/))
      .join(''),
  );

  // is hours
  if (range.includes('H')) {
    return `${rangeNumber}H`;
  }

  // is days
  if (range.includes('D')) {
    return `${rangeNumber}D`;
  }

  // is weeks
  if (range.includes('W')) {
    return `${rangeNumber}W`;
  }

  // is month
  if (range.includes('M')) {
    return `${rangeNumber}M`;
  }

  // is year
  if (range.includes('Y')) {
    return `${rangeNumber}Y`;
  }

  return `${rangeNumber}` as IChartRange;
};

export const mapChartRangeToCaption = (range: IChartRange) => {
  if (range === 'ALL') return 'ALL';

  const rangeNumber = Number(
    range
      .split('')
      .filter(el => el.match(/^\d$/))
      .join(''),
  );

  // is hours
  if (range.includes('H')) {
    return `${rangeNumber}H`;
  }

  // is days
  if (range.includes('D')) {
    return `${rangeNumber}D`;
  }

  // is weeks
  if (range.includes('W')) {
    return `${rangeNumber}W`;
  }

  // is month
  if (range.includes('M')) {
    return `${rangeNumber}M`;
  }

  // is year
  if (range.includes('Y')) {
    return `${rangeNumber}Y`;
  }
};

export const mapChartTimeframeToCaption = (chartTimeframe: IChartTimeframe) => {
  switch (chartTimeframe) {
    case '15m':
      return '15m';
    case '1m':
      return '1m';
    case '5m':
      return '5m';
    case '1h':
      return '1H';
    case '4h':
      return '4H';
    case '24h':
      return '1D';
    case '168h':
      return '1W';
  }
};

export interface ICexChartPoint {
  time: number;
  close: number;
  high: number;
  low: number;
  open: number;
  volume: number;
  baseBalance: number;
  baseBalanceUsd: number;
  quoteBalane: number;
  quoteBalanceUsd: number;
  internalVolume: number;
  balanceTotalUsd: number;
}

export interface IChartOrder {
  price: number;
  amount: string;
  side: ECexOrderSide;
  label: string;
  account_id: number;
  pair_id: number;
  cex_order_id: string;
}

export enum ETradingViewEvents {
  SubscribeBarsEvent = 'tvsubscribe',
  UnsubscribeBarsEvent = 'tvunsubscribe',
  UpdateRecentBars = 'tvupdaterecentbars',
}

export interface TVSubscribeParams {
  listenerGuid: string;
  resolution: ResolutionString;
  onTick: SubscribeBarsCallback;
  onResetCacheNeededCallback: () => void;
}

export interface TVUnsubscribeParams {
  listenerGuid: string;
}

export type ICursorPosistion = { time: Time; logical: Logical | undefined } | undefined;

export type ILogicalRange = { from: number; to: number } | undefined;

export type ITimescaleRange = { from: number; to: number } | undefined;
