import { isTestnet } from './config';

import pancakeLogoV2 from 'assets/images/dex/pancakeswapV2Logo.png';
import panckageV2LogoWordMark from 'assets/images/dex/pancakeswapV2LogoWordMark.png';
import pancakeLogoV3 from 'assets/images/dex/pancakeswapV3Logo.png';
import panckageV3LogoWordMark from 'assets/images/dex/pancakeswapV3LogoWordMark.png';

import uniswapLogoV2 from 'assets/images/dex/uniswapLogoV2.svg';
import uniswapLogoV2WordMark from 'assets/images/dex/uniswapLogoV2WordMark.svg';
import uniswapLogoV3 from 'assets/images/dex/uniswapLogoV3.svg';
import uniswapLogoV3WordMark from 'assets/images/dex/uniswapLogoV3WordMark.svg';
import quickswapLogo from 'assets/images/dex/quickswapLogo.svg';
import quickswapLogoWordMark from 'assets/images/dex/quickswapLogoWordMark.svg';
import sushiswapLogo from 'assets/images/dex/sushiswapLogo.svg';
import sushiswapLogoWordMark from 'assets/images/dex/sushiswapLogoWordMark.png';
import camelotLogo from 'assets/images/dex/camelotLogo.png';
import camelotLogoWordMark from 'assets/images/dex/camelotLogoWordMark.webp';
import swopfiLogo from 'assets/images/dex/swopfiLogo.png';
import swopfiLogoWordMark from 'assets/images/dex/swopfiLogoWordMark.png';

export type IDexName =
  | 'uniswap_v2'
  | 'uniswap_v2:base'
  | 'uniswap_v3'
  | 'uniswap_v3:polygon'
  | 'uniswap_v3:arbitrum_one'
  | 'uniswap_v3:base'
  | 'pancakeswap_v2'
  | 'pancakeswap_v3:bsc'
  | 'quickswap_v2'
  | 'sushiswap_v2'
  | 'sushiswap_v3:haqq'
  | 'camelot_v2'
  | 'swopfi_v2:unit0';

interface IConfig {
  fee: number;
  icon: string;
  iconText: string;
}

export const dexConfigs: Record<IDexName, IConfig> = {
  uniswap_v3: {
    fee: 0.3,
    icon: uniswapLogoV3,
    iconText: uniswapLogoV3WordMark,
  },
  'uniswap_v3:arbitrum_one': {
    fee: 0.3,
    icon: uniswapLogoV3,
    iconText: uniswapLogoV3WordMark,
  },
  'uniswap_v3:polygon': {
    fee: 0.3,
    icon: uniswapLogoV3,
    iconText: uniswapLogoV3WordMark,
  },
  'uniswap_v3:base': {
    fee: 0.3,
    icon: uniswapLogoV3,
    iconText: uniswapLogoV3WordMark,
  },
  uniswap_v2: {
    fee: 0.3,
    icon: uniswapLogoV2,
    iconText: uniswapLogoV2WordMark,
  },
  'uniswap_v2:base': {
    fee: 0.3,
    icon: uniswapLogoV2,
    iconText: uniswapLogoV2WordMark,
  },
  pancakeswap_v2: {
    fee: isTestnet ? 0.2 : 0.25,
    icon: pancakeLogoV2,
    iconText: panckageV2LogoWordMark,
  },
  'pancakeswap_v3:bsc': {
    fee: 0.3,
    icon: pancakeLogoV3,
    iconText: panckageV3LogoWordMark,
  },
  quickswap_v2: {
    fee: 0.3,
    icon: quickswapLogo,
    iconText: quickswapLogoWordMark,
  },
  sushiswap_v2: {
    fee: 0.3,
    icon: sushiswapLogo,
    iconText: sushiswapLogoWordMark,
  },
  'sushiswap_v3:haqq': {
    fee: 0.3,
    icon: sushiswapLogo,
    iconText: sushiswapLogoWordMark,
  },
  camelot_v2: {
    fee: 0.3,
    icon: camelotLogo,
    iconText: camelotLogoWordMark,
  },
  'swopfi_v2:unit0': {
    fee: 0.3,
    icon: swopfiLogo,
    iconText: swopfiLogoWordMark,
  },
};
