import { configureStore } from '@reduxjs/toolkit';
import { useSelector, useDispatch, TypedUseSelectorHook } from 'react-redux';

import {
  authSlice,
  projectsSlice,
  dictionarySlice,
  uiSlice,
  pairsSlice,
  pairConnectedBots,
  filtersSlice,
  pairConfigsSlice,
} from './slices';

const store = configureStore({
  reducer: {
    auth: authSlice,
    projects: projectsSlice,
    dictionary: dictionarySlice,
    ui: uiSlice,
    pairs: pairsSlice,
    pairConnectedBots: pairConnectedBots,
    filters: filtersSlice,
    pairConfigs: pairConfigsSlice,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootStore = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useTypedSelector: TypedUseSelectorHook<RootStore> = useSelector;
export const useTypedDispatch: () => AppDispatch = useDispatch;

export default store;
