import {
  bscTestnet,
  sepolia,
  arbitrumSepolia,
  bsc,
  mainnet,
  polygon,
  arbitrum,
  base,
  Chain,
  haqqMainnet,
} from 'wagmi/chains';

//networks
import bscLogo from 'assets/images/networks/bsc.png';
import ethLogo from 'assets/images/networks/eth.svg';
import polygonLogo from 'assets/images/networks/polygon.png';
import arbitrumLogo from 'assets/images/networks/arbitrum.png';
import baseLogo from 'assets/images/networks/base.png';
import unitZeroLogo from 'assets/images/networks/unit-zero.png';

//scans
import bscScanSrc from 'assets/images/scans/bscscan-logo.webp';
import ethScanSrc from 'assets/images/scans/logo-etherscan.png';
import polygonScanSrc from 'assets/images/scans/logo-polygonscan.png';
import arbiScanSrc from 'assets/images/scans/logo-arbiscan.svg';
import baseScanSrc from 'assets/images/scans/logo-basescan.png';
import haqqScanSrc from 'assets/images/scans/logo-haqqscan.jpg';

export const isTestnet = import.meta.env.VITE_APP_NETWORK_TYPE === 'testnet';

export enum EExchange {
  cex = 'cex',
  dex = 'dex',
}

export enum ENetwork {
  eth = 'eth',
  bsc = 'bsc',
  polygon = 'polygon',
  arbitrum = 'arbitrum_one',
  base = 'base',
  unit0 = 'unit0',
  haqq = 'haqq',
}

interface IConfig {
  chainId: number;
  chainName: string;
  wagmiChain: Chain;
  dextoolsSlug?: string;
  kattanaSlug: string;
}

interface ICommonConfig {
  label: string;
  rpcUrls: string[];
  scanUrl: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  logo: string;
  scanLogo: string;
}

export const unitZeroMainnet: Chain = {
  id: 88811,
  name: 'Unit Zero Mainnet',
  nativeCurrency: {
    name: 'UNIT0',
    symbol: 'UNIT0',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: [import.meta.env.VITE_APP_UNIT0_RPC_URL],
    },
  },
  blockExplorers: {
    default: { name: 'Unit Zero Explorer', url: import.meta.env.VITE_APP_UNIT0_SCAN_URL },
  },
};

const testnets: Partial<Record<ENetwork, IConfig>> = {
  [ENetwork.bsc]: {
    chainId: 97,
    chainName: 'BSC testnet',
    wagmiChain: bscTestnet,
    kattanaSlug: 'BSC_TESTNET',
  },
  [ENetwork.eth]: {
    chainId: 11155111,
    chainName: 'Ethereum sepolia testnet',
    wagmiChain: sepolia,
    kattanaSlug: 'ETH_SEPOLIA_TESTNET',
  },
  [ENetwork.arbitrum]: {
    chainId: 421614,
    chainName: 'Arbitrum Sepolia testnet',
    wagmiChain: arbitrumSepolia,
    kattanaSlug: 'ARBITRUM_SEPOLIA_TESTNET',
  },
};

const mainnets: Record<ENetwork, IConfig> = {
  [ENetwork.bsc]: {
    chainId: 56,
    chainName: 'Binance smart chain',
    wagmiChain: bsc,
    dextoolsSlug: 'bnb',
    kattanaSlug: 'BSC',
  },
  [ENetwork.eth]: {
    chainId: 1,
    chainName: 'Ethereum Mainnet',
    wagmiChain: mainnet,
    dextoolsSlug: 'ether',
    kattanaSlug: 'ETH',
  },
  [ENetwork.polygon]: {
    chainId: 137,
    chainName: 'Polygon Mainnet',
    wagmiChain: polygon,
    dextoolsSlug: 'polygon',
    kattanaSlug: 'POLYGON',
  },
  [ENetwork.arbitrum]: {
    chainId: 42161,
    chainName: 'Arbitrum One Mainnet',
    wagmiChain: arbitrum,
    dextoolsSlug: 'arbitrum',
    kattanaSlug: 'ARBITRUM',
  },
  [ENetwork.base]: {
    chainId: 8453,
    chainName: 'Base Mainnet',
    wagmiChain: base,
    dextoolsSlug: 'base',
    kattanaSlug: 'BASE',
  },
  [ENetwork.unit0]: {
    chainId: 88811,
    chainName: 'Unit Zero Mainnet',
    wagmiChain: unitZeroMainnet,
    dextoolsSlug: '',
    kattanaSlug: '',
  },
  [ENetwork.haqq]: {
    chainId: 11235,
    chainName: 'Haqq Network',
    wagmiChain: haqqMainnet,
    dextoolsSlug: '',
    kattanaSlug: '',
  },
};

const commonConfigs: Record<ENetwork, ICommonConfig> = {
  [ENetwork.bsc]: {
    label: 'BSC',
    rpcUrls: [import.meta.env.VITE_APP_BSC_RPC_URL],
    scanUrl: import.meta.env.VITE_APP_BSC_SCAN,
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    logo: bscLogo,
    scanLogo: bscScanSrc,
  },
  [ENetwork.eth]: {
    label: 'Ethereum',
    rpcUrls: [import.meta.env.VITE_APP_ETH_RPC_URL],
    scanUrl: import.meta.env.VITE_APP_ETH_SCAN,
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    logo: ethLogo,
    scanLogo: ethScanSrc,
  },
  [ENetwork.polygon]: {
    label: 'Polygon',
    rpcUrls: [import.meta.env.VITE_APP_POLYGON_RPC_URL],
    scanUrl: import.meta.env.VITE_APP_POLYGON_SCAN,
    nativeCurrency: {
      name: 'Polygon',
      symbol: 'POL',
      decimals: 18,
    },
    logo: polygonLogo,
    scanLogo: polygonScanSrc,
  },
  [ENetwork.arbitrum]: {
    label: 'Arbitrum',
    rpcUrls: [import.meta.env.VITE_APP_ARBITRUM_RPC_URL],
    scanUrl: import.meta.env.VITE_APP_ARBI_SCAN_URL,
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    logo: arbitrumLogo,
    scanLogo: arbiScanSrc,
  },
  [ENetwork.base]: {
    label: 'Base',
    rpcUrls: [import.meta.env.VITE_APP_BASE_RPC_URL],
    scanUrl: import.meta.env.VITE_APP_BASE_SCAN_URL,
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    logo: baseLogo,
    scanLogo: baseScanSrc,
  },
  [ENetwork.unit0]: {
    label: 'Unit Zero',
    rpcUrls: [import.meta.env.VITE_APP_UNIT0_RPC_URL],
    scanUrl: import.meta.env.VITE_APP_UNIT0_SCAN_URL,
    nativeCurrency: {
      name: 'UNIT0',
      symbol: 'UNIT0',
      decimals: 18,
    },
    logo: unitZeroLogo,
    scanLogo: unitZeroLogo,
  },
  [ENetwork.haqq]: {
    label: 'Haqq Network',
    rpcUrls: [import.meta.env.VITE_APP_HAQQ_RPC_URL],
    scanUrl: import.meta.env.VITE_APP_HAQQ_SCAN_URL,
    nativeCurrency: {
      name: 'Islamic Coin',
      symbol: 'ISLM',
      decimals: 18,
    },
    logo: haqqScanSrc,
    scanLogo: haqqScanSrc,
  },
};

export const supportedWagmiChains = Object.values(ENetwork)
  .filter(el => typeof el === 'string')
  .map(network => ({
    wagmiChain: isTestnet
      ? testnets[network]?.wagmiChain ?? mainnets[network].wagmiChain
      : mainnets[network].wagmiChain,
    rpcUrl: commonConfigs[network].rpcUrls[0],
  }));

export const chains = Object.values(ENetwork)
  .filter(el => typeof el === 'string')
  .reduce((acc, network) => {
    return {
      ...acc,
      [network]: isTestnet
        ? testnets[network]?.chainId ?? mainnets[network].chainId
        : mainnets[network].chainId,
    };
  }, {}) as Record<ENetwork, number>;

export const web3Configs = Object.values(ENetwork)
  .filter(el => typeof el === 'string')
  .reduce((acc, network) => {
    const conf = isTestnet ? testnets[network] ?? mainnets[network] : mainnets[network];
    const commonConf = commonConfigs[network] ?? {};

    return {
      ...acc,
      [network]: {
        ...conf,
        ...commonConf,
      },
    };
  }, {}) as Record<ENetwork, IConfig & ICommonConfig>;

export type IRange = 'ALL' | '4H' | '1M' | '1D' | '1W';
