import { useMemo, useEffect, useCallback } from 'react';
import { useTypedSelector } from 'store';

import { Bus } from 'tools';
import { EExchange } from 'web3';
import { ICursorPosistion } from 'types/charts';

const useCursorPosition = ({
  event,
  setter,
}: {
  event: string;
  setter?: (cursorPosition: any) => void;
}) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const exchange = useMemo(
    () => (dexPair ? EExchange.dex : cexPair ? EExchange.cex : EExchange.dex),
    [dexPair, cexPair],
  );

  const pair = useMemo(
    () => (exchange === EExchange.dex ? dexPair : cexPair),
    [exchange, cexPair, dexPair],
  );

  useEffect(() => {
    if (setter && event) {
      Bus.on(event, setter);
    }
    return () => {
      if (setter && event) {
        Bus.off(event, setter);
      }
    };
  }, [event, pair, setter]);

  const setCursorPosition = useCallback(
    (param: ICursorPosistion) => {
      if (setter) {
        setter(param);
      }

      Bus.emit(event, param);
    },
    [event, setter],
  );

  return { setCursorPosition };
};

export { useCursorPosition };
