import { useCallback, useMemo, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { useTypedSelector } from 'store';
import useWebSocket from 'react-use-websocket';

import { ApiOrders } from 'api';
import { bn, divideBignumbers, humanizeBn } from 'tools';
import { ICexActiveOrder, ICexActiveOrderBot } from 'types/orders';
import { ICexAccount } from 'types/accounts';
import { IChartOrder } from 'types/charts';
import { metricsBaseUrl } from 'api/apiBase/baseUrl';

interface IGetOrdersArgs {
  selectedAccounts: ICexAccount[];
  accountsToAPI: (selectedAccounts: ICexAccount[]) => {
    array: number[];
    optional?: number[];
  };
}

interface IInnerActiveOrder extends ICexActiveOrder {
  account_notes?: string;
  price: BigNumber;
  priceNumber: number;
  label: string;
}

const useActiveOrders = ({ accounts, pairId }: { accounts: ICexAccount[]; pairId: number }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [activeOrders, setActiveOrders] = useState<IInnerActiveOrder[]>([]);
  const [activeOrdersCount, setActiveOrdersCount] = useState<number>(0);

  const isAdmin = useTypedSelector(store => store.auth.isAdmin);
  const jwt = useTypedSelector(store => store.auth.jwt);

  const formatOrders = useCallback(
    (orders?: ICexActiveOrder[]) => {
      const botToOrder = (bot: ICexActiveOrderBot) => {
        switch (bot) {
          case 'abuse_protection': {
            return 'AP';
          }
          case 'arbitrage': {
            return 'AR';
          }
          case 'buysell': {
            return 'BS';
          }
          case 'cm': {
            return 'CM';
          }
          case 'counter-order': {
            return 'CO';
          }
          case 'fg': {
            return 'FG';
          }
          case 'order-manager-bot': {
            return 'OM';
          }
          case 'orderbook': {
            return 'OB';
          }
          case 'orderbook_dynamic': {
            return 'OBD';
          }
          case 'organic': {
            return 'ORG';
          }
          case 'regular': {
            return 'REG';
          }
          case 'unknown': {
            return 'UNK';
          }
          default: {
            return '';
          }
        }
      };

      return (orders ?? [])
        .map(el => {
          const findAccount = accounts.find(account => account.id === el.account_id);

          const quoteAmountBN = bn(el.quote_amount);
          const baseAmountBN = bn(el.base_amount);

          const price = divideBignumbers([quoteAmountBN, 18], [baseAmountBN, 18]);
          const priceNumber = Number(humanizeBn(price, 18));

          return {
            ...el,
            account_notes: findAccount ? findAccount.notes : undefined,
            price,
            priceNumber,
            label: botToOrder(el.bot),
          };
        })
        .sort((a, b) => {
          if (a.priceNumber >= b.priceNumber) return -1;

          if (b.priceNumber >= a.priceNumber) return 1;

          return 0;
        });
    },
    [accounts],
  );

  const getOrders = useCallback(
    async ({ selectedAccounts, accountsToAPI }: IGetOrdersArgs, options?: { silent: boolean }) => {
      const isSilent = options?.silent;

      try {
        if (!isAdmin) return;

        if (!isSilent) {
          setLoading(true);
        }

        const { data } = await ApiOrders.getCexActiveOrders({
          pair_id: pairId,
          limit: 100,
          offset: 0,
          account_id: accountsToAPI(selectedAccounts).array,
        });

        setActiveOrders(formatOrders(data?.orders ?? []));

        setActiveOrdersCount(Number(data?.total_count?.toString() ?? 0));
      } catch (error) {
        console.log('error: ', error);
      } finally {
        if (!isSilent) {
          setLoading(false);
        }
      }
    },
    [pairId, formatOrders, isAdmin],
  );

  useWebSocket(metricsBaseUrl + `/api/v1/orders/ws/active?pair_id=${pairId}`, {
    protocols: jwt ? ['Bearer', jwt] : [],
    onOpen: () => {},
    onMessage: (event: any) => {
      try {
        const newOrders = JSON.parse(event.data);

        setActiveOrders(formatOrders(newOrders));
        setActiveOrdersCount(newOrders?.length ?? 0);
      } catch (error) {
        console.log('error: ', error);
      }
    },
    shouldReconnect: () => true,
  });

  const chartOrders = useMemo<IChartOrder[]>(() => {
    return activeOrders.map(order => {
      return {
        side: order.side,
        price: order.priceNumber,
        amount: order.base_amount,
        label: order.label,
        account_id: order.account_id,
        pair_id: order.pair_id,
        cex_order_id: order.cex_order_id,
      };
    });
  }, [activeOrders]);

  return {
    getOrders,
    loading,
    activeOrders,
    activeOrdersCount,
    chartOrders,
  };
};

export { useActiveOrders };
