import React, { createContext, useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTypedDispatch, useTypedSelector } from 'store';

import { ApiDexBuySellBot } from 'api';
import { Bus } from 'tools';
import { useDexBotConfig } from 'hooks/dex';
import { EDexBot, IDexBotConfig, IDexBuySellBotTaskItem } from 'types/bots';
import { IBuySellTaskStatus, IBuySellBotTaskItemInner } from 'types/buy-sell-bot';
import { PAIR_BUYSELL_BOT_RELOAD, STOP_RELOAD_PREFIX } from 'constant/reload';
import { setAlertState, dropAlertState } from 'store/slices/ui';

interface IDexBuySellBotContext {
  loading: { get: boolean; set: (v: boolean) => void };
  records: {
    get: IBuySellBotTaskItemInner[] | undefined;
    set: (v: IBuySellBotTaskItemInner[] | undefined) => void;
  };
  botSettings: IDexBotConfig<EDexBot.buy_sell_bot> | undefined;
  handleLoadRecords: () => void;
  handleLoadBotSettings: () => void;
  extendedRow: number | undefined;
  setExtendedRow: (v: number | undefined) => void;
  errorMessage: string | undefined;
  taskEnablingLoading: number | undefined;
  handleEnableTask: (taskId: number, flag: boolean) => Promise<void>;
}

export const DexBuySellBotContext = createContext<IDexBuySellBotContext>({
  loading: { get: false, set: () => {} },
  records: { get: undefined, set: () => {} },
  botSettings: undefined,
  handleLoadRecords: () => {},
  handleLoadBotSettings: () => {},
  extendedRow: undefined,
  setExtendedRow: () => {},
  errorMessage: undefined,
  taskEnablingLoading: undefined,
  handleEnableTask: async () => {},
});

interface IDexBuySellBotContextProviderProps {
  children?: React.ReactNode;
}

export const DexBuySellBotContextProvider: React.FC<IDexBuySellBotContextProviderProps> = ({
  children,
}) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const dispatch = useTypedDispatch();

  const [loading, setLoading] = useState<boolean>(true);
  const [records, setRecords] = useState<IBuySellBotTaskItemInner[] | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [extendedRow, setExtendedRow] = useState<number | undefined>(undefined);
  const [botSettings, setBotSettings] = useState<IDexBotConfig<EDexBot.buy_sell_bot> | undefined>(
    undefined,
  );
  const [taskEnablingLoading, setTaskEnablingLoading] = useState<number | undefined>(undefined);

  const { getBotConfig } = useDexBotConfig();

  const handleSetExtendedRow = useCallback((index: number | undefined) => {
    if (index == undefined) {
      setExtendedRow(undefined);
    } else {
      setExtendedRow(v => (v === index ? undefined : index));
    }
  }, []);

  const parseRecords = useCallback((records: IDexBuySellBotTaskItem[]) => {
    return records.map(el => {
      const start_time = dayjs(el.task.options.start_time).valueOf();
      let status = 'past';

      if (start_time >= Date.now()) {
        status = 'future';
      } else if (Number(el.total.amount_percent) === 100) {
        status = 'past';
      } else {
        status = 'current';
      }

      return {
        ...el,
        status: status as IBuySellTaskStatus,
      };
    });
  }, []);

  const handleLoadRecords = useCallback(
    async (params?: { silent: boolean }) => {
      const isSilent = params?.silent ?? false;

      if (!isSilent) {
        setLoading(true);
        setExtendedRow(undefined);
        setErrorMessage(undefined);
      }

      try {
        const { isSuccess, errorMessage, data } = await ApiDexBuySellBot.getBuySellTasks({
          pairId: dexPair.id,
        });

        if (isSuccess && data) {
          setRecords(data.tasks ? parseRecords(data.tasks) : undefined);
          setErrorMessage(undefined);
        } else {
          setRecords(undefined);
          setErrorMessage(errorMessage ?? undefined);
        }
      } catch (error) {
        console.log(error);
        setErrorMessage(
          'You get an error while trying to get buy sell bot records. Try to refresh your page...',
        );
      } finally {
        if (!isSilent) {
          setLoading(false);
        }
      }
    },
    [dexPair, parseRecords],
  );

  const handleLoadBotSettings = useCallback(
    async (params?: { silent: boolean }) => {
      const isSilent = params?.silent ?? false;

      if (!isSilent) {
        setLoading(true);
      }

      const botConfig = await getBotConfig({ pairId: dexPair.id, bot: EDexBot.buy_sell_bot });

      setBotSettings(botConfig);

      if (!isSilent) {
        setLoading(false);
      }
    },
    [dexPair, getBotConfig],
  );

  const handleEnableTask = useCallback(
    async (taskId: number, flag: boolean) => {
      const findTask = records?.find(el => el.task.id === taskId);

      if (!findTask) return;

      try {
        setTaskEnablingLoading(taskId);

        const updatedTaskOpts = {
          ...findTask.task.options,
          enabled: flag,
        };

        const { isSuccess, errorMessage } = await ApiDexBuySellBot.updateBuySellTask({
          taskId: findTask.task.id,
          options: updatedTaskOpts,
        });

        if (!isSuccess) {
          dispatch(
            setAlertState({
              type: 'failed-img',
              text: errorMessage ?? 'Something went wrong',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                dispatch(dropAlertState());
              },
            }),
          );
        } else {
          setRecords(v => {
            if (!v) return v;

            const newV = [...v];
            const findIndex = newV.findIndex(el => el.task.id === taskId);

            newV[findIndex].task.options.enabled = flag;

            return newV;
          });
        }
      } catch (error) {
        console.log('error: ', error);
      } finally {
        setTaskEnablingLoading(undefined);
      }
    },
    [records, dispatch],
  );

  useEffect(() => {
    handleLoadRecords();

    //eslint-disable-next-line
  }, []);

  const handleUpdate = useCallback(async () => {
    const startTime = Date.now();

    try {
      await Promise.all([
        handleLoadBotSettings({ silent: true }),
        handleLoadRecords({ silent: true }),
      ]);
    } catch (e) {}

    const endTime = Date.now();

    if (endTime - startTime >= 1500) {
      Bus.emit(STOP_RELOAD_PREFIX + PAIR_BUYSELL_BOT_RELOAD);
    } else {
      setTimeout(() => {
        Bus.emit(STOP_RELOAD_PREFIX + PAIR_BUYSELL_BOT_RELOAD);
      }, 1500 - (endTime - startTime));
    }
  }, [handleLoadBotSettings, handleLoadRecords]);

  useEffect(() => {
    Bus.on(PAIR_BUYSELL_BOT_RELOAD, handleUpdate);

    return () => {
      Bus.off(PAIR_BUYSELL_BOT_RELOAD, handleUpdate);
    };
  }, [handleUpdate]);

  return (
    <DexBuySellBotContext.Provider
      value={{
        loading: { get: loading, set: setLoading },
        records: { get: records, set: setRecords },
        botSettings,
        handleLoadRecords,
        handleLoadBotSettings,
        errorMessage,
        extendedRow: extendedRow,
        setExtendedRow: handleSetExtendedRow,
        taskEnablingLoading,
        handleEnableTask,
      }}
    >
      {children}
    </DexBuySellBotContext.Provider>
  );
};
