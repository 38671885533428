import React, { useState, useCallback, lazy } from 'react';

import { Panel } from 'ui';

import { RoundChartIcon } from 'assets/icons';
import { IPanelProps } from 'types/pairs';

const Content = lazy(() => import('./Content'));

const DayTrader: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  const [isPanelOpened, setIsPanelOpened] = useState<boolean>(initialOpened ?? false);

  const handleSwitch = useCallback(
    (opened: boolean) => {
      setIsPanelOpened(opened);
      setIsOpened?.(opened);
    },
    [setIsOpened],
  );

  return (
    <Panel
      title="Charts"
      icon={RoundChartIcon}
      initialOpened={initialOpened}
      onChange={handleSwitch}
      className="_overflow-disable"
    >
      <Content isPanelOpened={isPanelOpened} />
    </Panel>
  );
};

export { DayTrader };
