import { memo, FC, useState, useEffect, useCallback, useMemo } from 'react';
import { useRendersCount } from 'react-use';
import dayjs from 'dayjs';
import { motion, AnimatePresence } from 'framer-motion';
import { useTypedSelector } from 'store';
import { capitalize, isNil } from 'lodash';

import { useTaskTime, useFeeCostsUiString, useConvertedCurrencies } from 'hooks/dex';
import { Button, Modal, ToggleRadio, ButtonLoading, ErrorText } from 'ui';
import { DateField, DurationField, InputField } from 'fields';
import { CreateTaskForm } from 'ui/forms';
import { useTaskModal } from './useTaskModal/useTaskModal';
import { mempoolConfigMap, MempoolConfigMap, revertedMempoolConfigMap } from 'constant/common';
import { ENetwork } from 'web3';
import { IDexBuySellBotDirection } from 'types/bots';
import { IDexPair } from 'types/pairs';
import { IAddPairArgs } from 'api/apiPairs/models';
import { IBuySellBotTaskItemInner } from 'types/buy-sell-bot';

import { Currencies } from './Currencies';

import './index.scss';

type Direction = 'Sell' | 'Buy';

type Props = {
  onClose: () => void;
  onOpen: () => void;
  mode: 'create' | 'edit';
  task?: IBuySellBotTaskItemInner;
};

export const DexBuySellTaskModal: FC<Props> = memo(({ onClose, mode, task }) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const [direction, setDirection] = useState<Capitalize<IDexBuySellBotDirection>>('Sell');
  const rendersCount = useRendersCount();

  const {
    loading,
    values,
    handleSubmit,
    setFieldValue,
    formError,
    memPoolConfig,
    setMemPoolConfig,
    totalCurrency,
    setTotalCurrency,
    tradesCurrency,
    setTradesCurrency,
    thresholdCurrency,
    setThresholdCurrency,
    validateForm,
    edited,
    errors,
    touched,
  } = useTaskModal({
    pair: dexPair as IAddPairArgs & IDexPair,
    direction,
    onClose,
    task,
    mode,
  });

  const memPoolDisabled = useMemo(() => memPoolConfig === 'Do not use', [memPoolConfig]);
  const isTaskRunning = useMemo(
    () => task?.status === 'current' || task?.status === 'past',
    [task],
  );

  const convertedAmount = useConvertedCurrencies({
    amount: values.totalTokenAmount,
    selectedCurrency: totalCurrency,
  });
  const convertedMinAmount = useConvertedCurrencies({
    amount: values.minAmount,
    selectedCurrency: tradesCurrency,
  });
  const convertedMaxAmount = useConvertedCurrencies({
    amount: values.maxAmount,
    selectedCurrency: tradesCurrency,
  });
  const convertedThresholdMin = useConvertedCurrencies({
    amount: values.priceThresholdMin,
    selectedCurrency: thresholdCurrency,
    currencies: [dexPair.token_quote.symbol, dexPair.token_fee.symbol, 'USD'],
  });
  const convertedThresholdMax = useConvertedCurrencies({
    amount: values.priceThresholdMax,
    selectedCurrency: thresholdCurrency,
    currencies: [dexPair.token_quote.symbol, dexPair.token_fee.symbol, 'USD'],
  });
  const feeCostsUiString = useFeeCostsUiString({
    totalTokenAmount: values.totalTokenAmount,
    minAmount: values.minAmount,
    maxAmount: values.maxAmount,
  });

  const handleChangeMempoolConfig = useCallback(
    (mpConfig: MempoolConfigMap) => {
      setMemPoolConfig(mpConfig);
      setTimeout(() => {
        validateForm();
      }, 0);
    },
    [setMemPoolConfig, validateForm],
  );

  useEffect(() => {
    if (task) {
      const mempoolConfigValue = revertedMempoolConfigMap[task.task.options.use_mempool];
      const directionValue = capitalize(task.task.options.direction) as Direction;

      setMemPoolConfig(mempoolConfigValue);
      setDirection(directionValue);
    }
  }, [task, setMemPoolConfig]);

  const { finishTimeUiString } = useTaskTime({
    minAmount: values.minAmount,
    maxAmount: values.maxAmount,
    minDelay: values.minDelay ?? undefined,
    maxDelay: values.maxDelay ?? undefined,
    totalTokenAmount: values.totalTokenAmount,
  });

  const mempoolInputTitleClassName = useMemo(() => {
    if (!memPoolDisabled) {
      return 'text-primary font-bold mb-1.5 text-sm';
    } else {
      return 'text-gray-2 font-bold mb-1.5 text-sm';
    }
  }, [memPoolDisabled]);

  return (
    <Modal
      title={`${capitalize(mode)} task`}
      edited={edited}
      className="mm-dex-buy-sell-bot-task-modal"
      onClose={onClose}
    >
      <CreateTaskForm>
        <div className="form-inner scrollable">
          <div className="mm-add-pair-modal">
            <ToggleRadio
              disabled={isTaskRunning}
              label="Direction"
              titles={['Sell', 'Buy']}
              name="new-task-direction"
              value={direction}
              onChange={e => setDirection(e.target.value as Direction)}
            />
            <DateField
              date={values.startDate ? dayjs(values.startDate).valueOf() : null}
              label="Start time"
              setDate={value =>
                setFieldValue(
                  'startDate',
                  !isNil(value) ? dayjs(value).second(0).millisecond(0).valueOf() : null,
                )
              }
              readonly={isTaskRunning}
              errorMessage={errors.startDate && touched.startDate ? errors.startDate : undefined}
            />
            <div>
              <div className="header-currencies">
                <span className="currency-title">Total amount in</span>
                <Currencies selected={totalCurrency} onSelect={setTotalCurrency} />
              </div>
              <InputField
                type="decimal-number"
                value={values.totalTokenAmount}
                label="Amount"
                decimals={dexPair.token_base.decimals}
                setValue={v => setFieldValue('totalTokenAmount', v ? v : '')}
                errorMessage={
                  errors.totalTokenAmount && touched.totalTokenAmount
                    ? errors.totalTokenAmount
                    : undefined
                }
                nodeRight={<span className="input-right-caption">{convertedAmount}</span>}
              />
            </div>
            <div>
              <div className="header-currencies">
                <span className="currency-title">Price threshold in</span>
                <Currencies
                  currencies={[dexPair.token_fee.symbol, dexPair.token_quote.symbol, 'USD']}
                  selected={thresholdCurrency}
                  onSelect={setThresholdCurrency}
                />
              </div>
              <InputField
                type="decimal-number"
                value={values.priceThresholdMin}
                label="Min threshold"
                setValue={v => {
                  setFieldValue('priceThresholdMin', v ? v : '');
                  setTimeout(() => {
                    setFieldValue('priceThresholdMax', v ? v : '');
                  }, 0);
                }}
                decimals={dexPair.token_quote.decimals}
                errorMessage={
                  errors.priceThresholdMin && touched.priceThresholdMin
                    ? errors.priceThresholdMin
                    : undefined
                }
                nodeRight={<span className="input-right-caption">{convertedThresholdMin}</span>}
              />
              <InputField
                type="decimal-number"
                value={values.priceThresholdMax}
                label="Max threshold"
                setValue={v => setFieldValue('priceThresholdMax', v ? v : '')}
                decimals={dexPair.token_quote.decimals}
                errorMessage={
                  errors.priceThresholdMax && touched.priceThresholdMax
                    ? errors.priceThresholdMax
                    : undefined
                }
                nodeRight={<span className="input-right-caption">{convertedThresholdMax}</span>}
              />
            </div>
            <div>
              <div className="header-currencies">
                <span className="currency-title"> One trade amount in</span>
                <Currencies selected={tradesCurrency} onSelect={setTradesCurrency} />
              </div>
              <InputField
                type="decimal-number"
                value={values.minAmount}
                decimals={dexPair.token_base.decimals}
                label="Minimum"
                setValue={v => setFieldValue('minAmount', v ? v : '')}
                errorMessage={errors.minAmount && touched.minAmount ? errors.minAmount : undefined}
                nodeRight={<span className="input-right-caption">{convertedMinAmount}</span>}
              />
              <InputField
                type="decimal-number"
                value={values.maxAmount}
                decimals={dexPair.token_base.decimals}
                label="Maximum"
                setValue={v => setFieldValue('maxAmount', v ? v : '')}
                errorMessage={errors.maxAmount && touched.maxAmount ? errors.maxAmount : undefined}
                nodeRight={<span className="input-right-caption">{convertedMaxAmount}</span>}
              />
            </div>
            <AnimatePresence>
              {memPoolConfig !== 'Only' && (
                <motion.div
                  initial={rendersCount === 1 ? false : { height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.6, ease: 'easeInOut' }}
                  key="trades-pause-params"
                  className="trades-pause-container"
                >
                  <div className="text-primary font-bold mb-1.5 text-sm">Pause between trades</div>
                  <div className="inputs-row">
                    <DurationField
                      value={values.minDelay}
                      setValue={v => {
                        const newValue = isNil(v) ? null : v;

                        setFieldValue('minDelay', newValue);
                      }}
                      label="Min delay"
                      id="min-delay"
                      zeroable
                      errorMessage={
                        errors.minDelay && touched.minDelay ? errors.minDelay : undefined
                      }
                    />
                    <DurationField
                      value={values.maxDelay}
                      setValue={v => {
                        const newValue = isNil(v) ? null : v;

                        setFieldValue('maxDelay', newValue);
                      }}
                      label="Max delay"
                      id="max-delay"
                      zeroable
                      errorMessage={
                        errors.maxDelay && touched.maxDelay ? errors.maxDelay : undefined
                      }
                    />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            {dexPair.network !== ENetwork.arbitrum && (
              <>
                <ToggleRadio
                  label="Mempool"
                  titles={Object.keys(mempoolConfigMap)}
                  name="new-task-mempool"
                  value={memPoolConfig}
                  onChange={e => handleChangeMempoolConfig(e.target.value as MempoolConfigMap)}
                />
                <AnimatePresence>
                  {!memPoolDisabled && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.6, easings: '' }}
                      key="mempool-params"
                      className="mempool-agruments"
                    >
                      <div className="mempool-agruments-area">
                        <div className={mempoolInputTitleClassName}>Contr trade %</div>
                        <div className="inputs-row">
                          <InputField
                            type="decimal-number"
                            value={values.amountDisperseCoeffMin}
                            placeholder="30"
                            label="Minimum"
                            setValue={v => setFieldValue('amountDisperseCoeffMin', v ? v : '')}
                            disabled={memPoolDisabled}
                            errorMessage={
                              errors.amountDisperseCoeffMin && touched.amountDisperseCoeffMin
                                ? errors.amountDisperseCoeffMin
                                : undefined
                            }
                          />
                          <InputField
                            type="decimal-number"
                            value={values.amountDisperseCoeffMax}
                            placeholder="30"
                            label="Maximum"
                            setValue={v => setFieldValue('amountDisperseCoeffMax', v ? v : '')}
                            disabled={memPoolDisabled}
                            errorMessage={
                              errors.amountDisperseCoeffMax && touched.amountDisperseCoeffMax
                                ? errors.amountDisperseCoeffMax
                                : undefined
                            }
                          />
                        </div>
                      </div>
                      <div className="mempool-agruments-area">
                        <div className={mempoolInputTitleClassName}>Skip transactions</div>
                        <div className="inputs-row">
                          <InputField
                            type="natural-number"
                            value={values.skipTransactionsMin}
                            placeholder="30"
                            label="Minimum"
                            setValue={v => setFieldValue('skipTransactionsMin', v ? v : '')}
                            disabled={memPoolDisabled}
                            errorMessage={
                              errors.skipTransactionsMin && touched.skipTransactionsMin
                                ? errors.skipTransactionsMin
                                : undefined
                            }
                          />
                          <InputField
                            type="natural-number"
                            value={values.skipTransactionsMax}
                            placeholder="30"
                            label="Maximum"
                            setValue={v => setFieldValue('skipTransactionsMax', v ? v : '')}
                            disabled={memPoolDisabled}
                            errorMessage={
                              errors.skipTransactionsMax && touched.skipTransactionsMax
                                ? errors.skipTransactionsMax
                                : undefined
                            }
                          />
                        </div>
                      </div>
                      {memPoolConfig === 'Use additionaly' && (
                        <InputField
                          type="decimal-number"
                          value={values.advanceCoefficient}
                          placeholder="10"
                          label="Advanced mempool progress %"
                          setValue={v => setFieldValue('advanceCoefficient', v ? v : '')}
                          disabled={memPoolDisabled}
                          errorMessage={
                            errors.advanceCoefficient && touched.advanceCoefficient
                              ? errors.advanceCoefficient
                              : undefined
                          }
                        />
                      )}
                    </motion.div>
                  )}
                </AnimatePresence>
              </>
            )}
            <div className="grid grid-flow-row gap-2.5 text-primary font-normal">
              <div className="grid grid-flow-col">
                <span className="text-sm">Finish time:</span>
                <span className="text-right text-sm">
                  {finishTimeUiString ? finishTimeUiString : 'Not defined'}
                </span>
              </div>
              <div className="grid grid-flow-col">
                <span className="text-sm">Fee costs:</span>
                <span className="text-right text-sm">
                  {feeCostsUiString ? feeCostsUiString : 'Not defined'}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="mm-new-buy-sell-bot-task-actions">
          {formError && <ErrorText>{formError}</ErrorText>}
          {loading ? (
            <ButtonLoading />
          ) : (
            <Button onClick={handleSubmit}>{mode === 'create' ? 'Create task' : 'Save'}</Button>
          )}
        </div>
      </CreateTaskForm>
    </Modal>
  );
});
