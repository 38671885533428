import React, { useCallback, useMemo, useRef, memo } from 'react';
import { useTypedSelector } from 'store';
import { ColumnDef, getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { v4 as uuid } from 'uuid';

import { TableWrapper, TableCheckbox } from 'ui';
import { RowComponent, StaticTable, TableHeaders, TBody } from 'tables/common';
import { useArbitrageCexAccountsRecords } from 'hooks/tables';
import { IAccountBalances, ICexAccount } from 'types/accounts';
import { ICexPair } from 'types/pairs';
import { ICexAccountTableRow } from 'types/tables';

import './style.scss';

interface IAccountWithBalance extends ICexAccount, Partial<IAccountBalances> {}

interface Props {
  pair: ICexPair;
  pairAccounts: IAccountWithBalance[];
  selectedAccountId?: number;
  onSelectAccount: (accountId?: number) => void;
}

const ArbitrageCexAccountsTable: React.FC<Props> = ({
  pair,
  pairAccounts,
  selectedAccountId,
  onSelectAccount,
}) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const isAdmin = useTypedSelector(store => store.auth.isAdmin);

  const { tableData } = useArbitrageCexAccountsRecords(pairAccounts);

  const columns = useMemo(
    () =>
      (
        [
          {
            id: 'select',
            accessorFn: (row: Row<ICexAccountTableRow>) => row,
            header: () => <div style={{ textAlign: 'left' }}>Notes</div>,
            cell: ({
              row,
              table: {
                options: { meta },
              },
            }) => {
              const _meta = meta as any;

              return (
                <div className="checkbox-area">
                  {isAdmin && (
                    <TableCheckbox
                      id={uuid()}
                      checked={_meta.selectedAccountId === row.original.id}
                      indeterminate={row.getIsSomeSelected()}
                      onChange={() =>
                        onSelectAccount(
                          _meta.selectedAccountId !== row.original.id ? row.original.id : undefined,
                        )
                      }
                    />
                  )}
                  <span>{row.original.notesCol.value}</span>
                </div>
              );
            },
            size: 250,
          },
          {
            id: 'token_base',
            header: () => <div style={{ textAlign: 'left' }}>{pair.token_base.symbol}</div>,
            accessorFn: (row: ICexAccountTableRow) => row.tokenBaseCol,
            cell: memo(({ getValue }) => {
              const data = getValue() as string;

              return data;
            }),
            size: 100,
          },
          {
            id: 'token_quote',
            accessorFn: (row: ICexAccountTableRow) => row.tokenQuoteCol,
            header: () => <div style={{ textAlign: 'left' }}>{pair.token_quote.symbol}</div>,
            cell: memo(({ getValue }) => {
              const data = getValue() as string;

              return data;
            }),
            size: 100,
          },
        ] as ColumnDef<ICexAccountTableRow>[]
      ).filter(el => el !== null),
    [pair, isAdmin, onSelectAccount],
  );

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: useMemo(
      () => ({
        selectedAccountId,
      }),
      [selectedAccountId],
    ),
  });

  const { rows } = table.getRowModel();
  const virtualizer = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    count: useMemo(() => rows.length, [rows]),
    overscan: 5,
    estimateSize: useCallback(() => 44, []),
  });

  return (
    <>
      <div className="arbitrage-cex-accounts-table-title">Accounts</div>
      <TableWrapper
        loading={false}
        records={pairAccounts}
        hasMore={false}
        heightLimit={400}
        cRef={tableContainerRef}
        className="arbitrage-cex-accounts-table-container"
      >
        <StaticTable className="mm-common-table">
          <TableHeaders isAllChecked={table.getIsAllRowsSelected()} table={table} />
          <TBody
            paddingTop={virtualizer.getVirtualItems()?.[0]?.start || 0}
            paddingBottom={
              virtualizer.getTotalSize() -
              (virtualizer.getVirtualItems()?.[virtualizer.getVirtualItems().length - 1]?.end || 0)
            }
          >
            {virtualizer.getVirtualItems().map(virtualRow => {
              const row = rows[virtualRow.index];

              return (
                <RowComponent
                  isChecked={selectedAccountId === row.original.id}
                  row={row}
                  virtualRow={virtualRow}
                  key={virtualRow.index}
                />
              );
            })}
          </TBody>
        </StaticTable>
      </TableWrapper>
    </>
  );
};

export { ArbitrageCexAccountsTable };
