import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { isNil } from 'lodash';
import { useTypedSelector } from 'store';
import { UTCTimestamp } from 'lightweight-charts';

import { chartFormatter } from 'utils/charts';
import { formatFiat } from 'utils/formats';
import { EExchange } from 'web3';
import { bn } from 'tools/math';
import { ICursorPosistion, ILogicalRange } from 'types/charts';
import { useCursorPosition, useVisibleLogicalRange } from 'hooks/charts';

import './style.scss';

interface IPairTooltipNewProps {
  isConverted?: boolean;
  exchange: EExchange;
  candles: {
    time: UTCTimestamp;
    close: number;
    high: number;
    low: number;
    open: number;
  }[];
  logicalEvent: string;
  cursorEvent: string;
  volumes: {
    time: UTCTimestamp;
    value: number;
  }[];
  internal_volumes?: {
    time: UTCTimestamp;
    value: number;
  }[];
}

const PairTooltipNew: React.FC<IPairTooltipNewProps> = ({
  isConverted = false,
  exchange,
  candles,
  volumes,
  internal_volumes,
  logicalEvent,
  cursorEvent,
}) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const isAdmin = useTypedSelector(store => store.auth.isAdmin);

  const [visibleLogicalRange, _setVisibleLogicalRange] = useState<ILogicalRange>(undefined);
  const onLogicalRangeChanged = useCallback((p: any) => {
    _setVisibleLogicalRange(p);
  }, []);
  useVisibleLogicalRange({
    event: 'change-visible-local-range',
    setter: onLogicalRangeChanged,
  });

  const [cursorPosition, _setCursorPosition] = useState<ICursorPosistion>(undefined);
  useCursorPosition({
    event: cursorEvent,
    setter: (p: ICursorPosistion) => {
      _setCursorPosition(p);
    },
  });

  const firstVisibleIndex = useMemo(
    () => (visibleLogicalRange ? Math.ceil(visibleLogicalRange.from) : undefined),
    [visibleLogicalRange],
  );
  const lastVisibleIndex = useMemo(
    () => (visibleLogicalRange ? Math.floor(visibleLogicalRange.to) : undefined),
    [visibleLogicalRange],
  );

  const hoveredIndex = useMemo(() => {
    const result = cursorPosition
      ? cursorPosition === undefined
        ? lastVisibleIndex
        : cursorPosition.logical
      : lastVisibleIndex;

    const recordsLength = candles.length;

    if (!result) return recordsLength - 1;

    return result > recordsLength - 1 ? recordsLength - 1 : result;
  }, [lastVisibleIndex, cursorPosition, candles]);

  const high = useMemo(() => candles[hoveredIndex]?.high ?? 0, [candles, hoveredIndex]);

  const open = useMemo(() => candles[hoveredIndex]?.open ?? 0, [candles, hoveredIndex]);

  const low = useMemo(() => candles[hoveredIndex]?.low ?? 0, [candles, hoveredIndex]);

  const close = useMemo(() => candles[hoveredIndex]?.close ?? 0, [candles, hoveredIndex]);

  const volumeTotal = useMemo(() => volumes[hoveredIndex]?.value ?? 0, [volumes, hoveredIndex]);

  const volumeInternal = useMemo(
    () => (internal_volumes ? internal_volumes[hoveredIndex]?.value ?? 0 : undefined),
    [internal_volumes, hoveredIndex],
  );

  const startPrice = useMemo(
    () => (firstVisibleIndex !== undefined ? candles[firstVisibleIndex]?.close ?? 0 : undefined),
    [candles, firstVisibleIndex],
  );

  const pair = useMemo(
    () => (exchange === EExchange.cex ? cexPair : dexPair),
    [cexPair, dexPair, exchange],
  );

  const baseSymbol = useMemo(() => pair.token_base.symbol, [pair]);
  const pairSymbol = useMemo(() => pair.symbol, [pair]);

  const pairChartTitle = useMemo(
    () =>
      isConverted && exchange === EExchange.dex ? `${baseSymbol}/USD (converted)` : pairSymbol,
    [baseSymbol, isConverted, pairSymbol, exchange],
  );

  const isGreen = useMemo(() => close >= open, [close, open]);

  const amountCN = useMemo(() => cn('amount', { green: isGreen, red: !isGreen }), [isGreen]);

  const diff = useMemo(
    () => (startPrice !== undefined ? close - startPrice : undefined),
    [close, startPrice],
  );

  const diffPercentage = useMemo(
    () =>
      startPrice !== undefined && startPrice !== 0 && diff !== undefined
        ? Number(Math.abs((diff / startPrice) * 100).toFixed(2))
        : undefined,
    [startPrice, diff],
  );

  const totalVolumeString = useMemo(() => {
    const number = chartFormatter(volumeTotal);

    const bignumber = bn(number);

    return formatFiat(bignumber, 18, exchange === EExchange.dex ? (isConverted ? '$' : '') : '');
  }, [volumeTotal, isConverted, exchange]);

  const internalVolumeString = useMemo(() => {
    if (!volumeInternal) return '0';

    const number = chartFormatter(volumeInternal);

    const bignumber = bn(number);

    return formatFiat(bignumber, 18, exchange === EExchange.dex ? (isConverted ? '$' : '') : '');
  }, [volumeInternal, isConverted, exchange]);

  return (
    <div className="mm-pair-pair-chart-tooltip-new">
      <div className="pair-values">
        <div className="value">
          <span className="caption">O</span>
          <span className={amountCN}>{chartFormatter(open)}</span>
        </div>
        <div className="value">
          <span className="caption">H</span>
          <span className={amountCN}>{chartFormatter(high)}</span>
        </div>
        <div className="value">
          <span className="caption">L</span>
          <span className={amountCN}>{chartFormatter(low)}</span>
        </div>
        <div className="value">
          <span className="caption">C</span>
          <span className={amountCN}>{chartFormatter(close)}</span>
        </div>
        {diff !== undefined && diffPercentage !== undefined && (
          <div className="value">
            <span className={cn('amount', { green: diff >= 0, red: diff < 0 })}>
              {diff > 0 ? '+' : ''}
              {chartFormatter(diff)}
            </span>
            <span className={cn('amount', { green: diff >= 0, red: diff < 0 })}>
              ({diff >= 0 ? '+' : '-'}
              {diffPercentage}%)
            </span>
          </div>
        )}
      </div>
      {!isNil(volumeInternal) && isAdmin && (
        <div className="pair-values">
          <div className="volume-value">
            <span className="caption">Internal v:</span>
            <span className="amount">{internalVolumeString}</span>
          </div>
        </div>
      )}
      <div className="pair-values">
        <div className="volume-value">
          <span className="caption">Total v:</span>
          <span className="amount">{totalVolumeString}</span>
        </div>
      </div>
    </div>
  );
};

export { PairTooltipNew };
