import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { throttle } from 'lodash';

import { Modal } from 'ui';
import {
  useAddArbitrageModal,
  EStep,
  IArbitrageForm,
} from './useAddArbitrageModal/useAddArbitrageModal';
import { PairsList } from './components';

import { PairSettingsForm, GeneralSettingsForm } from 'common/arbitrage/forms';
import PlatformIcon from 'common/project/ProjectListWithPairs/PlatformIcon';
import { ICexPair } from 'types/pairs';

import './style.scss';

interface IAddPairModalProps {
  onClose: () => void;
  onSuccess?: (form: IArbitrageForm) => void;
  projectId: number;
  mode?: 'add' | 'create';
  pairsToAdd?: ICexPair[];
}

const variants = {
  hidden: (isForwardDirection: boolean) => ({
    opacity: 0,
    x: isForwardDirection ? 200 : -200,
  }),
  visible: {
    opacity: 1,
    x: 0,
  },
  exit: (isForwardDirection: boolean) => ({
    opacity: 0,
    x: isForwardDirection ? -200 : 200,
  }),
};

const AddArbitrageModal: React.FC<IAddPairModalProps> = ({
  onClose,
  onSuccess,
  projectId,
  mode = 'create',
  pairsToAdd,
}) => {
  const {
    project,
    currentStepIdx,
    steps,
    cexPairs,
    currentPair,
    selectedCexPairs,
    currentStepControls,
    setupSteps,
    setSelectedCexPairs,
    toggleAllPairs,
    generalSettings,
    pairSettings,
  } = useAddArbitrageModal({ onClose, onSuccess, projectId, mode, pairsToAdd });

  const [isForwardDirection, setIsForwardDirection] = useState(true);

  const handlePairSelect = useCallback(
    (pair: ICexPair) => {
      setSelectedCexPairs(prevPairs => {
        const isSelected = prevPairs.some(selected => selected.id === pair.id);
        if (isSelected) {
          return prevPairs.filter(selected => selected.id !== pair.id);
        } else {
          return [...prevPairs, pair];
        }
      });
    },
    [setSelectedCexPairs],
  );

  const handleForwardClick = useCallback(() => {
    if (currentStepControls.forwardControl.disabled) {
      return;
    }

    setIsForwardDirection(true);

    setTimeout(() => {
      currentStepControls.forwardControl?.onForwardClick?.();
    }, 50);
  }, [currentStepControls]);

  const handleBackwardClick = useCallback(() => {
    setIsForwardDirection(false);

    setTimeout(() => {
      currentStepControls.backwardControl?.onBackwardClick?.();
    }, 50);
  }, [currentStepControls]);

  useEffect(() => {
    setupSteps(selectedCexPairs.length);
  }, [selectedCexPairs.length, setupSteps]);

  const step = steps[currentStepIdx];

  if (!project || !step) return null;

  return (
    <Modal
      customHeader={
        <div className="mm-add-arbitrage-modal__header">
          {step.name === EStep.pairs ? (
            mode === 'create' ? (
              'New arbitrage'
            ) : (
              'Add pairs to arbitrage'
            )
          ) : step.name === EStep.general ? (
            <span>General settings</span>
          ) : (
            currentPair && (
              <>
                <PlatformIcon
                  platform={currentPair.cex}
                  isIcon
                  className="mm-sidebar__projects__project__trading-pair-icon"
                />
                {currentPair.symbol}
              </>
            )
          )}
        </div>
      }
      onClose={onClose}
      customButtons={
        <div className="step-controls-container">
          <div className={'control _backward'}>
            <div className={cn('text-button')} onClick={throttle(handleBackwardClick, 500)}>
              {currentStepControls.backwardControl?.title}
            </div>
          </div>
          <div className="control _forward">
            <div
              className={cn('text-button', {
                _disabled: currentStepControls.forwardControl.disabled,
              })}
              onClick={handleForwardClick}
            >
              {currentStepControls.forwardControl.title}
            </div>
          </div>
        </div>
      }
      className="mm-add-arbitrage-modal-wrapper"
    >
      <div className="mm-add-arbitrage-modal">
        <div className="stepper-form-content scrollable">
          <AnimatePresence custom={isForwardDirection} mode="wait">
            <motion.div
              key={currentStepIdx}
              custom={isForwardDirection}
              variants={variants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ duration: 0.2 }}
              className="form-step"
            >
              <div className="mm-add-arbitrage-modal__content">
                {step.name === EStep.pairs && (
                  <PairsList
                    cexPairs={cexPairs}
                    toggleAllPairs={toggleAllPairs}
                    selectedCexPairs={selectedCexPairs}
                    handlePairSelect={handlePairSelect}
                  />
                )}
                {step.name === EStep.pair && currentPair && (
                  <PairSettingsForm
                    key={currentPair.id}
                    cexPair={currentPair}
                    errors={pairSettings.errors}
                    setFieldTouched={pairSettings.setFieldTouched}
                    setFieldValue={pairSettings.setFieldValue}
                    touched={pairSettings.touched}
                    values={pairSettings.values}
                  />
                )}
                {step.name === EStep.general && (
                  <GeneralSettingsForm
                    values={generalSettings.values}
                    setFieldValue={generalSettings.setFieldValue}
                    errors={generalSettings.errors}
                    touched={generalSettings.touched}
                    setFieldTouched={generalSettings.setFieldTouched}
                  />
                )}
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </Modal>
  );
};

export { AddArbitrageModal };
