import { IDexBuySellBotTask, IDexBuySellBotTaskItem } from 'types/bots';

import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';
import { AApiBase, IBaseResponse } from '../apiBase';

interface IBuySellSTasksResponse {
  tasks: IDexBuySellBotTaskItem[] | undefined | null;
}

class ApiDexBuySellBot extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiDexBuySellBot | undefined;
  private static instance = (() => {
    if (ApiDexBuySellBot.__instance === undefined) {
      ApiDexBuySellBot.__instance = new ApiDexBuySellBot();
    }
    return ApiDexBuySellBot.__instance;
  })();

  public static getBuySellTasks = async ({ pairId }: { pairId: number }) => {
    try {
      const response = await ApiDexBuySellBot.instance.get<IBaseResponse<IBuySellSTasksResponse>>(
        `/buysellbot-tasks?pair_id=${pairId}`,
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<IBuySellSTasksResponse>({ error });
    }
  };

  public static getBuySellTask = async ({ taskId }: { taskId: number }) => {
    try {
      const response = await ApiDexBuySellBot.instance.get<
        IBaseResponse<IDexBuySellBotTaskItem | undefined>
      >(`/buysellbot-tasks/${taskId}`);

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<IDexBuySellBotTaskItem | undefined>({ error });
    }
  };

  public static createBuySellTask = async ({
    pairId,
    options,
  }: {
    pairId: number;
    options: IDexBuySellBotTask;
  }) => {
    try {
      const response = await ApiDexBuySellBot.instance.post<IBaseResponse<{ id: number }>>(
        '/buysellbot-tasks',
        { pair_id: pairId, options },
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<{ id: number }>({ error });
    }
  };

  public static updateBuySellTask = async ({
    taskId,
    options,
  }: {
    taskId: number;
    options: IDexBuySellBotTask;
  }) => {
    try {
      const response = await ApiDexBuySellBot.instance.patch<IBaseResponse<any>>(
        `/buysellbot-tasks/${taskId}`,
        { options },
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };

  public static deleteBuySellTask = async ({ taskId }: { taskId: number }) => {
    try {
      const response = await ApiDexBuySellBot.instance.delete<IBaseResponse<any>>(
        `/buysellbot-tasks/${taskId}`,
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };
}

export { ApiDexBuySellBot };
