import React, { useRef, useEffect, memo } from 'react';
import cn from 'classnames';
import * as echarts from 'echarts';
import { Time } from 'lightweight-charts';
import dayjs from 'dayjs';

import './barBrushDiagram.scss';

interface DiagramSide {
  color: string;
  label: string;
  points: { time: Time; value: number }[];
}

type BarBrushDiagramProps = {
  positiveSide: DiagramSide;
  negativeSide: DiagramSide;
  unit?: string;
  convertValue?: (v: number) => string;
  className?: string;
};

const BarBrushDiagram: React.FC<BarBrushDiagramProps> = ({
  positiveSide,
  negativeSide,
  unit,
  convertValue,
  className,
  ...rest
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) return;
    const barChart = echarts.init(chartRef.current);

    const positiveData = positiveSide.points.map(point => point.value);
    const negativeData = negativeSide.points.map(point => point.value);
    const timeData = positiveSide.points.map(point =>
      dayjs(Number(point.time) * 1000).format('L LT'),
    );

    const minBars = 40;

    const calculateVisibleData = (start: number, end: number) => {
      const visibleStartIndex = Math.floor((start / 100) * positiveSide.points.length);
      const visibleEndIndex = Math.ceil((end / 100) * positiveSide.points.length);

      const visiblePositiveData = positiveSide.points
        .slice(visibleStartIndex, visibleEndIndex)
        .map(point => point.value);

      const visibleNegativeData = negativeSide.points
        .slice(visibleStartIndex, visibleEndIndex)
        .map(point => point.value);

      return { visiblePositiveData, visibleNegativeData };
    };

    const calculateYAxis = (visiblePositiveData: number[], visibleNegativeData: number[]) => {
      const maxAbsValue = Math.max(
        ...visiblePositiveData.map(Math.abs),
        ...visibleNegativeData.map(Math.abs),
      );
      const roundedMaxValue = Math.ceil(maxAbsValue / 10) * 10;
      const stepSize = roundedMaxValue / 3;
      return { min: -roundedMaxValue, max: roundedMaxValue, interval: stepSize };
    };

    const updateBarWidth = (start: number, end: number): number => {
      const visibleDataCount = Math.ceil(((end - start) / 100) * positiveSide.points.length);
      return Math.max(
        4,
        Math.min(20, chartRef.current?.clientWidth / visibleDataCount / 1.5 || 10),
      );
    };

    const enforceMinBars = (start: number, end: number): { start: number; end: number } => {
      const totalBars = positiveSide.points.length;
      const visibleBars = Math.ceil(((end - start) / 100) * totalBars);

      if (visibleBars < minBars) {
        const extraBars = minBars - visibleBars;
        const additionalPercent = (extraBars / totalBars) * 100;
        const newStart = Math.max(0, start - additionalPercent / 2);
        const newEnd = Math.min(100, end + additionalPercent / 2);
        return { start: newStart, end: newEnd };
      }

      return { start, end };
    };

    const { visiblePositiveData, visibleNegativeData } = calculateVisibleData(0, 100);
    const { min, max, interval } = calculateYAxis(visiblePositiveData, visibleNegativeData);

    const option: echarts.EChartsOption = {
      grid: {
        top: 30,
        bottom: 5,
        left: 0,
        right: 5,
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: timeData,
        axisLine: { show: false },
        splitLine: { show: false },
        axisTick: { show: false },
        axisLabel: { show: false },
      },
      yAxis: [
        {
          type: 'value',
          position: 'right',
          min,
          max,
          interval,
          axisLabel: {
            formatter: value => `${Math.round(Number(value) / 10) * 10}`,
            color: '#71717A',
            fontSize: 9,
            fontWeight: 400,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#27272A',
              width: 1,
              type: 'solid',
            },
          },
        },
      ],
      tooltip: {
        trigger: 'item',
        formatter: params => {
          const index = params.dataIndex;
          const time = timeData[index];
          const valueType = params.seriesName;

          const rawValue =
            positiveSide.points[index]?.value || negativeSide.points[index]?.value || params.value;

          const value = convertValue ? convertValue(rawValue) : rawValue;

          return `${valueType}: ${value} ${unit || ''}<br/>Time: ${time}`;
        },
      },
      series: [
        {
          name: negativeSide.label,
          type: 'bar',
          stack: 'total',
          data: negativeData,
          itemStyle: {
            color: negativeSide.color,
            borderRadius: 4,
          },
          barWidth: updateBarWidth(0, 100),
          barCategoryGap: '8px',
          emphasis: {
            focus: 'self',
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.3)',
            },
          },
        },
        {
          name: positiveSide.label,
          type: 'bar',
          stack: 'total',
          data: positiveData,
          itemStyle: {
            color: positiveSide.color,
            borderRadius: 4,
          },
          barWidth: updateBarWidth(0, 100),
          barCategoryGap: '8px',
          emphasis: {
            focus: 'self',
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.3)',
            },
          },
        },
      ],
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
        },
      ],
    };

    barChart.setOption(option);

    const handleZoom = (params: any) => {
      const { start: rawStart, end: rawEnd } = params.batch[0];
      const { start, end } = enforceMinBars(rawStart, rawEnd);

      const { visiblePositiveData, visibleNegativeData } = calculateVisibleData(start, end);
      const { min, max, interval } = calculateYAxis(visiblePositiveData, visibleNegativeData);
      const newBarWidth = updateBarWidth(start, end);

      barChart.dispatchAction({ type: 'downplay' });

      barChart.setOption({
        yAxis: [
          {
            min,
            max,
            interval,
          },
        ],
        series: [{ barWidth: newBarWidth }, { barWidth: newBarWidth }],
      });

      barChart.dispatchAction({
        type: 'dataZoom',
        start,
        end,
      });
    };

    barChart.on('dataZoom', handleZoom);

    return () => {
      barChart.off('dataZoom', handleZoom);
      barChart.dispose();
    };
  }, [positiveSide, negativeSide, unit, convertValue]);

  return (
    <div className="mm-bar-brush-diagram-container">
      <div className="bar-brush-legend">
        <div className="legend-item">
          <div className="round" style={{ backgroundColor: positiveSide.color }} />
          <span>{positiveSide.label}</span>
        </div>
        <div className="legend-item">
          <div className="round" style={{ backgroundColor: negativeSide.color }} />
          <span>{negativeSide.label}</span>
        </div>
      </div>
      <div
        ref={chartRef}
        className={cn({ [className ?? '']: !!className }, 'mm-bar-brush-diagram')}
        {...rest}
      />
    </div>
  );
};

const memoBarBrushDiagram = memo(BarBrushDiagram);

export { memoBarBrushDiagram as BarBrushDiagram };
