import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink, generatePath } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useTypedDispatch, useTypedSelector } from 'store';
import cn from 'classnames';

import { ApiCexPairs, ApiProjects } from 'api';
import { IProject } from 'types/project';
import { UDPairModal } from 'modals';
import { useTheme } from 'hooks/utility';
import { EExchange, cexConfigs } from 'web3';
import { ROUTE_PATHS } from 'constant/routes';
import {
  setSelectedCexPair,
  setSelectedDexPair,
  setPairError,
  setPairLoading,
  updateCexPairPrice,
} from 'store/slices/pairs';
import { getPairLinkedBots } from 'utils/getPairLinkedBots';

import ProjectListIconPairs from '../../project/ProjectListWithPairs/PlatformIcon';

import { CogIcon } from 'assets/icons';

interface ICexPairHeaderProps {
  pairId: number;
  projectId: number;
}

const CexPairHeader: React.FC<ICexPairHeaderProps> = ({ pairId, projectId }) => {
  const dispatch = useTypedDispatch();
  const { pathname } = useLocation();
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair);
  const projects = useTypedSelector(store => store.projects.projects);
  const isAdmin = useTypedSelector(store => store.auth.isAdmin);
  const [project, setProject] = useState<IProject | null>(null);
  const [updateModalOpened, setUpdateModalOpened] = useState<boolean>(false);
  const { isDark } = useTheme();

  const onOpenUpdateModalOpened = useCallback(() => {
    setUpdateModalOpened(true);
  }, []);

  const onCloseUpdateModalOpened = useCallback(() => {
    setUpdateModalOpened(false);
  }, []);

  useEffect(() => {
    const setupSelectProjectAndPair = async () => {
      if (projects) {
        try {
          dispatch(setSelectedCexPair(null));
          dispatch(setSelectedDexPair(null));
          dispatch(setPairLoading(true));
          dispatch(setPairError(false));

          const [activeProject, activePair] = await Promise.all([
            ApiProjects.getProjectById(projectId),
            ApiCexPairs.getCexPairById(pairId),
          ]);

          if (activeProject.isSuccess && activePair.isSuccess) {
            setProject({
              created_at: activeProject.data.created_at,
              id: activeProject.data.id,
              name: activeProject.data.name,
              cexPairs: [],
              dexPairs: [],
              updated_at: '',
              notes: activeProject.data.notes,
            });

            if ('cex' in activePair.data) {
              dispatch(setSelectedCexPair(activePair.data));

              setTimeout(() => {
                dispatch(updateCexPairPrice({ pair: activePair.data }));
              }, 0);
            }

            await getPairLinkedBots({
              pairId: activePair.data.id,
              dispatch,
              exchange: EExchange.cex,
            });
          } else {
            dispatch(setPairError(true));
          }
        } catch (error) {
          console.log(error);
        } finally {
          dispatch(setPairLoading(false));
        }
      }
    };

    setupSelectProjectAndPair();
  }, [dispatch, pathname, projects, projectId, pairId]);

  return (
    <motion.div
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { duration: 0.4 } }}
      className="mm-header__header-pair"
    >
      {project && cexPair && (
        <>
          <div className="mm-header__header-pair__info">
            <div className="pair-name">
              <span>{cexPair.token_base.symbol}</span>
              <span>/</span>
              <span>{cexPair.token_quote.symbol}</span>
            </div>
            {isAdmin && (
              <motion.div
                whileHover={{ rotate: 180, scale: 1.3 }}
                transition={{ duration: 0.3 }}
                className="pair-settings"
                onClick={onOpenUpdateModalOpened}
              >
                <CogIcon />
              </motion.div>
            )}
            <NavLink
              to={generatePath(ROUTE_PATHS.project, { id: project.id.toString() })}
              className="project-name"
            >
              {project.name}
            </NavLink>
            <div
              className={cn('header-trading-pair-logo-header', cexPair.cex, {
                'bg-white': isDark,
                'bg-bg-primary': !isDark,
              })}
            >
              <ProjectListIconPairs platform={cexPair.cex} className="exchange-icon" />
            </div>
          </div>
          <div className="mm-header__header-pair__bottom">
            <a
              className={cn('scan-link rounded-full p-[2px]')}
              target="_blank"
              rel="noreferref noreferrer"
              href={cexConfigs[cexPair.cex].cexLink(
                cexPair.token_base.symbol,
                cexPair.token_quote.symbol,
              )}
            >
              <ProjectListIconPairs
                platform={cexPair.cex}
                className="header-trading-pair-logo-bottom"
                isIcon
              />
            </a>
            {cexPair.notes && isAdmin && <span>{cexPair.notes}</span>}
          </div>
          {updateModalOpened && (
            <UDPairModal
              exchange={EExchange.cex}
              onClose={onCloseUpdateModalOpened}
              onOpen={onOpenUpdateModalOpened}
              pairId={cexPair.id}
              projectId={project.id}
              pairSymbol={cexPair.symbol}
              initialNotes={cexPair.notes ?? ''}
            />
          )}
        </>
      )}
    </motion.div>
  );
};

export default CexPairHeader;
