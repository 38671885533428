import { Handler, EventType } from 'mitt';

type MultiHandlerMap<T> = Map<EventType, Array<Handler<T>>>;

export class EventBus {
  private emitter: {
    all: MultiHandlerMap<unknown>;
  };

  constructor() {
    this.emitter = { all: new Map<EventType, Array<Handler<unknown>>>() };
  }

  on(eventName: EventType, handlerFn: Handler<unknown>): void {
    const existingHandlers = this.emitter.all.get(eventName) || [];
    if (!existingHandlers.includes(handlerFn)) {
      this.emitter.all.set(eventName, [...existingHandlers, handlerFn]);
    }
  }

  emit(eventName: EventType, payload?: unknown): void {
    const handlers = this.emitter.all.get(eventName) || [];
    handlers.forEach(handler => handler(payload));
  }

  off(eventName: EventType, handlerFn: Handler<unknown>): void {
    const handlers = this.emitter.all.get(eventName) || [];
    const updatedHandlers = handlers.filter(handler => handler !== handlerFn);

    if (updatedHandlers.length > 0) {
      this.emitter.all.set(eventName, updatedHandlers);
    } else {
      this.emitter.all.delete(eventName);
    }
  }
}

export const Bus = new EventBus();
