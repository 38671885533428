import React, { useCallback, useRef, useEffect, memo } from 'react';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import {
  createChart,
  CrosshairMode,
  PriceScaleMode,
  ISeriesApi,
  UTCTimestamp,
  IChartApi,
  Time,
  Logical,
  MouseEventParams,
} from 'lightweight-charts';
import { useTypedDispatch, useTypedSelector } from 'store';

import { bn, Bus } from 'tools';
import { useVisibleLogicalRange, useCursorPosition, useTimescaleRange } from 'hooks/charts';
import { chartFormatter } from 'utils/charts';
import { formatFiat } from 'utils/formats';
import { CHARTS_COLORS } from 'constant/charts';
import { IPriceFormat } from 'types/charts';
import { setCopyPopupShow } from 'store/slices/ui';
import { ChartLoading, ChartNotFound } from '../controls';
import { BalanceTooltipOld } from './BalanceTooltipOld';
import { BalanceTooltipNew } from './BalanceTooltipNew';

import './index.scss';

interface IBalancesChartProps {
  loading: boolean;
  chart: string;
  relatedCharts?: string[];
  logicalEvent: string;
  cursorEvent: string;
  timescaleEvent?: string;
  chartMode: PriceScaleMode;
  priceFormat: IPriceFormat;
  balances: {
    records: {
      time: UTCTimestamp;
      value: number;
    }[];
    label: string;
  }[];
  totalBalancesUsd: {
    time: UTCTimestamp;
    value: number;
  }[];
  balancesShow: Record<string, boolean>;
  tooltipVariant?: 'new' | 'old';
}

const BalancesChart: React.FC<IBalancesChartProps> = memo(
  ({
    loading,
    chart: chartId,
    relatedCharts = [],
    logicalEvent,
    cursorEvent,
    chartMode,
    priceFormat,
    balances,
    totalBalancesUsd,
    balancesShow,
    timescaleEvent,
    tooltipVariant = 'old',
  }) => {
    const palette = useTypedSelector(store => store.ui.selectedThemeColors);
    const dispatch = useTypedDispatch();
    const chartRef = useRef<HTMLDivElement | null>(null);
    const chartAPIRef = useRef<IChartApi | undefined>(undefined);
    const firstLastIndexesRef = useRef<
      { first: number | undefined; last: number | undefined } | undefined
    >(undefined);
    const hoveredIndexRef = useRef<number | undefined>(undefined);

    const balancesSeriesRef = useRef<ISeriesApi<'Line'>[]>([]);

    const setFirstLastIndexes = useCallback((p: any) => {
      const first = p ? Math.ceil(p.from) : undefined;
      const last = p ? Math.floor(p.to) : undefined;

      firstLastIndexesRef.current = { first, last };
    }, []);
    const { setVisibleLogicalRange } = useVisibleLogicalRange({
      event: logicalEvent,
      setter: setFirstLastIndexes,
    });

    const setHoveredIndex = (p: any) => {
      hoveredIndexRef.current = p
        ? p === undefined
          ? firstLastIndexesRef.current?.first
          : p.logical
        : firstLastIndexesRef.current?.last;
    };
    const { setCursorPosition } = useCursorPosition({
      event: cursorEvent,
      setter: setHoveredIndex,
    });

    const { setTimescaleRange } = useTimescaleRange({
      event: timescaleEvent ?? 'unknownEvent',
    });

    const crosshairMoveHandler = useCallback(
      (param: MouseEventParams<Time>) => {
        if (!param.sourceEvent) return;

        if (param.time) {
          Bus.emit(`${chartId}-${cursorEvent}`, {
            time: param.time,
            logical: param.logical,
          });
          setCursorPosition({
            time: param.time,
            logical: param.logical,
          });
        } else {
          chartAPIRef.current?.clearCrosshairPosition();
          Bus.emit(`${chartId}-${cursorEvent}`, undefined);
          setCursorPosition(undefined);
        }
      },
      [chartId, cursorEvent, setCursorPosition],
    );

    const initChart = useCallback(() => {
      try {
        const chart = createChart(chartRef.current as HTMLElement, {
          autoSize: true,
          crosshair: {
            mode: CrosshairMode.Normal,
          },
          rightPriceScale: {
            scaleMargins: tooltipVariant === 'old' ? { top: 0, bottom: 0 } : { top: 0.4 },
            mode: chartMode,
            minimumWidth: 100,
            borderColor: palette.chart_border_color,
          },
          layout: {
            background: {
              color: 'transparent',
            },
          },
          grid: {
            horzLines: {
              color: palette.chart_line_color,
            },
            vertLines: {
              color: palette.chart_line_color,
            },
          },
          timeScale: {
            timeVisible: true,
            secondsVisible: false,
            borderColor: palette.chart_border_color,
            rightBarStaysOnScroll: true,
            minBarSpacing: 3,
          },
          localization: {
            priceFormatter: chartFormatter,
            percentageFormatter: (p: number) => `${p.toFixed(2)}%`,
          },
        });
        chartAPIRef.current = chart;

        chart.timeScale().subscribeVisibleLogicalRangeChange(logicalRange => {
          if (!logicalRange) return;

          Bus.emit(`${chartId}-${logicalEvent}`, {
            from: Number(logicalRange.from),
            to: Number(logicalRange.to),
          });

          setVisibleLogicalRange({
            from: Number(logicalRange.from),
            to: Number(logicalRange.to),
          });
        });

        if (timescaleEvent) {
          chart.timeScale().subscribeVisibleTimeRangeChange(timeRange => {
            if (!timeRange) return;

            Bus.emit(`${chartId}-${timescaleEvent}`, {
              from: Number(timeRange.from),
              to: Number(timeRange.to),
            });
            setTimescaleRange({ from: Number(timeRange.from), to: Number(timeRange.to) });
          });
        }

        chart.subscribeCrosshairMove(crosshairMoveHandler);

        // -----
      } catch (error) {
        console.log('initing balance chart error: ', error);
      }
    }, [
      chartId,
      logicalEvent,
      chartMode,
      timescaleEvent,
      crosshairMoveHandler,
      setVisibleLogicalRange,
      setTimescaleRange,
      palette,
      tooltipVariant,
    ]);

    const timescaleSetter = useCallback((v: unknown) => {
      const _v = v as unknown as { from: Time; to: Time };

      if (chartAPIRef && chartAPIRef.current) {
        chartAPIRef.current.timeScale().setVisibleRange(_v);
      }
    }, []);

    useEffect(() => {
      for (const relatedChart of relatedCharts) {
        Bus.on(`${relatedChart}-${timescaleEvent}`, timescaleSetter);
      }

      return () => {
        for (const relatedChart of relatedCharts) {
          Bus.off(`${relatedChart}-${timescaleEvent}`, timescaleSetter);
        }
      };
    }, [relatedCharts, timescaleEvent, timescaleSetter]);

    useEffect(() => {
      const setter = (v: unknown) => {
        const _v = v as unknown as { from: number; to: number };

        if (chartAPIRef && chartAPIRef.current) {
          chartAPIRef.current.timeScale().setVisibleLogicalRange(_v);
        }
      };

      for (const relatedChart of relatedCharts) {
        Bus.on(`${relatedChart}-${logicalEvent}`, setter);
      }

      return () => {
        for (const relatedChart of relatedCharts) {
          Bus.off(`${relatedChart}-${logicalEvent}`, setter);
        }
      };
    }, [relatedCharts, logicalEvent]);

    useEffect(() => {
      const setter = (v: unknown) => {
        if (chartAPIRef.current) {
          const _v = v as unknown as { time: Time; logical: Logical | undefined } | undefined;

          if (!_v) {
            chartAPIRef.current.clearCrosshairPosition();
          } else if (balancesSeriesRef.current.length !== 0) {
            chartAPIRef.current.setCrosshairPosition(0, _v.time, balancesSeriesRef.current[0]);
          }
        }
      };

      for (const relatedChart of relatedCharts) {
        Bus.on(`${relatedChart}-${cursorEvent}`, setter);
      }

      return () => {
        for (const relatedChart of relatedCharts) {
          Bus.off(`${relatedChart}-${cursorEvent}`, setter);
        }
      };
    }, [relatedCharts, cursorEvent, crosshairMoveHandler]);

    useUpdateEffect(() => {
      if (chartAPIRef.current) {
        chartAPIRef.current.applyOptions({ rightPriceScale: { mode: chartMode } });
      }
    }, [chartMode]);

    useUpdateEffect(() => {
      if (chartAPIRef.current) {
        chartAPIRef.current.applyOptions({
          rightPriceScale: {
            borderColor: palette.chart_border_color,
          },
          grid: {
            horzLines: {
              color: palette.chart_line_color,
            },
            vertLines: {
              color: palette.chart_line_color,
            },
          },
          timeScale: {
            borderColor: palette.chart_border_color,
          },
        });
      }
    }, [palette]);

    useUpdateEffect(() => {
      if (balancesSeriesRef.current.length !== 0) {
        balancesSeriesRef.current.forEach(serie => {
          if (serie) {
            const serieTitle = serie.options().title;

            const isTotal = serieTitle === 'Total';

            if (isTotal) {
              serie.setData(totalBalancesUsd);
            }

            const findBalances = balances.find(el => el.label === serieTitle);

            if (findBalances) {
              serie.setData(findBalances.records);
            }
          }
        });
      } else if (chartAPIRef.current) {
        const balanceSeries: any[] = [];

        let index = 0;

        for (const balanceItem of balances) {
          const lineSeries = chartAPIRef.current.addLineSeries({
            color: CHARTS_COLORS[index],
            lineWidth: 2,
            baseLineWidth: 1,
            title: balanceItem.label,
            visible: !!balancesShow[balanceItem.label],
          });

          lineSeries.setData(balanceItem.records);
          balanceSeries.push(lineSeries);

          index++;
        }

        const totalLineSeries = chartAPIRef.current.addLineSeries({
          color: CHARTS_COLORS[3],
          lineWidth: 2,
          baseLineWidth: 1,
          title: 'Total',
          visible: priceFormat === 'usd' && !!balancesShow['Total'],
        });

        totalLineSeries.setData(totalBalancesUsd);
        balanceSeries.push(totalLineSeries);

        balancesSeriesRef.current = balanceSeries;
      }
    }, [totalBalancesUsd, balances]);

    useEffect(() => {
      if (balancesSeriesRef.current.length !== 0) {
        for (const serie of balancesSeriesRef.current) {
          const serieTitle = serie.options().title;

          serie.applyOptions({
            visible:
              serieTitle === 'Total'
                ? priceFormat === 'usd' && !!balancesShow['Total']
                : !!balancesShow[serieTitle],
          });
        }
      }
    }, [balancesShow, priceFormat]);

    useEffectOnce(() => {
      initChart();
    });

    // cleanup function
    useEffect(() => {
      const _chartApiRef = chartAPIRef.current;

      return () => {
        if (_chartApiRef) {
          _chartApiRef.remove();
        }
      };
    }, []);

    const handleCopyBalancesValue = useCallback(() => {
      const hoveredIndex = hoveredIndexRef.current;
      if (!balances || hoveredIndex === undefined) return;

      const totalBalance =
        priceFormat === 'usd' ? totalBalancesUsd[hoveredIndex]?.value ?? 0 : null;
      const totalBalanceFormatted = totalBalance !== null ? formatFiat(bn(totalBalance), 18) : null;

      const copiedData = balances.map(el => {
        const formattedValue =
          priceFormat === 'usd'
            ? formatFiat(bn(el.records[hoveredIndex]?.value ?? 0), 18)
            : chartFormatter(el.records[hoveredIndex]?.value ?? 0);

        return {
          label: el.label,
          value: formattedValue,
        };
      });

      const totalString = totalBalanceFormatted ? `Total: ${totalBalanceFormatted}\n` : '';
      const balancesString = copiedData.map(item => `${item.label}: ${item.value}`).join('\n');

      const preparedString = totalString + balancesString;

      navigator.clipboard.writeText(preparedString);

      dispatch(setCopyPopupShow(true));
    }, [balances, totalBalancesUsd, dispatch, priceFormat]);

    const onMouseDownHandler = useCallback(() => {
      for (const relatedChart of relatedCharts) {
        Bus.off(`${relatedChart}-${timescaleEvent}`, timescaleSetter);
      }
    }, [relatedCharts, timescaleEvent, timescaleSetter]);
    const onMouseUpHandler = useCallback(() => {
      for (const relatedChart of relatedCharts) {
        Bus.on(`${relatedChart}-${timescaleEvent}`, timescaleSetter);
      }
    }, [relatedCharts, timescaleEvent, timescaleSetter]);

    return (
      <div className="mm-balances-chart-container">
        {!loading && tooltipVariant === 'new' ? (
          <BalanceTooltipNew
            priceFormat={priceFormat}
            balancesOuter={balances}
            cursorEvent={cursorEvent}
            logicalEvent={logicalEvent}
            totalBalancesUsd={totalBalancesUsd}
          />
        ) : (
          <BalanceTooltipOld
            priceFormat={priceFormat}
            balancesOuter={balances}
            cursorEvent={cursorEvent}
            logicalEvent={logicalEvent}
            totalBalancesUsd={totalBalancesUsd}
          />
        )}
        <div className="chart-wrp">
          <div
            className="chart"
            ref={node => {
              chartRef.current = node;
            }}
            onClick={handleCopyBalancesValue}
            onMouseDown={onMouseDownHandler}
            onMouseUp={onMouseUpHandler}
          />
        </div>

        {loading && <ChartLoading />}
        {!loading && false && <ChartNotFound text="Chart not found" />}
      </div>
    );
  },
);

export { BalancesChart };
