import { memo, useCallback, useEffect, useState } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import { motion } from 'framer-motion';

import { ApiCexAccounts, ApiStatistic } from 'api';
import { Spinner, Switcher } from 'ui';
import { InputField } from 'fields';
import { IAccountBalances, ICexAccount } from 'types/accounts';
import { ArbitrageCexAccountsTable } from 'tables/arbitrage';
import { ICexPair } from 'types/pairs';
import { IArbitragePairSettings } from 'types/arbitrage';

import './style.scss';

interface IAccountWithBalance extends ICexAccount, Partial<IAccountBalances> {}

const loadAccounts = async (cexPair: ICexPair): Promise<IAccountWithBalance[]> => {
  try {
    const { data } = await ApiCexAccounts.getAccounts({ limit: 1000, pairId: cexPair.id });

    const withBalances = await Promise.all(
      (data?.items ?? []).map(async account => {
        try {
          const { data } = await ApiStatistic.geCexActualBalance({
            cex: cexPair.cex,
            symbol: `${cexPair.token_base.symbol}-${cexPair.token_quote.symbol}`,
            account_id: [account.id],
          });

          return { ...account, ...(data ? data : {}) };
        } catch (error) {
          console.log('error: ', error);
          return account;
        }
      }),
    );

    return withBalances as IAccountWithBalance[];
  } catch (error) {
    console.log('error: ', error);
    return [];
  }
};

interface Props {
  cexPair: ICexPair;
  values: IArbitragePairSettings;
  setFieldValue: (v: keyof IArbitragePairSettings, va: any) => any;
  touched: FormikTouched<IArbitragePairSettings>;
  errors: FormikErrors<IArbitragePairSettings>;
  setFieldTouched: (field: string, touched?: boolean, shouldValidate?: boolean) => void;
}

const PairSettingsForm = memo(
  ({ cexPair, values, setFieldValue, touched, errors, setFieldTouched }: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pairAccounts, setPairAccounts] = useState<IAccountWithBalance[]>([]);

    const getPairAccounts = useCallback(async () => {
      try {
        setIsLoading(true);

        const accounts = await loadAccounts(cexPair);

        setPairAccounts(accounts);
      } catch (error) {
        console.log('error: ', error);
      } finally {
        setIsLoading(false);
      }
    }, [cexPair]);

    useEffect(() => {
      getPairAccounts();
    }, [getPairAccounts]);

    const handleSelectAccount = (accountId: number | undefined) => {
      setFieldTouched('accountId', true, true);

      setFieldValue('accountId', accountId !== values.accountId ? accountId : undefined);
    };

    return (
      <div className="arbitrage-pair-settings-form">
        <form className="settings-form">
          <span className="form-title">Settings</span>
          <div className="additional-inputs">
            <div className="boolean-inputs-container">
              <div className="boolean-input">
                Arbitrable
                <Switcher
                  value={values.arbitrable ?? false}
                  onChange={v => {
                    setFieldTouched('arbitrable', true, true);
                    setFieldValue('arbitrable', v);
                  }}
                />
              </div>
              <div className="boolean-input">
                Subscribe to updates
                <Switcher
                  value={values.subscribe_to_updates ?? false}
                  onChange={v => {
                    setFieldTouched('subscribe_to_updates', true, true);
                    setFieldValue('subscribe_to_updates', v);
                  }}
                />
              </div>
              <div className="boolean-input">
                Allow rebalancer buy
                <Switcher
                  value={values.allow_rebalancer_buy ?? false}
                  onChange={v => {
                    setFieldTouched('allow_rebalancer_buy', true, true);
                    setFieldValue('allow_rebalancer_buy', v);
                  }}
                />
              </div>
              <div className="boolean-input">
                Allow rebalancer sell
                <Switcher
                  value={values.allow_rebalancer_sell ?? false}
                  onChange={v => {
                    setFieldTouched('allow_rebalancer_sell', true, true);
                    setFieldValue('allow_rebalancer_sell', v);
                  }}
                />
              </div>
              <div className="boolean-input">
                Check balance
                <Switcher
                  value={values.check_balance ?? false}
                  onChange={v => {
                    setFieldTouched('check_balance', true, true);
                    setFieldValue('check_balance', v);
                  }}
                />
              </div>
            </div>
            <div className="decimal-inputs-container">
              <InputField
                label="Target ratio"
                type="decimal-number"
                value={values.target_base_quote_ratio ?? '0.5'}
                setValue={v => {
                  setFieldTouched('target_base_quote_ratio', true, true);
                  setFieldValue('target_base_quote_ratio', v);
                }}
                errorMessage={
                  touched.target_base_quote_ratio ? errors.target_base_quote_ratio : undefined
                }
              />
              <InputField
                label="Reserved balance (%)"
                type="decimal-number"
                value={values.reserved_balance_percent ?? '0'}
                setValue={v => {
                  setFieldTouched('reserved_balance_percent', true, true);
                  setFieldValue('reserved_balance_percent', v);
                }}
                errorMessage={
                  touched.reserved_balance_percent ? errors.reserved_balance_percent : undefined
                }
              />
            </div>
          </div>
        </form>
        {isLoading ? (
          <div className="spinner-container">
            <Spinner size="mini" />
          </div>
        ) : pairAccounts.length === 0 ? (
          <span className="no-accounts">
            No available accounts. You cannot proceed further. You should remove this pair from
            arbitrage
          </span>
        ) : (
          <div className="account-table-wrapper">
            <div className="pair-accounts">
              <ArbitrageCexAccountsTable
                selectedAccountId={values.accountId}
                onSelectAccount={handleSelectAccount}
                pair={cexPair}
                pairAccounts={pairAccounts}
              />
            </div>
            {touched.accountId && errors.accountId && (
              <motion.div
                className="accouns-error"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
              >
                {errors.accountId}
              </motion.div>
            )}
          </div>
        )}
      </div>
    );
  },
);

export { PairSettingsForm };
