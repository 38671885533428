import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';
import store from 'store';

import { AApiBase, IBaseResponse, Response, metricsAxios } from '../apiBase';
import {
  ECexBot,
  EDexBot,
  ICexBotConfig,
  IDexBotConfig,
  IAuthBot,
  ICexOrderbookGridBotConfig,
  ICexOrderbookGridBotTask,
  ICexBuySellBotConfig,
  ICexBuySellBotTask,
  ICexSLConfig,
  IBotStates,
} from 'types/bots';
import { IArbitrageConfig } from 'types/arbitrage';
import { ILinkedBotAuthItem } from './models';
import { setConnectedBots } from 'store/slices/pairs_connected_bots';

interface IGetLinkedBotsResponse {
  items: ILinkedBotAuthItem[];
  total_items: number;
}

class ApiBot extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiBot | undefined;
  private static instance = (() => {
    if (ApiBot.__instance === undefined) {
      ApiBot.__instance = new ApiBot();
    }
    return ApiBot.__instance;
  })();

  public static async getBots({
    limit,
    offset,
    types,
  }: {
    limit: number;
    offset: number;
    types?: ('cex' | 'dex')[];
  }) {
    type Return = { items: IAuthBot[] };

    try {
      const urlParams = new URLSearchParams();
      urlParams.append('limit', limit.toString());
      urlParams.append('offset', offset.toString());

      if (types) {
        for (const type of types) {
          urlParams.append('types', type);
        }
      }

      const response = await ApiBot.instance.get<IBaseResponse<Return>>(
        '/bots' + '?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: any) {
      return mapError<Return>({ error });
    }
  }

  public static async getDexBotConfig<V extends EDexBot>({
    pairId,
    bot,
  }: {
    pairId: number;
    bot: V;
  }): Promise<Response<IDexBotConfig<V> | undefined>> {
    type Return = IDexBotConfig<V> | undefined;

    try {
      const response = await ApiBot.instance.get<IBaseResponse<Return>>(
        `/pairs/${pairId}/bots/${bot}`,
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: any) {
      const errorCode = error?.response?.data?.code ?? 0;
      const errorMessage = error?.response?.data?.error ?? 'Something went wrong';

      // If not connected to the pair
      if (errorCode === 14002) {
        return { isSuccess: false, errorCode, errorMessage: errorMessage };
      } else {
        return mapError<Return>({ error });
      }
    }
  }

  public static async getCexBotConfig<V extends ECexBot>({
    pairId,
    bot,
  }: {
    pairId: number;
    bot: V;
  }): Promise<Response<ICexBotConfig<V> | undefined>> {
    type Return = ICexBotConfig<V> | undefined;

    try {
      const response = await ApiBot.instance.get<IBaseResponse<Return>>(
        `/cex-pairs/${pairId}/bots/${bot}`,
      );
      return mapResponse({
        data: response.data,
      });
    } catch (error: any) {
      const errorCode = error?.response?.data?.code ?? 0;
      const errorMessage = error?.response?.data?.error ?? 'Something went wrong';

      // If not connected to the pair
      if (errorCode === 14002) {
        return { isSuccess: false, errorCode, errorMessage: errorMessage };
      } else {
        return mapError<Return>({ error });
      }
    }
  }

  // TODO: need types
  public static async saveDexBotConfig<V extends EDexBot>({
    pairId,
    body,
    bot,
  }: {
    pairId?: number;
    bot: EDexBot;
    body: IDexBotConfig<V>;
  }): Promise<Response<any>> {
    try {
      if (!body) {
        return mapError<any>({ error: 'Body config is required!' });
      }

      const response = await ApiBot.instance.put<IBaseResponse<any>>(
        `/pairs/${pairId}/bots/${bot.toString()}`,
        body,
      );

      if (pairId) {
        let connectedBots =
          store.getState().pairConnectedBots.pairsConnectedBots[pairId] ??
          ({} as Record<EDexBot | ECexBot, boolean>);

        connectedBots = { ...connectedBots, [bot]: body.is_enabled };

        store.dispatch(setConnectedBots({ pairId, connectedBots }));
      }

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  }

  public static saveCexBotConfig = async ({
    pairId,
    body,
    bot,
  }: {
    pairId: number;
    bot: ECexBot;
    body: any;
  }) => {
    try {
      const response = await ApiBot.instance.put<IBaseResponse<any>>(
        `/cex-pairs/${pairId}/bots/${bot.toString()}`,
        body,
      );

      let connectedBots =
        store.getState().pairConnectedBots.pairsConnectedBots[pairId] ??
        ({} as Record<EDexBot | ECexBot, boolean>);

      connectedBots = { ...connectedBots, [bot]: body.is_enabled };

      store.dispatch(setConnectedBots({ pairId, connectedBots }));

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };

  public static getDexLinkedBots = async ({ pairId }: { pairId: number }) => {
    try {
      const response = await ApiBot.instance.get<IBaseResponse<IGetLinkedBotsResponse>>(
        `/pairs/${pairId}/bots`,
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<IGetLinkedBotsResponse>({ error });
    }
  };

  public static getCexLinkedBots = async ({ pairId }: { pairId: number }) => {
    try {
      const response = await ApiBot.instance.get<IBaseResponse<IGetLinkedBotsResponse>>(
        `/cex-pairs/${pairId}/bots`,
      );
      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<IGetLinkedBotsResponse>({ error });
    }
  };

  public static getOrderbookConfig = async ({ pairId }: { pairId: number }) => {
    try {
      const response = await metricsAxios.get<IBaseResponse<ICexOrderbookGridBotConfig>>(
        `/api/v1/bot/orderbook?pair_id=${pairId}`,
      );
      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<ICexOrderbookGridBotConfig>({ error });
    }
  };

  public static getBotStates = async ({ pairId }: { pairId: number }) => {
    try {
      const response = await metricsAxios.get<IBaseResponse<IBotStates>>(
        `/api/v1/bot/states/${pairId}`,
      );
      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<IBotStates>({ error });
    }
  };

  public static updateOrderbookTask = async ({ task }: { task: ICexOrderbookGridBotTask }) => {
    try {
      const response = await metricsAxios.put<IBaseResponse<any>>('/api/v1/bot/orderbook', task);
      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };

  public static addOrderbookTask = async ({ task }: { task: ICexOrderbookGridBotTask }) => {
    try {
      const response = await metricsAxios.post<IBaseResponse<{ id: number }>>(
        '/api/v1/bot/orderbook',
        task,
      );
      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<{ id: number }>({ error });
    }
  };

  public static deleteOrderbookTask = async ({
    pairId,
    taskId,
  }: {
    pairId: number;
    taskId: number;
  }) => {
    try {
      const response = await metricsAxios.delete<IBaseResponse<any>>(
        `/api/v1/bot/orderbook?pair_id=${pairId}&config_id=${taskId}`,
      );
      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };

  public static getCexBuySellConfig = async ({ pairId }: { pairId: number }) => {
    try {
      const response = await metricsAxios.get<IBaseResponse<ICexBuySellBotConfig>>(
        `/api/v1/bot/buysell?pair_id=${pairId}`,
      );
      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<ICexBuySellBotConfig>({ error });
    }
  };

  public static updateCexBuySellTask = async ({ task }: { task: ICexBuySellBotTask }) => {
    try {
      const response = await metricsAxios.put<IBaseResponse<any>>('/api/v1/bot/buysell', task);
      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };

  public static addCexBuySellTask = async ({ task }: { task: ICexBuySellBotTask }) => {
    try {
      const response = await metricsAxios.post<IBaseResponse<{ id: number }>>(
        '/api/v1/bot/buysell',
        task,
      );
      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<{ id: number }>({ error });
    }
  };

  public static deleteCexBuySellTask = async ({
    pairId,
    taskId,
  }: {
    pairId: number;
    taskId: number;
  }) => {
    try {
      const response = await metricsAxios.delete<IBaseResponse<any>>(
        `/api/v1/bot/buysell?pair_id=${pairId}&config_id=${taskId}`,
      );
      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };

  public static getCexSLConfig = async ({ pairId }: { pairId: number }) => {
    try {
      const response = await metricsAxios.get<IBaseResponse<ICexSLConfig>>(
        `/api/v1/bot/smart-liquidity?pair_id=${pairId}`,
      );
      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<ICexSLConfig>({ error });
    }
  };

  public static updateCexSLConfig = async ({ config }: { config: ICexSLConfig }) => {
    try {
      const response = await metricsAxios.put<IBaseResponse<any>>(
        '/api/v1/bot/smart-liquidity',
        config,
      );
      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };

  public static addCexSLConfig = async ({ config }: { config: ICexSLConfig }) => {
    try {
      const response = await metricsAxios.post<IBaseResponse<{ id: number }>>(
        '/api/v1/bot/smart-liquidity',
        config,
      );
      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<{ id: number }>({ error });
    }
  };

  public static deleteCexSLConfig = async ({
    pairId,
    configId,
  }: {
    pairId: number;
    configId: number;
  }) => {
    try {
      const response = await metricsAxios.delete<IBaseResponse<any>>(
        `/api/v1/bot/smart-liquidity?pair_id=${pairId}&config_id=${configId}`,
      );
      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };

  public static getArbitrageConfigs = async ({ projectId }: { projectId: number }) => {
    try {
      const response = await metricsAxios.get<IBaseResponse<IArbitrageConfig[]>>(
        `/api/v1/bot/arbitrage?project_id=${projectId}`,
      );
      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<IArbitrageConfig[]>({ error });
    }
  };

  public static updateArbitrageConfig = async (newConfig: IArbitrageConfig) => {
    try {
      const response = await metricsAxios.put<IBaseResponse<any>>(
        '/api/v1/bot/arbitrage',
        newConfig,
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };

  public static createArbitrageConfig = async (newConfig: Omit<IArbitrageConfig, 'id'>) => {
    try {
      const response = await metricsAxios.post<IBaseResponse<{ id: number }>>(
        '/api/v1/bot/arbitrage',
        newConfig,
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<{ id: number }>({ error });
    }
  };

  public static deleteArbitrageConfig = async (configId: number) => {
    try {
      const response = await metricsAxios.delete<IBaseResponse<any>>(
        `/api/v1/bot/arbitrage?config_id=${configId}`,
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };
}

export { ApiBot };
