import { FC, memo, useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';

import './switcher.scss';

type Props = {
  color?: 'primary';
  disabled?: boolean;
  onChange: (value: boolean) => void;
  value: boolean;
  id?: string;
};

export const Switcher: FC<Props> = memo(
  ({ color = 'primary', disabled = false, onChange, value, id }) => {
    const [checked, setChecked] = useState(value);

    const innerId = useMemo(() => id ?? uuid(), [id]);

    useEffect(() => {
      setChecked(value);
    }, [value]);

    const initialChecked = useMemo(
      () => value,
      //eslint-disable-next-line
  []);

    return (
      <div className={cn('mm-switcher', `mm-switcher-${color}`)}>
        <input
          disabled={disabled}
          id={innerId}
          type="checkbox"
          checked={checked}
          onChange={e => onChange(e.target.checked)}
        />
        <label
          onClick={() => {
            !disabled && onChange(!checked);
          }}
          htmlFor={innerId}
          className="mm-switcher__label"
        >
          <motion.div
            className="toggler"
            initial={initialChecked ? { right: 2, left: 'auto' } : { left: 2, right: 'auto' }}
            animate={checked ? { right: 2, left: 'auto' } : { left: 2, right: 'auto' }}
            transition={{ duration: 0.1 }}
          />
        </label>
      </div>
    );
  },
);
