import { FormikErrors, FormikTouched } from 'formik';

import { Switcher } from 'ui';
import { DurationField, InputField } from 'fields';

import { IArbitrageGeneralSettings } from 'types/arbitrage';

import './style.scss';

interface Props {
  values: IArbitrageGeneralSettings;
  setFieldValue: (v: keyof IArbitrageGeneralSettings, va: any) => any;
  touched: FormikTouched<IArbitrageGeneralSettings>;
  errors: FormikErrors<IArbitrageGeneralSettings>;
  setFieldTouched: (field: string, touched?: boolean, shouldValidate?: boolean) => void;
}

const GeneralSettingsForm = ({
  values,
  setFieldValue,
  touched,
  errors,
  setFieldTouched,
}: Props) => {
  return (
    <form className="arbitrage-general-form">
      <div className="additional-inputs">
        <div className="decimal-inputs-container">
          <InputField
            label="Min profit (%)"
            type="decimal-number"
            value={values.min_profit}
            setValue={v => {
              setFieldTouched('min_profit', true, true);
              setFieldValue('min_profit', v);
            }}
            errorMessage={touched.min_profit ? errors.min_profit : undefined}
          />
          <InputField
            label="USD threshold"
            type="decimal-number"
            value={values.usd_threshold}
            setValue={v => {
              setFieldTouched('usd_threshold', true, true);
              setFieldValue('usd_threshold', v);
            }}
            errorMessage={touched.usd_threshold ? errors.usd_threshold : undefined}
          />
        </div>
        <div className="decimal-inputs-container">
          <InputField
            label="Maximum limit (USD)"
            type="decimal-number"
            value={values.maximum_limit_usd}
            setValue={v => {
              setFieldTouched('maximum_limit_usd', true, true);
              setFieldValue('maximum_limit_usd', v);
            }}
            errorMessage={touched.maximum_limit_usd ? errors.maximum_limit_usd : undefined}
          />
          <DurationField
            label="Default cancel timeout"
            value={values.default_cancel_timeout_sec}
            setValue={v => {
              setFieldTouched('default_cancel_timeout_sec', true, true);
              setFieldValue('default_cancel_timeout_sec', v);
            }}
            errorMessage={
              touched.default_cancel_timeout_sec ? errors.default_cancel_timeout_sec : undefined
            }
          />
        </div>
        <div className="boolean-inputs-container">
          <div className="boolean-input">
            Maximum limited
            <Switcher
              value={values.is_maximum_limited}
              onChange={v => {
                setFieldTouched('is_maximum_limited', true, true);
                setFieldValue('is_maximum_limited', v);
              }}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export { GeneralSettingsForm };
