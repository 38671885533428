import React, { useMemo } from 'react';
import { useTypedSelector } from 'store';
import { Helmet } from 'react-helmet';

import { PairPageWrapper, Spinner } from 'ui';
import { EExchange } from 'web3';
import { ECexPairPanel, IPanelProps } from 'types/pairs';
import CexPairContextProvider from 'context/CexPairContext';
import { usePairConfig } from 'store/slices/pair_configs/hooks';

import { Accounts } from './Accounts';
import { SmartLiquidity } from './SmartLiquidity';
import { BuySell } from './BuySell';
import { CexCharts } from '../CexCharts';
import { OrderHistory } from './OrderHistory';
import { DayTrader } from '../DayTrader';
import { OrderbookGrid } from './OrderbookGrid';
import { NewOrderHistory } from './NewOrderHistory';
import { PageNotFound } from 'ui/PageNotFound';

const CexPair: React.FC = () => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair);
  const isAdmin = useTypedSelector(store => store.auth.isAdmin);
  const pairError = useTypedSelector(store => store.pairs.pairError);
  const pairLoading = useTypedSelector(store => store.pairs.pairLoading);

  const pairId = useMemo(() => cexPair?.id, [cexPair]);

  const { pairConfig, setPairConfig } = usePairConfig({
    exchange: EExchange.cex,
    id: pairId,
  });

  const panels = useMemo(() => {
    const adminPanel = [
      ECexPairPanel.charts_demo,
      ECexPairPanel.sl_bot,
      ECexPairPanel.buy_sell_bot,
      ECexPairPanel.order_history,
      ECexPairPanel.new_order_history,
      ECexPairPanel.orderbook_grid_bot_new,
    ];

    const panelValues = Object.values(ECexPairPanel).filter(
      el => isAdmin || !adminPanel.includes(el),
    );

    return panelValues.map(value => {
      const props: IPanelProps = {
        initialOpened: pairConfig.lastOpenedPanel === value,
        setIsOpened: (opened: boolean) =>
          pairId
            ? setPairConfig(pairId, { ...pairConfig, lastOpenedPanel: opened ? value : undefined })
            : undefined,
      };

      const mapper: Record<ECexPairPanel, React.ReactNode> = {
        [ECexPairPanel.charts_demo]: <DayTrader key="day-trader" {...props} />,
        [ECexPairPanel.charts]: <CexCharts key="charts" {...props} />,
        [ECexPairPanel.accounts]: <Accounts key="accounts" {...props} />,
        [ECexPairPanel.sl_bot]: <SmartLiquidity key="smart-liquidity" {...props} />,
        [ECexPairPanel.order_history]: <OrderHistory key="order-history" {...props} />,
        [ECexPairPanel.buy_sell_bot]: <BuySell key="buy-sell-bot" {...props} />,
        [ECexPairPanel.new_order_history]: <NewOrderHistory key="new-order-history" {...props} />,
        [ECexPairPanel.orderbook_grid_bot_new]: (
          <OrderbookGrid key="orderbook-grid-bot" {...props} />
        ),
      };

      return mapper[value];
    });
  }, [isAdmin, pairConfig, setPairConfig, pairId]);

  if (!pairLoading && pairError) {
    return <PageNotFound />;
  }

  return (
    <CexPairContextProvider>
      <PairPageWrapper>
        {cexPair && (
          <>
            <Helmet>
              <title>
                {cexPair.symbol} | {cexPair.cex} | MM
              </title>
            </Helmet>
            {panels}
          </>
        )}
        {!cexPair && (
          <div className="w-full h-52 flex items-center justify-center">
            <Spinner size="small" />
          </div>
        )}
      </PairPageWrapper>
    </CexPairContextProvider>
  );
};

export default CexPair;
