import React, { useCallback, useEffect, useRef, memo } from 'react';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import {
  createChart,
  CrosshairMode,
  PriceScaleMode,
  ISeriesApi,
  UTCTimestamp,
  IChartApi,
  Time,
  Logical,
  MouseEventParams,
  LineStyle,
  IPriceLine,
} from 'lightweight-charts';
import { useTypedSelector } from 'store';

import { ChartLoading, ChartNotFound } from '../controls';
import { PairTooltipOld } from './PairTooltipOld';

import { Bus } from 'tools';
import { chartFormatter } from 'utils/charts';
import { chartVolumeBarColor, internalVolumeBarColor } from 'constant/charts';
import { EExchange } from 'web3';
import { ECexOrderSide } from 'types/orders';
import { useCursorPosition, useVisibleLogicalRange } from 'hooks/charts';
import { PairTooltipNew } from './PairTooltipNew';

import './index.scss';

interface IPairChartProps {
  loading: boolean;
  chart: string;
  relatedCharts?: string[];
  logicalEvent: string;
  cursorEvent: string;
  isConverted?: boolean;
  exchange: EExchange;
  candles: {
    time: UTCTimestamp;
    close: number;
    high: number;
    low: number;
    open: number;
  }[];
  volumes: {
    time: UTCTimestamp;
    value: number;
  }[];
  internal_volumes?: {
    time: UTCTimestamp;
    value: number;
  }[];
  orders?: { price: number; amount: string; side: ECexOrderSide; label: string }[];
  tooltipVariant?: 'old' | 'new';
}

const PairChart: React.FC<IPairChartProps> = memo(
  ({
    loading,
    chart: chartId,
    relatedCharts = [],
    logicalEvent,
    cursorEvent,
    isConverted,
    exchange,
    candles,
    internal_volumes,
    volumes,
    orders = [],
    tooltipVariant = 'old',
  }) => {
    const palette = useTypedSelector(store => store.ui.selectedThemeColors);
    const chartRef = useRef<HTMLDivElement | null>(null);
    const chartAPIRef = useRef<IChartApi | undefined>(undefined);

    const candlestickSeriesRef = useRef<ISeriesApi<'Candlestick'> | undefined>(undefined);
    const totalvolumeSeriesRef = useRef<ISeriesApi<'Histogram'> | undefined>(undefined);
    const internalvolumeSeriesRef = useRef<ISeriesApi<'Histogram'> | undefined>(undefined);
    const tradePriceLines = useRef<IPriceLine[]>([]);

    const { setVisibleLogicalRange } = useVisibleLogicalRange({
      event: logicalEvent,
    });

    const { setCursorPosition } = useCursorPosition({
      event: cursorEvent,
    });

    const crosshairMoveHandler = useCallback(
      (param: MouseEventParams<Time>) => {
        if (!param.sourceEvent) return;

        if (param.time) {
          Bus.emit(`${chartId}-${cursorEvent}`, {
            time: param.time,
            logical: param.logical,
          });
          setCursorPosition({ time: param.time, logical: param.logical });
        } else {
          chartAPIRef.current?.clearCrosshairPosition();
          Bus.emit(`${chartId}-${cursorEvent}`, undefined);
          setCursorPosition(undefined);
        }
      },
      [chartId, cursorEvent, setCursorPosition],
    );

    const initChart = useCallback(() => {
      try {
        const chart = createChart(chartRef.current as HTMLElement, {
          autoSize: true,
          crosshair: {
            mode: CrosshairMode.Normal,
          },
          rightPriceScale: {
            mode: PriceScaleMode.Normal,
            minimumWidth: 100,
            borderColor: palette.chart_border_color,
            alignLabels: false,
          },
          grid: {
            horzLines: {
              color: palette.chart_line_color,
            },
            vertLines: {
              color: palette.chart_line_color,
            },
          },
          layout: {
            background: {
              color: 'transparent',
            },
          },
          timeScale: {
            timeVisible: true,
            secondsVisible: false,
            borderColor: palette.chart_border_color,
            rightBarStaysOnScroll: true,
            minBarSpacing: 3,
          },
          localization: {
            priceFormatter: chartFormatter,
            percentageFormatter: (p: number) => `${p.toFixed(2)}%`,
          },
        });
        chartAPIRef.current = chart;

        //candlestick
        const candleStickSeries = chart.addCandlestickSeries();
        candleStickSeries.priceScale().applyOptions({ scaleMargins: { top: 0.1, bottom: 0.2 } });

        //total volume
        const totalVolumeSeries = chart.addHistogramSeries({
          color: chartVolumeBarColor,
          priceFormat: {
            type: 'volume',
          },
          priceScaleId: '',
          lastValueVisible: false,
        });
        totalVolumeSeries.priceScale().applyOptions({ scaleMargins: { top: 0.8, bottom: 0 } });

        //internal volume
        const internalVolumeSeries = chart.addHistogramSeries({
          color: internalVolumeBarColor,
          priceFormat: {
            type: 'volume',
          },
          priceScaleId: '',
          lastValueVisible: false,
        });

        internalVolumeSeries.priceScale().applyOptions({ scaleMargins: { top: 0.8, bottom: 0 } });
        internalvolumeSeriesRef.current = internalVolumeSeries;

        // save series
        candlestickSeriesRef.current = candleStickSeries;
        totalvolumeSeriesRef.current = totalVolumeSeries;
        // -----

        if (candlestickSeriesRef.current) {
          candlestickSeriesRef.current.setData(candles);
        }

        if (totalvolumeSeriesRef.current) {
          totalvolumeSeriesRef.current.setData(volumes);
        }

        if (internalvolumeSeriesRef.current && internal_volumes) {
          internalvolumeSeriesRef.current.setData(internal_volumes);
        }

        chart.timeScale().subscribeVisibleLogicalRangeChange(logicalRange => {
          if (!logicalRange) return;

          Bus.emit(`${chartId}-${logicalEvent}`, {
            from: Number(logicalRange.from),
            to: Number(logicalRange.to),
          });

          setVisibleLogicalRange({
            from: Number(logicalRange.from),
            to: Number(logicalRange.to),
          });
        });

        chart.subscribeCrosshairMove(crosshairMoveHandler);

        if (orders.length !== 0) {
          const priceLines: IPriceLine[] = [];

          for (const trade of orders) {
            const priceLine = candleStickSeries.createPriceLine({
              price: trade.price,
              title: trade.label,
              color:
                trade.side === ECexOrderSide.sell
                  ? palette.sell_trade_color
                  : palette.buy_trade_color,
              lineWidth: 1,
              lineStyle: LineStyle.Solid,
              axisLabelVisible: true,
            });

            priceLines.push(priceLine);
          }

          tradePriceLines.current = priceLines;
        }

        // -----
      } catch (error) {
        console.log('pair chart initing error: ', error);
      }
    }, [
      chartId,
      logicalEvent,
      crosshairMoveHandler,
      setVisibleLogicalRange,
      orders,
      palette,
      candles,
      internal_volumes,
      volumes,
    ]);

    useEffect(() => {
      const setter = (v: unknown) => {
        const _v = v as unknown as { from: number; to: number };

        if (chartAPIRef && chartAPIRef.current) {
          chartAPIRef.current.timeScale().setVisibleLogicalRange(_v);
        }
      };

      for (const relatedChart of relatedCharts) {
        Bus.on(`${relatedChart}-${logicalEvent}`, setter);
      }

      return () => {
        for (const relatedChart of relatedCharts) {
          Bus.off(`${relatedChart}-${logicalEvent}`, setter);
        }
      };
    }, [logicalEvent, relatedCharts]);

    useEffect(() => {
      const setter = (v: unknown) => {
        if (chartAPIRef.current) {
          const _v = v as unknown as { time: Time; logical: Logical | undefined } | undefined;

          if (!_v) {
            chartAPIRef.current.clearCrosshairPosition();
          } else if (candlestickSeriesRef.current) {
            chartAPIRef.current.setCrosshairPosition(0, _v.time, candlestickSeriesRef.current);
          }
        }
      };

      for (const relatedChart of relatedCharts) {
        Bus.on(`${relatedChart}-${cursorEvent}`, setter);
      }

      return () => {
        for (const relatedChart of relatedCharts) {
          Bus.off(`${relatedChart}-${cursorEvent}`, setter);
        }
      };
    }, [relatedCharts, cursorEvent, crosshairMoveHandler]);

    useUpdateEffect(() => {
      if (candlestickSeriesRef.current) {
        candlestickSeriesRef.current.setData(candles);
      }
    }, [candles]);

    useUpdateEffect(() => {
      if (totalvolumeSeriesRef.current) {
        totalvolumeSeriesRef.current.setData(volumes);
      }
    }, [volumes]);

    useUpdateEffect(() => {
      if (internalvolumeSeriesRef.current && internal_volumes) {
        internalvolumeSeriesRef.current.setData(internal_volumes);
      }
    }, [internal_volumes]);

    useUpdateEffect(() => {
      if (chartAPIRef.current) {
        chartAPIRef.current.applyOptions({
          rightPriceScale: {
            borderColor: palette.chart_border_color,
          },
          grid: {
            horzLines: {
              color: palette.chart_line_color,
            },
            vertLines: {
              color: palette.chart_line_color,
            },
          },
          timeScale: {
            borderColor: palette.chart_border_color,
          },
        });
      }
    }, [palette]);

    useUpdateEffect(() => {
      for (const priceLine of tradePriceLines.current) {
        priceLine.applyOptions({
          lineVisible: false,
          axisLabelVisible: false,
        });
      }

      if (orders.length !== 0 && candlestickSeriesRef.current) {
        const priceLines: IPriceLine[] = [];

        for (const order of orders) {
          const priceLine = candlestickSeriesRef.current.createPriceLine({
            price: order.price,
            title: order.label,
            color:
              order.side === ECexOrderSide.sell
                ? palette.sell_trade_color
                : palette.buy_trade_color,
            lineWidth: 1,
            lineStyle: LineStyle.Solid,
            axisLabelVisible: true,
          });

          priceLines.push(priceLine);
        }

        tradePriceLines.current = priceLines;
      }
    }, [orders]);

    useEffectOnce(() => {
      initChart();
    });

    // cleanup function
    useEffect(() => {
      const _chartApiRef = chartAPIRef.current;

      return () => {
        if (_chartApiRef) {
          _chartApiRef.remove();
        }
      };
    }, []);

    return (
      <div className="mm-pair-chart-container">
        {candles.length !== 0 &&
          (tooltipVariant === 'old' ? (
            <PairTooltipOld
              isConverted={isConverted}
              exchange={exchange}
              volumes={volumes}
              internal_volumes={internal_volumes}
              candles={candles}
              logicalEvent={logicalEvent}
              cursorEvent={cursorEvent}
            />
          ) : (
            <PairTooltipNew
              isConverted={isConverted}
              exchange={exchange}
              volumes={volumes}
              internal_volumes={internal_volumes}
              candles={candles}
              logicalEvent={logicalEvent}
              cursorEvent={cursorEvent}
            />
          ))}
        <div className="chart-wrp">
          <div
            className="chart"
            ref={node => {
              chartRef.current = node;
            }}
          />
        </div>

        {loading && <ChartLoading />}
        {!loading && candles.length === 0 && <ChartNotFound text="Chart not found" />}
      </div>
    );
  },
);

export { PairChart };
