import { useCallback } from 'react';
import { matchPath, PathMatch, useLocation, useNavigate } from 'react-router';
import { motion } from 'framer-motion';
import { tailwindConfig } from 'tailwind-config';
import { useTypedDispatch } from 'store';

import { ApiBot } from 'api';
import { Bus } from 'tools';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { ROUTE_PATHS } from 'constant/routes';

import { TrashIcon } from 'assets/icons';
import './style.scss';

const Header = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const pathMatch: PathMatch<'projectId' | 'arbitrageId'> | null = matchPath(
    ROUTE_PATHS.arbitrage,
    pathname,
  );

  const arbitrageId = Number(pathMatch?.params.arbitrageId) ?? 0;

  const deleteArbitrage = useCallback(async () => {
    if (arbitrageId === 0) return;

    try {
      const { isSuccess, errorMessage } = await ApiBot.deleteArbitrageConfig(arbitrageId);

      if (isSuccess) {
        dispatch(
          setAlertState({
            type: 'success',
            text: 'You have successfully deleted arbitrage project!',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );

        navigate('/');
        Bus.emit('delete-arbitrage');
      } else {
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: errorMessage ?? 'Something went wrong',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      }
    } catch (error) {
      console.log('error: ', error);

      dispatch(
        setAlertState({
          type: 'failed-img',
          text: 'Something went wrong',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => {
            dispatch(dropAlertState());
          },
        }),
      );
    }
  }, [navigate, dispatch, arbitrageId]);

  const handleDeleteArbitrage = useCallback(() => {
    dispatch(
      setAlertState({
        type: 'sure',
        text: 'Are you sure that you want to delete arbitrage project?',
        onClose: () => dispatch(dropAlertState()),
        onSubmit: deleteArbitrage,
      }),
    );
  }, [dispatch, deleteArbitrage]);

  return (
    <motion.div
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { duration: 0.4 } }}
      className="arbitrage-page-header"
    >
      <div className="header-info">
        <span className="header-title">Arbitrage {arbitrageId}</span>
        <button onClick={handleDeleteArbitrage}>
          <TrashIcon color={tailwindConfig.theme.colors['red-1']} />
        </button>
      </div>
    </motion.div>
  );
};

export { Header };
