import React, { useEffect } from 'react';
import { useLocation, useNavigate, generatePath } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';
import { useTypedSelector, useTypedDispatch } from 'store';
import { useDebounce } from 'react-use';

import { wagmiConfig } from 'web3/wagmi';
import { CopyPopup } from 'ui';
import { MM_MAIN_ELEMENT_ID } from 'constant/dom';
import { useResizeObserver } from 'hooks/utility';
import { ROUTE_PATHS } from 'constant/routes';
import { getAllProjectsAndPairs } from 'store/slices/projects';
import { setLayoutWidth } from 'store/slices/ui';

import { Header } from '../Header/Header';
import { Sidebar } from '../Sidebar/Sidebar';

import './applicationLayout.scss';

const queryClient = new QueryClient();

const WagmiWrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const userRole = useTypedSelector(store => store.auth.user?.role);

  const isAdmin = userRole === 'admin';

  if (isAdmin) {
    return (
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
      </WagmiProvider>
    );
  }

  return children;
};

interface IApplicationLayout {
  children: React.ReactNode;
}

const ApplicationLayout: React.FC<IApplicationLayout> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useTypedDispatch();
  const userIsAuthenticated = useTypedSelector(store => Boolean(store.auth.jwt));
  const user = useTypedSelector(store => store.auth.user);
  const isUser = useTypedSelector(store => store.auth.isUser);

  useEffect(() => {
    const redirectToFirstProjectPair = ({
      projectId,
      pairPath,
    }: {
      projectId: number | undefined;
      pairPath: string | undefined;
    }) => {
      if (projectId && pairPath && user && isUser && location.pathname === '/') {
        navigate(
          generatePath(ROUTE_PATHS.project, {
            id: `${projectId}${pairPath}`,
          }),
        );
      }
    };

    if (userIsAuthenticated && user) {
      dispatch(getAllProjectsAndPairs({ redirect: redirectToFirstProjectPair }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsAuthenticated, user, dispatch]);

  const [resizableRef, sizes] = useResizeObserver();

  useDebounce(
    () => {
      // remove paddings and margins
      dispatch(setLayoutWidth(sizes.width));
    },
    200,
    [sizes],
  );

  return (
    <WagmiWrapper>
      <div className="mm-application-layout">
        <Sidebar />
        <div className="mm-application-layout__main__holder">
          <section id={MM_MAIN_ELEMENT_ID} className="mm-application-layout__main scrollable">
            <div className="mm-application-layout__main__container">
              <Header />
              <main ref={resizableRef} className="mm-application-layout__content">
                {children}
              </main>
            </div>
          </section>
          <CopyPopup />
        </div>
      </div>
    </WagmiWrapper>
  );
};

export { ApplicationLayout };
