import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router';

import { ROUTE_PATHS } from 'constant/routes';
import { HeaderProject } from '../HeaderProject/HeaderProject';
import { HeaderPair } from '../HeaderPair';
import { Header as HeaderArbitrage } from '../../arbitrage/header';

const PageHeaderPiece: React.FC = () => {
  const { pathname } = useLocation();
  const [headerType, setHeaderType] = useState<
    'header-project' | 'header-pair' | 'arbitrage' | null
  >(null);

  useEffect(() => {
    const changeHeaderTypeRelateToPathname = () => {
      const matchDexPair = matchPath({ path: ROUTE_PATHS.dexPair }, pathname);
      const matchCexPair = matchPath({ path: ROUTE_PATHS.cexPair }, pathname);
      const matchArbitrage = matchPath({ path: ROUTE_PATHS.arbitrage }, pathname);

      if (matchPath({ path: ROUTE_PATHS.project }, pathname)) {
        return setHeaderType('header-project');
      }
      if (matchDexPair || matchCexPair) {
        return setHeaderType('header-pair');
      }
      if (matchArbitrage) {
        return setHeaderType('arbitrage');
      }

      return setHeaderType(null);
    };

    changeHeaderTypeRelateToPathname();
  }, [pathname]);

  return (
    <>
      {headerType === 'header-project' && <HeaderProject />}
      {headerType === 'header-pair' && <HeaderPair />}
      {headerType === 'arbitrage' && <HeaderArbitrage />}
    </>
  );
};

export { PageHeaderPiece };
