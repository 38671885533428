import React from 'react';

import { Spinner } from 'ui';

import './index.scss';

const ChartLoading: React.FC = () => {
  return (
    <div className="mm-pair-chart-loading">
      <Spinner size="small" />
      <span className="caption">Loading chart...</span>
    </div>
  );
};

export { ChartLoading };
