import { useState, useCallback, useMemo, useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router';
import { useFormik } from 'formik';

import { ApiBot, ApiCexPairs } from 'api';
import { useTypedSelector, useTypedDispatch } from 'store';
import {
  validateGeneralSettings,
  validatePairSettings,
  validateSelectedPairs,
} from './validateForm';
import { ICexPair } from 'types/pairs';
import {
  IArbitrageGeneralSettings,
  IArbitragePairSettings,
  generalArbitrageSettingsSchema,
  pairArbitrageSettingsSchema,
} from 'types/arbitrage';
import { ROUTE_PATHS } from 'constant/routes';
import { setAlertState, dropAlertState } from 'store/slices/ui';

export type IArbitrageForm = Record<number, IArbitragePairSettings> &
  IArbitrageGeneralSettings & { cexes: ICexPair[] };

interface IArgs {
  onClose: () => void;
  onSuccess?: (form: IArbitrageForm) => void;
  projectId: number;
  mode: 'add' | 'create';
  pairsToAdd?: ICexPair[];
}

export enum EStep {
  pairs = 'pairs',
  pair = 'pair',
  general = 'general',
}

interface IStep {
  name: EStep;
  back: string;
  continue: string;
}

const firstStep: IStep = {
  name: EStep.pairs,
  back: 'Back',
  continue: 'Continue >',
};

const INITIAL_PAIR_FORM: IArbitragePairSettings = {
  accountId: undefined,
  arbitrable: true,
  subscribe_to_updates: true,
  allow_rebalancer_buy: true,
  allow_rebalancer_sell: true,
  check_balance: true,
  target_base_quote_ratio: '0.5',
  reserved_balance_percent: '0',
};

const useAddArbitrageModal = ({ mode, onClose, onSuccess, projectId, pairsToAdd }: IArgs) => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const projects = useTypedSelector(store => store.projects.projects);

  const [steps, setSteps] = useState<IStep[]>([firstStep]);
  const [currentStepIdx, setCurrentStepIdx] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);

  const [arbitrageForm, setArbitrageForm] = useState({
    cexes: [] as ICexPair[],
  } as IArbitrageForm);

  const [isValidationSuccess, setIsValidationSuccess] = useState<boolean>(false);

  const [cexPairs, setCexPairs] = useState<ICexPair[]>([]);

  const selectedCexPairs = useMemo(() => arbitrageForm.cexes, [arbitrageForm]);

  const currentPair = useMemo(
    () => (currentStepIdx ? selectedCexPairs[currentStepIdx - 1] ?? null : null),
    [selectedCexPairs, currentStepIdx],
  );

  const project = useMemo(
    () => (projects ?? []).find(project => project.id === projectId) ?? null,
    [projects, projectId],
  );

  const projectAllPairsIsActive = useMemo(() => {
    return cexPairs.every(pair => selectedCexPairs.some(selected => selected.id === pair.id));
  }, [cexPairs, selectedCexPairs]);

  const setSelectedCexPairs = useCallback(
    (pairsOrUpdater: ICexPair[] | ((prevPairs: ICexPair[]) => ICexPair[])) => {
      setArbitrageForm(v => {
        const prevPairs = v.cexes;
        const newSelectedPairs =
          typeof pairsOrUpdater === 'function'
            ? (pairsOrUpdater as (prevPairs: ICexPair[]) => ICexPair[])(prevPairs)
            : (pairsOrUpdater as ICexPair[]);

        return { ...v, cexes: newSelectedPairs };
      });
    },
    [],
  );

  const toggleAllPairs = useCallback(() => {
    if (projectAllPairsIsActive) {
      setSelectedCexPairs([]);
    } else {
      setSelectedCexPairs([...cexPairs]);
    }
  }, [projectAllPairsIsActive, cexPairs, setSelectedCexPairs]);

  const generalSettings = useFormik<IArbitrageGeneralSettings>({
    initialValues: useMemo(
      () => ({
        min_profit: '0',
        usd_threshold: '0',
        maximum_limit_usd: '0',
        default_cancel_timeout_sec: 600 * 1000,
        is_maximum_limited: false,
      }),
      [],
    ),
    enableReinitialize: true,
    onSubmit: () => {},
    validationSchema: generalArbitrageSettingsSchema,
  });

  const pairSettings = useFormik<IArbitragePairSettings>({
    initialValues: INITIAL_PAIR_FORM,
    enableReinitialize: true,
    onSubmit: () => {},
    validationSchema: pairArbitrageSettingsSchema,
  });

  useEffect(() => {
    if (currentPair) {
      pairSettings.setValues(arbitrageForm[currentPair.id] ?? INITIAL_PAIR_FORM);
    }

    //eslint-disable-next-line
  }, [currentPair]);

  const setupSteps = useCallback(
    (pairLength: number) => {
      const generalStep: IStep = {
        name: EStep.general,
        back: `< Pair ${pairLength}/${pairLength}`,
        continue: 'Create arbitrage >',
      };

      const pairLengthSteps = Array.from({ length: pairLength })
        .fill(0)
        .map((_, idx) => ({
          name: EStep.pair,
          back: idx == 0 ? '< Back' : `< Pair ${idx}/${pairLength}`,
          continue:
            idx === pairLength - 1
              ? mode === 'create'
                ? 'General settings >'
                : 'Add pairs'
              : `Pair ${idx + 1}/${pairLength} >`,
        }));

      setSteps(
        [firstStep, ...pairLengthSteps, mode === 'create' ? generalStep : null].filter(
          el => el !== null,
        ),
      );
    },
    [mode],
  );

  const handleCreateArbitrage = useCallback(async () => {
    if (!project) return;

    try {
      setLoading(true);

      const { isSuccess, data, errorMessage } = await ApiBot.createArbitrageConfig({
        project_id: project.id,
        active: true,
        execute_cex: true,
        execute_dex: true,
        usd_threshold: Number(generalSettings.values.usd_threshold),
        maximum_limit_usd: Number(generalSettings.values.maximum_limit_usd),
        is_maximum_limited: generalSettings.values.is_maximum_limited,
        min_profit_percentage: Number(generalSettings.values.min_profit),
        default_cancel_timeout_sec: (generalSettings.values.default_cancel_timeout_sec ?? 0) / 1000,
        pairs_cex: arbitrageForm.cexes
          .map(cexPair => {
            const values = arbitrageForm[cexPair.id];

            if (!values) return null;

            const {
              accountId,
              allow_rebalancer_buy,
              allow_rebalancer_sell,
              arbitrable,
              check_balance,
              reserved_balance_percent,
              subscribe_to_updates,
              target_base_quote_ratio,
            } = values;

            return {
              account_id: accountId!,
              allow_rebalancer_buy: allow_rebalancer_buy!,
              allow_rebalancer_sell: allow_rebalancer_sell!,
              arbitrable: arbitrable!,
              check_balance: check_balance!,
              pair_id: cexPair.id,
              reserved_balance_percent: Number(reserved_balance_percent!),
              subscribe_to_updates: subscribe_to_updates!,
              target_base_quote_ratio: Number(target_base_quote_ratio!),
            };
          })
          .filter(el => el !== null),
      });

      if (isSuccess && data) {
        onClose();
        navigate(
          generatePath(ROUTE_PATHS.arbitrage, {
            projectId: project.id.toString(),
            arbitrageId: data.id.toString(),
          }),
        );

        dispatch(
          setAlertState({
            type: 'success',
            text: 'Successfully created new arbitrage project!',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      } else {
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: errorMessage ?? 'Something went wrong',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      }
    } catch (error) {
      console.log('error: ', error);

      dispatch(
        setAlertState({
          type: 'failed-img',
          text: 'Something went wrong',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => {
            dispatch(dropAlertState());
          },
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [onClose, project, navigate, dispatch, generalSettings.values, arbitrageForm]);

  const currentStepControls = useMemo(() => {
    const step = steps[currentStepIdx];
    const hasNext = !!steps[currentStepIdx + 1];

    const onForwardClick = () => {
      if (isValidationSuccess) {
        setCurrentStepIdx(v => v + 1);
      }
    };

    const setFormState = () => {
      if (currentPair) {
        setArbitrageForm(v => ({ ...v, [currentPair.id]: pairSettings.values }));
      }
    };

    const onBackwardClick = () => {
      if (!isValidationSuccess && currentPair && step.name !== EStep.general) {
        setArbitrageForm(v => {
          const old = { ...v };
          delete old[currentPair.id];
          return old;
        });
      }

      setCurrentStepIdx(v => (v === 0 ? 0 : v - 1));
    };

    const enabledNext = isValidationSuccess && !loading;

    if (step.name === EStep.pairs) {
      return {
        backwardControl: undefined,
        forwardControl: {
          disabled: !enabledNext,
          title: step.continue,
          onForwardClick,
        },
      };
    }

    if (mode === 'add' && step.name === EStep.pair && !hasNext) {
      return {
        backwardControl: {
          disabled: false,
          title: step.back,
          onBackwardClick,
        },
        forwardControl: {
          disabled: !enabledNext,
          title: step.continue,
          onForwardClick: () => {
            if (currentPair) {
              onSuccess?.({ ...arbitrageForm, [currentPair.id]: pairSettings.values });
            }
          },
        },
      };
    }

    if (step.name === EStep.pair) {
      return {
        backwardControl: {
          disabled: false,
          title: step.back,
          onBackwardClick,
        },
        forwardControl: {
          disabled: !enabledNext,
          title: step.continue,
          onForwardClick: () => {
            setFormState();
            onForwardClick();
          },
        },
      };
    }

    if (step.name === EStep.general) {
      return {
        backwardControl: {
          disabled: false,
          title: step.back,
          onBackwardClick,
        },
        forwardControl: {
          disabled: !enabledNext,
          title: step.continue,
          onForwardClick: async () => {
            await handleCreateArbitrage();
            onSuccess?.(arbitrageForm);
          },
        },
      };
    }

    return {
      backwardControl: {
        disabled: false,
        title: step.back,
        onBackwardClick,
      },
      forwardControl: {
        disabled: !enabledNext,
        title: step.continue,
        onForwardClick: () => {
          setFormState();
          onForwardClick();
        },
      },
    };
  }, [
    arbitrageForm,
    onSuccess,
    mode,
    currentPair,
    currentStepIdx,
    steps,
    loading,
    isValidationSuccess,
    pairSettings.values,
    handleCreateArbitrage,
  ]);

  useEffect(() => {
    const getCexPairs = async () => {
      if (pairsToAdd) {
        setCexPairs(pairsToAdd);
      } else {
        try {
          if (project) {
            const _cexPairs = (
              await Promise.all(project.cexPairs.map(pair => ApiCexPairs.getCexPairById(pair.id)))
            )
              .filter(el => el.data)
              .map(el => ({ ...el.data } as ICexPair));

            setCexPairs(_cexPairs);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    getCexPairs();
  }, [pairsToAdd, project]);

  useEffect(() => {
    const step = steps[currentStepIdx];

    if (step) {
      if (step.name === EStep.pairs) {
        setIsValidationSuccess(validateSelectedPairs(arbitrageForm.cexes).success);
      } else if (step.name === EStep.pair) {
        setIsValidationSuccess(validatePairSettings(pairSettings.values).success);
      } else if (step.name === EStep.general) {
        setIsValidationSuccess(validateGeneralSettings(generalSettings.values).success);
      }
    }
  }, [arbitrageForm, currentStepIdx, steps, generalSettings.values, pairSettings.values]);

  return {
    project,
    steps,
    currentStepIdx,
    currentPair,
    selectedCexPairs,
    cexPairs,
    currentStepControls,
    setupSteps,
    setSelectedCexPairs,
    generalSettings,
    pairSettings,
    toggleAllPairs,
  };
};

export { useAddArbitrageModal };
