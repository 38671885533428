import React, { useMemo, useState, useCallback } from 'react';
import { useLocation, matchPath, generatePath } from 'react-router';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useTypedSelector } from 'store';

import { RadioBox, TableAction, TableActionIcon } from 'ui';
import { AddPairModal, TradingPairModal, SelectPairDexModal, AddArbitrageModal } from 'modals';
import { IDex } from 'api/apiDictionary/models';
import { EExchange } from 'web3';
import { ICexPair, IDexPair } from 'types/pairs';
import { IProject } from 'types/project';
import { tailwindConfig } from 'tailwind-config';
import { ROUTE_PATHS } from 'constant/routes';

import PlatformIcon from './PlatformIcon';
import NetworkIcon from './NetworkIcon';

import { PlusIcon, SelectArrow } from 'assets/icons';
import './projectListWithPairs.scss';

type ICommonPair = (IDexPair | ICexPair) & { exchange: EExchange };

interface IProjectListWithPairs {
  id: string;
  project: IProject;
  arbitrageIds: number[];
  getArbitrages: () => void;
  selectedProjectId: number | null;
  onSelectProject: (id: number) => void;
}

const ProjectListWithPairs: React.FC<IProjectListWithPairs> = ({
  id,
  project,
  arbitrageIds,
  getArbitrages,
  selectedProjectId,
  onSelectProject,
}) => {
  const { pathname } = useLocation();
  const isAdmin = useTypedSelector(store => store.auth.isAdmin);
  const palette = useTypedSelector(store => store.ui.selectedThemeColors);

  const projectIsSelected: boolean = useMemo(
    () => project.id === selectedProjectId,
    [project.id, selectedProjectId],
  );

  const [tradingType, setTradingType] = useState<EExchange>(EExchange.cex);
  const [dex, setDex] = useState<IDex | undefined>(undefined);

  const [tradingPairWindowOpened, setTradingPairWindowOpened] = useState<boolean>(false);
  const [addPairWindowOpened, setAddPairWindowOpened] = useState<boolean>(false);
  const [selectPairDexModalOpened, setSelectPairDexModalOpened] = useState<boolean>(false);
  const [addArbitrageWindowOpened, setAddArbitrageWindowOpened] = useState<boolean>(false);

  const openTradingPairWindow = useCallback(() => {
    setTradingPairWindowOpened(true);
  }, []);

  const closeTradingPairWindow = useCallback(() => {
    setTradingPairWindowOpened(false);
  }, []);

  const openArbitrageWindow = useCallback(() => {
    setAddArbitrageWindowOpened(true);
  }, []);

  const closeArbitrageWindow = useCallback(() => {
    setAddArbitrageWindowOpened(false);
  }, []);

  const openAddPairWindow = useCallback(() => {
    setAddPairWindowOpened(true);
    setTradingPairWindowOpened(false);
    setSelectPairDexModalOpened(false);
  }, []);

  const openSelectPairDex = useCallback(() => {
    setDex(undefined);
    setSelectPairDexModalOpened(true);
    setTradingPairWindowOpened(false);
  }, []);

  const closeSelectPairDex = useCallback(() => {
    setSelectPairDexModalOpened(false);
  }, []);

  const closeAddPairWindow = useCallback(() => {
    setAddPairWindowOpened(false);
  }, []);

  const projectAllPairsIsActive = useMemo(
    () => Boolean(matchPath(`project/${project.id}`, pathname)),
    [pathname, project],
  );

  const pairs = useMemo(
    () =>
      [...project.dexPairs, ...project.cexPairs]
        .sort((a, b) => {
          const createdAtA = new Date(a.created_at).valueOf();
          const createdAtB = new Date(b.created_at).valueOf();

          return createdAtA - createdAtB;
        })
        .map(el => {
          if ('cex' in el) return { ...el, exchange: EExchange.cex };
          else if ('dex' in el) return { ...el, exchange: EExchange.dex };
        }) as ICommonPair[],
    [project],
  );

  return (
    <>
      <div className="mm-sidebar__projects__project" key={project.id} id={id}>
        <div
          className="mm-sidebar__projects__project__header"
          onClick={() => onSelectProject(project.id)}
        >
          <motion.div
            animate={{
              color: projectIsSelected ? palette.primary : undefined,
            }}
            className="mm-sidebar__projects__project__name"
          >
            <span>{project.name}</span>
          </motion.div>
          <motion.div
            animate={{ rotate: projectIsSelected ? 180 : 0 }}
            className="mm-sidebar__projects__project__select-icon"
          >
            <SelectArrow
              color={projectIsSelected ? tailwindConfig.theme.colors.primary : undefined}
            />
          </motion.div>
        </div>
        <motion.div
          initial={{ height: 0, overflow: 'hidden' }}
          animate={{
            height: projectIsSelected ? 'auto' : 0,
            marginBottom: projectIsSelected ? '1rem' : '0rem',
          }}
          className="mm-sidebar__projects__project__pairs"
        >
          {isAdmin && (
            <NavLink to={generatePath(ROUTE_PATHS.project, { id: project.id.toString() })}>
              <RadioBox checked={projectAllPairsIsActive} text="All pairs" />
            </NavLink>
          )}
          {pairs.length === 0 && (
            <span className="mm-sidebar__projects__project__no-info">
              There is no existing pairs. Create first one!
            </span>
          )}
          {pairs.map(pair => (
            <div key={pair.created_at} className="mm-sidebar__projects__project__trading-pair">
              <NavLink
                to={generatePath(
                  pair.exchange === EExchange.dex ? ROUTE_PATHS.dexPair : ROUTE_PATHS.cexPair,
                  {
                    projectId: project.id.toString(),
                    pairId: pair.id.toString(),
                  },
                )}
              >
                <RadioBox
                  checked={Boolean(
                    matchPath(
                      generatePath(
                        pair.exchange === EExchange.dex ? ROUTE_PATHS.dexPair : ROUTE_PATHS.cexPair,
                        {
                          projectId: project.id.toString(),
                          pairId: pair.id.toString(),
                        },
                      ),
                      pathname,
                    ),
                  )}
                  text={pair.symbol}
                  pairIcon={
                    <div className="project-pair-container">
                      {'dex' in pair && <NetworkIcon network={pair.network} />}
                      <PlatformIcon
                        platform={'cex' in pair ? pair.cex : pair.dex}
                        isIcon
                        className="mm-sidebar__projects__project__trading-pair-icon"
                      />
                    </div>
                  }
                />
              </NavLink>
            </div>
          ))}
          {isAdmin &&
            arbitrageIds.map(id => (
              <div key={id} className="mm-sidebar__projects__project__trading-pair">
                <NavLink
                  to={generatePath(ROUTE_PATHS.arbitrage, {
                    projectId: project.id.toString(),
                    arbitrageId: id.toString(),
                  })}
                >
                  <RadioBox
                    checked={Boolean(
                      matchPath(
                        generatePath(ROUTE_PATHS.arbitrage, {
                          projectId: project.id.toString(),
                          arbitrageId: id.toString(),
                        }),
                        pathname,
                      ),
                    )}
                    text={`Arbitrage ${id}`}
                  />
                </NavLink>
              </div>
            ))}
          {isAdmin && (
            <TableAction
              iconLeft={<TableActionIcon icon={PlusIcon} sideBar />}
              text="Add new pair"
              onClick={openTradingPairWindow}
              customClassName="mm-sidebar__projects__project__add-pair"
            />
          )}
        </motion.div>
        {isAdmin && project.cexPairs.length !== 0 && (
          <motion.div
            initial={{ height: 0, overflow: 'hidden' }}
            animate={{
              height: projectIsSelected ? 'auto' : 0,
              marginBottom: projectIsSelected ? '1rem' : '0rem',
            }}
            className="mm-sidebar__projects__project__pairs"
          >
            {pairs.length === 0 && (
              <span className="mm-sidebar__projects__project__no-info">
                There is no existing pairs. Create first one!
              </span>
            )}
            <TableAction
              iconLeft={<TableActionIcon icon={PlusIcon} sideBar />}
              text="Add arbitrage"
              onClick={openArbitrageWindow}
              customClassName="mm-sidebar__projects__project__add-pair"
            />
          </motion.div>
        )}
      </div>
      {tradingPairWindowOpened && (
        <TradingPairModal
          openAddPairWindow={openAddPairWindow}
          openSelectPairDex={openSelectPairDex}
          onClose={closeTradingPairWindow}
          typeTradingPair={tradingType}
          setTypeTradingPair={setTradingType}
        />
      )}
      {selectPairDexModalOpened && (
        <SelectPairDexModal
          dex={dex}
          setDex={setDex}
          openAddPairWindow={openAddPairWindow}
          onClose={closeSelectPairDex}
        />
      )}
      {addPairWindowOpened && (
        <AddPairModal
          onOpen={openAddPairWindow}
          onClose={closeAddPairWindow}
          projectId={project.id}
          tradingType={tradingType}
          dex={dex}
        />
      )}
      {addArbitrageWindowOpened && (
        <AddArbitrageModal
          onClose={closeArbitrageWindow}
          onSuccess={getArbitrages}
          projectId={project.id}
        />
      )}
    </>
  );
};

export { ProjectListWithPairs };
