import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store';

import { LocalStorage } from 'tools';
import { ETheme } from 'types/theme';
import { setTheme } from 'store/slices/ui';

export const useTheme = () => {
  const _theme = useTypedSelector(store => store.ui.selectedTheme);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(setTheme(ETheme.dark));
  }, [dispatch]);

  return { isDark: _theme === ETheme.dark };
};
