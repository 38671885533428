import React, { useState, useCallback, lazy, useContext } from 'react';

import { Panel } from 'ui';
import { CexPairContext } from 'context/CexPairContext';

import { RoundChartIcon } from 'assets/icons';
import { IPanelProps } from 'types/pairs';

const PanelContent = lazy(() => import('./components/Content'));

import './style.scss';

const CexCharts: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  const { updateAccounts } = useContext(CexPairContext);

  const [isPanelOpened, setIsPanelOpened] = useState<boolean>(initialOpened ?? false);

  const handleSwitch = useCallback(
    (opened: boolean) => {
      setIsPanelOpened(opened);
      setIsOpened?.(opened);

      if (opened) {
        updateAccounts();
      }
    },
    [setIsOpened, updateAccounts],
  );

  return (
    <Panel
      title="Charts OLD VERSION"
      icon={RoundChartIcon}
      initialOpened={initialOpened}
      onChange={handleSwitch}
      className="_overflow-disable"
    >
      <PanelContent isPanelOpened={isPanelOpened} />
    </Panel>
  );
};

export { CexCharts };
