import React, { useCallback, useEffect, useRef, useMemo, useState, memo } from 'react';
import {
  createChart,
  CrosshairMode,
  PriceScaleMode,
  IChartApi,
  ISeriesApi,
  Time,
  Logical,
  MouseEventParams,
} from 'lightweight-charts';
import { useTypedSelector } from 'store';

import { chartFormatter } from 'utils/charts';
import { Tooltip } from './Tooltip';

interface ICumulativePnlChartProps {
  series: {
    color: string;
    points: { time: Time; value: number }[];
    label: string;
  }[];
  priceFormatter?: (num: number) => string;
}

const CumulativePnlChart: React.FC<ICumulativePnlChartProps> = memo(
  ({ series, priceFormatter = chartFormatter }) => {
    const palette = useTypedSelector(store => store.ui.selectedThemeColors);
    const chartAPIRef = useRef<IChartApi | undefined>(undefined);
    const seriesRefs = useRef<ISeriesApi<'Area'>[]>([]);
    const chartRef = useRef<HTMLDivElement>(null);

    const [cursorPosition, setCursorPosition] = useState<
      { time: Time; logical: Logical | undefined } | undefined
    >(undefined);
    const [lastVisibleIndex, setLastVisibleIndex] = useState<number | undefined>(undefined);

    const hoveredIndex = useMemo(() => {
      const result = cursorPosition
        ? cursorPosition === undefined
          ? lastVisibleIndex
          : cursorPosition.logical
        : lastVisibleIndex;

      const recordsLength = series.length > 0 ? series[0].points.length : 0;

      if (!result) return recordsLength - 1;

      return result > recordsLength - 1 ? recordsLength - 1 : result;
    }, [lastVisibleIndex, cursorPosition, series]);

    const initChart = useCallback(() => {
      const chart = createChart(chartRef.current as HTMLElement, {
        autoSize: true,
        crosshair: {
          mode: CrosshairMode.Normal,
        },
        rightPriceScale: {
          mode: PriceScaleMode.Normal,
          borderColor: palette.chart_border_color,
          scaleMargins: { top: 0.3 },
        },
        grid: {
          horzLines: {
            color: palette.chart_line_color,
          },
          vertLines: {
            color: palette.chart_line_color,
          },
        },
        layout: {
          background: {
            color: 'transparent',
          },
        },
        timeScale: {
          timeVisible: true,
          secondsVisible: false,
          borderColor: palette.chart_border_color,
          rightBarStaysOnScroll: true,
        },
        localization: {
          priceFormatter,
        },
      });
      chartAPIRef.current = chart;

      series.forEach(({ color, points }) => {
        const newSeries = chart.addAreaSeries({
          lineColor: color,
          topColor: `${color}66`,
          bottomColor: `${color}33`,
          lineWidth: 2,
        });
        newSeries.setData(points);
        seriesRefs.current.push(newSeries);
      });

      const crosshairMoveHandler = (param: MouseEventParams<Time>) => {
        if (param.time) {
          setCursorPosition({
            time: param.time,
            logical: param.logical,
          });
        } else {
          chartAPIRef.current?.clearCrosshairPosition();
          setCursorPosition(undefined);
        }
      };

      chart.subscribeCrosshairMove(crosshairMoveHandler);

      chart.timeScale().subscribeVisibleLogicalRangeChange(logicalRange => {
        if (!logicalRange) return setLastVisibleIndex(undefined);

        setLastVisibleIndex(Math.floor(Number(logicalRange.to)));
      });
    }, [series, priceFormatter, palette]);

    useEffect(() => {
      initChart();

      return () => {
        if (chartAPIRef.current) {
          chartAPIRef.current.remove();
        }
      };
    }, [initChart]);

    return (
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <Tooltip
          values={series.map(serie => ({
            value: serie.points[hoveredIndex].value ?? 0,
            color: serie.color,
            label: serie.label,
          }))}
          priceFormatter={priceFormatter}
        />
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <div
            style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}
            ref={chartRef}
          />
        </div>
      </div>
    );
  },
);

export { CumulativePnlChart };
