import { useEffect, useMemo, useCallback } from 'react';
import { useTypedSelector } from 'store';

import { Bus } from 'tools';
import { EExchange } from 'web3';

const useVisibleLogicalRange = ({
  event,
  setter,
}: {
  event: string;
  setter?: (logicalRange: any) => void;
}) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const exchange = useMemo(
    () => (dexPair ? EExchange.dex : cexPair ? EExchange.cex : EExchange.dex),
    [dexPair, cexPair],
  );

  const pair = useMemo(
    () => (exchange === EExchange.dex ? dexPair : cexPair),
    [exchange, cexPair, dexPair],
  );

  useEffect(() => {
    if (setter && event) {
      Bus.on(event, setter);
    }

    return () => {
      if (setter && event) {
        Bus.off(event, setter);
      }
    };
  }, [event, pair, setter]);

  const setVisibleLogicalRange = useCallback(
    (param: { from: number; to: number } | undefined) => {
      if (setter) {
        setter(param);
      }

      Bus.emit(event, param);
    },
    [event, setter],
  );

  return { setVisibleLogicalRange };
};

export { useVisibleLogicalRange };
