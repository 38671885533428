import { ICexPairShort } from 'types/pairs';
import {
  generalArbitrageSettingsSchema,
  pairArbitrageSettingsSchema,
  IArbitrageGeneralSettings,
  IArbitragePairSettings,
} from 'types/arbitrage';

export const validateSelectedPairs = (selectedCexPairs: ICexPairShort[]) => {
  if (selectedCexPairs?.length === 0) {
    return {
      success: false,
    };
  }

  return { success: true };
};

export const validatePairSettings = (pairForm: IArbitragePairSettings) => {
  try {
    const validationSchema = pairArbitrageSettingsSchema;

    validationSchema.validateSync(pairForm, { abortEarly: false });

    return { success: true };
  } catch (e) {
    return { success: false };
  }
};

export const validateGeneralSettings = (settings: Partial<IArbitrageGeneralSettings>) => {
  const validationSchema = generalArbitrageSettingsSchema;

  try {
    validationSchema.validateSync(settings, { abortEarly: false });

    return { success: true };
  } catch (e) {
    return { success: false };
  }
};
