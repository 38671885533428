import { boolean, number, object, string } from 'yup';

export enum EArbitragePanel {
  pairs = 'pairs',
  settings = 'settings',
  order_history = 'order_history',
}

export interface IPanelProps {
  initialOpened?: boolean;
  setIsOpened?: (v: boolean) => any;
}

export interface IArbitrageConfig {
  active: boolean;
  default_cancel_timeout_sec: number;
  execute_cex: boolean;
  execute_dex: boolean;
  id: number;
  is_maximum_limited: boolean;
  maximum_limit_usd: number;
  min_profit_percentage: number;
  pairs_cex:
    | {
        account_id: number;
        allow_rebalancer_buy: boolean;
        allow_rebalancer_sell: boolean;
        arbitrable: boolean;
        check_balance: boolean;
        pair_id: number;
        reserved_balance_percent: number;
        subscribe_to_updates: boolean;
        target_base_quote_ratio: number;
      }[]
    | null;
  project_id: number;
  usd_threshold: number;
}

export interface IArbitrageGeneralSettings {
  min_profit: string;
  maximum_limit_usd: string;
  default_cancel_timeout_sec: number | null;
  is_maximum_limited: boolean;
  usd_threshold: string;
}

export interface IArbitragePairSettings {
  accountId?: number;
  arbitrable?: boolean;
  subscribe_to_updates?: boolean;
  allow_rebalancer_buy?: boolean;
  allow_rebalancer_sell?: boolean;
  check_balance?: boolean;
  target_base_quote_ratio?: string;
  reserved_balance_percent?: string;
}

export const generalArbitrageSettingsSchema = object({
  min_profit: string()
    .required('"Min profit" field is required')
    .test(
      'min_profit',
      '"Min profit" must be a percent value',
      (value: string | undefined) => !isNaN(Number(value)) && Number(value) <= 100,
    ),
  usd_threshold: string().required('"USD threshold" is required'),
  maximum_limit_usd: string().required('"Maximum limit (USD)" is required'),
  default_cancel_timeout_sec: number().required('"Default cancel timeout" is required'),
  is_maximum_limited: boolean(),
});

export const pairArbitrageSettingsSchema = object({
  accountId: number()
    .required('You should select account')
    .test('accountId', 'Select only one account', (accountId: number | undefined) => {
      return accountId !== undefined && !isNaN(Number(accountId));
    }),
  arbitrable: boolean(),
  subscribe_to_updates: boolean(),
  allow_rebalancer_buy: boolean(),
  allow_rebalancer_sell: boolean(),
  check_balance: boolean(),
  target_base_quote_ratio: string()
    .required('"Target ratio" field is required')
    .test(
      'target_base_quote_ratio',
      '"Target ratio" must be a number between 0 and 1',
      (value: string | undefined) => !isNaN(Number(value)) && Number(value) <= 1,
    ),
  reserved_balance_percent: string()
    .required('"Reserved balance" field is required')
    .test(
      'reserved_balance_percent',
      '"Reserved balance" must be a percent value',
      (value: string | undefined) => !isNaN(Number(value)) && Number(value) <= 100,
    ),
});
